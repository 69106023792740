import React from "react"
import MathJax from 'react-mathjax-preview'
import SupportedProblemItemHeader from "./SupportedProblemHeader";

export default function SupportedProblemItem(props) {

    console.log(props.item)
    return (
        <div>
            {/* <div style={{ backgroundColor: '#D3D3D3', display: 'flex', height: 30, alignItems: 'center', paddingLeft: 10 }}>
                <text style={{ color: 'black', fontSize: 20 }}>{props.title}</text>
            </div> */}
            {props.index === 0 && <SupportedProblemItemHeader title={props.title} />

            }
            <div style={{ display: "flex", flexDirection: 'row', alignItems: 'center', width: '100%', backgroundColor: '#FFFFFF', paddingLeft: '10px' }}>
                <div style={{ width: '60%', fontSize: 18 }} >
                    {/* {props.data.map((data) =>
                            <MathJax math={data.equation} />
                        )} */}
                    <MathJax
                    style={{color :  "#000000" , fontWeight:'bold' }}
                     math={props.data.equation} />
                </div>
                <div style={{ width: '20%', textAlign: 'center' }} >
                    {props.data.isSupported && <img alt="isSupportedImg" style={{ width: 25, height: 25 }} src={process.env.PUBLIC_URL + "/ic_check_circle.png"}></img>}
                    {/* {props.data.map((data) =>
                            <MathJax math={data.isSupported}
                            />
                        )} */}
                </div>
                <div style={{ width: '25%', textAlign: 'center' }} >
                    {props.data.isAnimation && <img alt="isAnimationImg" style={{ width: 25, height: 25 }} src={process.env.PUBLIC_URL + "/ic_check_circle.png"}></img>}
                    {/* {props.data.map((data) =>
                            <MathJax math={data.isAnimation}
                            />
                        )} */}
                </div>
            </div>
            <div style={{ backgroundColor: '#F6F6F6', height: 2 }}></div>
        </div>
    );
}
