
export default class CalculatorUtil {
    // static nonSigntificKeys = ['C', '%', '÷', 'CE', '7', '8', '9', '×', '4', '5', '6', '-', '1', '2', '3', '+', 'f(x)', '0', '.', '=']

    static nonSigntificKeys = [['C', 'MOVE_BACKWARD', 'MOVE_FORWARD', 'CE'], ['SQRT', 'frac', '7', '8', '9', '÷'], ['<', '>', '4', '5', '6', '×'], ['(', ')', '1', '2', '3', '-'], ['π', '%', '.', '0', '=', '+']]


    static signtificKeys = ['C', 'x', 'e', 'CE', 'frac', 'cos', 'tan', '×', '<', '>', 'π', '-', '(', ')', 'x2', '+', 'f(x)', '0', '.', '=']
    static highLightlingKeys1 = ['C', 'MOVE_BACKWARD', 'MOVE_FORWARD', 'CE', '']
    static highLightlingKeys = ['SQRT', 'frac', '×', '<', '>', '-', '(', ')', '+', 'π', '%', '=', '÷']
    static disableKeys = ['x', 'e', 'cos', 'tan', 'x2']
    static switchToNormalKeys = ['frac', '<', '>', '(', ')']

    // static operators = ["+", "-", "×", "÷", "(", ")", '<', '>', 'frac', 'SQRT', 'CUBE', 'POW']
    static operators = ["+", "-", "×", "÷", "(", ")", '<', '>', '≤', '≥', 'frac', 'SQRT', 'CUBE', 'POW']
    static numeric = ["0" , "1" , "2" , "3" , "4" , "5" , "6" , "7" , "8" ,"9" ,"."]
    static specialChar = ['(', ')' , '{' , '}' , "[" , "]"]

    static rightBottomKey = ["+"]
    static leftBottomKey = ["π"]

    static exponentChildItem = ['SQRT', 'CUBE', 'POW']
    static graterThanChildItem = ['>', '≥']
    static lesserThanChildItem = ['<', '≤']

    static id = 1

    static latexPower =["^"]



    static idGenerator = () => {
        return this.id++;
    }

    static isRightBootomKey = (element) => {
        return CalculatorUtil.rightBottomKey.includes(element)

    }


    static isLeftBootomKey = (element) => {
        return CalculatorUtil.leftBottomKey.includes(element)

    }
    static isOperator = (element) => {
        return CalculatorUtil.operators.includes(element)
    }
    static isSpecialChar = (element) => {
        return CalculatorUtil.specialChar.includes(element)
    }


    static isNumeric = (element) => {
        return CalculatorUtil.numeric.includes(element)
    }

    static isPower = (element) => {
        return CalculatorUtil.latexPower.includes(element)
    }


    static isFrac = (value) => {
        console.log("value : " , value)
        var substring  =  (value.length > 5 ? value.substring(0 , 5): value)
        console.log("substring : " , substring)

        if(substring === '/frac'){
            return true
        }
        return false
    }

    static isPi = (value) => {
        console.log("value PI: " , value)
        var substring  =  (value.length > 2 ? value.substring(0 , 2): value)
        console.log("substring : " , substring)

        if(substring === 'pi'){
            return true
        }
        return false
    }

    static isDisableKey = (element) => {
        return CalculatorUtil.disableKeys.includes(element)
    }

    static removeString = (str, position) => {
        return str.substring(0, position - 1) + str.substring(position, str.length)
    }

    static addString = (str, position, newStr) => {
        return str.substring(0, position) + newStr + str.substring(position, str.length)
    }

    static insert = (arr, index, element) => {
        arr.splice(index, 0, element)
        return arr.join()
    }

    static remove = (arr, index) => {
        return arr.splice(index, 1)
    }

    static removeWithLength = (arr, index, length) => {
        return arr.splice(index, length)
    }

    static isCornerItem = (key) => {
        return CalculatorUtil.highLightlingKeys.includes(key)
    }

    static isNextElementAvilable = (arr, index) => {
        return arr.length <= index ? false : true
    }
    static getChildItem(key) {
        switch (key) {
            case 'SQRT':
                return this.exponentChildItem
            case '<':
                return this.lesserThanChildItem
            case '>':
                return this.graterThanChildItem
            default:
                return null
        }
    }


    static imageForKey(key) {
        let img = undefined
        let size = 50
        switch (key) {
            case 'CE':
                img = 'backspace-new.png'
                size = 25
                break
            case 'frac':
                img = 'fraction.png'
                size = 35
                break
            case 'POW':
                img = 'ic_icon_sqrt.png'
                size = 35
                break
            case 'CUBE':
                img = 'ic_icon_exponent3.png'
                size = 35
                break
            case 'SQRT':
                img = 'ic_icon_exponent2.png'
                size = 35
                break
            case 'MOVE_BACKWARD':
                img = 'icons8-left-100.png'
                size = 30
                break
            case 'MOVE_FORWARD':
                img = 'icons8-right-100.png'
                size = 30
                break
            default:
                return undefined
        }
        return { "image": img, "size": size }
    }

}

export class EquationElementBase {
    identifier = ""
    parentIdentifier = undefined

    constructor(identifier, parentIdentifier) {
        this.identifier = identifier
        this.parentIdentifier = parentIdentifier
    }
}

export class EquationSymbol {
    element = ""
    id = ""
    parentIdentifier = ""
    constructor(parentIdentifier, element) {
        this.element = element
        this.parentIdentifier = parentIdentifier
        this.id = "ES" + CalculatorUtil.idGenerator()
    }
}

export class EquationElement {
    element = ""
    id = ""
    parentIdentifier = ""
    constructor(id, parentIdentifier, element) {
        this.element = element
        this.parentIdentifier = parentIdentifier
        this.id = id + CalculatorUtil.idGenerator()
    }
}

export class Expression {
    flexDirection
    equationSymbol
    elements
    id
    parentIdentifier

    constructor(parentIdentifier, flexDirection, equationSymbol, elements) {
        this.id = "Exp" + CalculatorUtil.idGenerator()
        this.parentIdentifier = parentIdentifier
        this.flexDirection = flexDirection
        this.equationSymbol = equationSymbol
        this.elements = elements
    }
}

export class FractionExpression extends Expression {
    secondElements
    constructor(parentIdentifier, flexDirection, equationSymbol, elements, secondElements) {
        super(parentIdentifier, flexDirection, equationSymbol, elements)
        this.secondElements = secondElements
    }
}

export class SquareExpression extends Expression {
    secondElements
    constructor(parentIdentifier, flexDirection, equationSymbol, elements, secondElements) {
        super(parentIdentifier, flexDirection, equationSymbol, elements)
        this.secondElements = secondElements
    }
}