import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.css";

import { Provider } from "react-redux";
import { createStore } from "redux";
import reducer from "./reducers/index";
import { initFacebookSdk } from "./FacebookSDK";

import "./fonts/SF-UI-Display-Regular.ttf";
import "./fonts/Roboto-Regular.ttf";

const store = createStore(reducer);

window.historyList = [];

initFacebookSdk();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
