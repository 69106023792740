import React from "react";
import { Component } from "react";

import { NavLink } from "react-router-dom";

class NavBar extends Component {
  async componentDidMount() { }

  render() {
    return (
      <div
        className={`${this.props.type === "slide" ? "container-fluid" : ""}`}
      >
        <div className="row">
          <div className="col-12 ham-nav-icn">
            <svg
              width="50"
              height="50"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M30.6666 25.3333C30.6666 26.0406 30.3856 26.7189 29.8855 27.219C29.3854 27.719 28.7072 28 27.9999 28H3.99992C3.29267 28 2.6144 27.719 2.1143 27.219C1.6142 26.7189 1.33325 26.0406 1.33325 25.3333V10.6667C1.33325 9.95942 1.6142 9.28115 2.1143 8.78105C2.6144 8.28095 3.29267 8 3.99992 8H9.33325L11.9999 4H19.9999L22.6666 8H27.9999C28.7072 8 29.3854 8.28095 29.8855 8.78105C30.3856 9.28115 30.6666 9.95942 30.6666 10.6667V25.3333Z"
                fill="white"
                stroke="#01497C"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M30.6667 25.3333C30.6667 26.0406 30.3858 26.7189 29.8857 27.219C29.3856 27.719 28.7073 28 28 28H15.8667C15.8667 27.3333 15.8667 25.4667 15.8667 25.0667C15.8667 24.4 15.8667 22.7072 15.8667 22V10.6667C15.8667 10.6667 15.8667 9.18105 15.8667 8.78105C15.8667 8.24772 15.8667 8 15.8667 7.33333V4H20.0001L22.6667 8H28C28.7073 8 29.3856 8.28095 29.8857 8.78105C30.3858 9.28115 30.6667 9.95942 30.6667 10.6667V25.3333Z"
                fill="#01497C"
              />
              <path
                d="M7.48682 22.6667H9.84651V19.2795H13.3333V16.9872H9.84651V13.6H7.48682V16.9872H4V19.2795H7.48682V22.6667Z"
                fill="#01497C"
              />
              <rect
                x="18.9333"
                y="16.8"
                width="8.8"
                height="2.4"
                fill="white"
              />
            </svg>

            {this.props.type === "slide" && (
              <span
                className="ham-men-close"
                onClick={this.props.hideHamburgerMenu}
              >
                {" "}
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.3002 0.709971C12.9102 0.319971 12.2802 0.319971 11.8902 0.709971L7.00022 5.58997L2.11022 0.699971C1.72022 0.309971 1.09021 0.309971 0.700215 0.699971C0.310215 1.08997 0.310215 1.71997 0.700215 2.10997L5.59022 6.99997L0.700215 11.89C0.310215 12.28 0.310215 12.91 0.700215 13.3C1.09021 13.69 1.72022 13.69 2.11022 13.3L7.00022 8.40997L11.8902 13.3C12.2802 13.69 12.9102 13.69 13.3002 13.3C13.6902 12.91 13.6902 12.28 13.3002 11.89L8.41021 6.99997L13.3002 2.10997C13.6802 1.72997 13.6802 1.08997 13.3002 0.709971Z"
                    fill="#676767"
                  />
                </svg>
              </span>
            )}
          </div>
          <div className="verti-nav-cell-link">
            <NavLink
              to="/add-image"
              className="verti-nav-cell-link"
              activeClassName="active"
              onClick={this.props.hideHamburgerMenu}
            >
              <div className="col-12 verti-nav-cell">
                <span className="verti-nav-icons">
                  {" "}
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 5H9V9H5V11H9V15H11V11H15V9H11V5ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18Z"
                      fill="black"
                    />
                  </svg>
                </span>
                Add/Click Image
              </div>
            </NavLink>

            <NavLink
              to="/calculator"
              className="verti-nav-cell-link"
              activeClassName="active"
              onClick={this.props.hideHamburgerMenu}
            >
              <div className="col-12 verti-nav-cell">
                <span className="verti-nav-icons">
                  {" "}
                  {/* <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16 0H2C0.9 0 0 0.9 0 2V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V2C18 0.9 17.1 0 16 0ZM16 16H2V2H16V16Z"
                    fill="black"
                  />
                  <path d="M8.25 4.72H3.25V6.22H8.25V4.72Z" fill="black" />
                  <path d="M15 12.75H10V14.25H15V12.75Z" fill="black" />
                  <path d="M15 10.25H10V11.75H15V10.25Z" fill="black" />
                  <path
                    d="M5 15H6.5V13H8.5V11.5H6.5V9.5H5V11.5H3V13H5V15Z"
                    fill="black"
                  />
                  <path
                    d="M11.09 7.95L12.5 6.54L13.91 7.95L14.97 6.89L13.56 5.47L14.97 4.06L13.91 3L12.5 4.41L11.09 3L10.03 4.06L11.44 5.47L10.03 6.89L11.09 7.95Z"
                    fill="black"
                  />
                </svg> */}
                  <img src={process.env.PUBLIC_URL + "/calculator.png"} width={20} height={20} alt=""></img>
                </span>
                Calculator
              </div>
            </NavLink>

            <NavLink
              to="/history"
              className="verti-nav-cell-link"
              activeClassName="active"
              onClick={this.props.hideHamburgerMenu}

            >
              <div className="col-12 verti-nav-cell ">
                <span className="verti-nav-icons">
                  {" "}
                  {/* <svg
                  width="18"
                  height="18"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.99 0C4.47 0 0 4.48 0 10C0 15.52 4.47 20 9.99 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 9.99 0ZM10 18C5.58 18 2 14.42 2 10C2 5.58 5.58 2 10 2C14.42 2 18 5.58 18 10C18 14.42 14.42 18 10 18ZM9.78 5H9.72C9.32 5 9 5.32 9 5.72V10.44C9 10.79 9.18 11.12 9.49 11.3L13.64 13.79C13.98 13.99 14.42 13.89 14.62 13.55C14.83 13.21 14.72 12.76 14.37 12.56L10.5 10.26V5.72C10.5 5.32 10.18 5 9.78 5Z"
                    fill="#323232"
                  />
                </svg> */}
                  <img src={process.env.PUBLIC_URL + "/history.png"} width={20} height={20} alt=""></img>
                </span>
                History
              </div>
            </NavLink>
          </div>

          {/* <NavLink
            to="/saved"
            className="verti-nav-cell-link"
            activeClassName="active"
          >
            <div className="col-12 verti-nav-cell">
              <span className="verti-nav-icons">
                {" "}
                <svg
                  width="14"
                  height="18"
                  viewBox="0 0 14 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14 0H0V18L7 15L14 18V0ZM12 15L7 12.82L2 15V2H12V15Z"
                    fill="black"
                  />
                </svg>
              </span>
              Saved
            </div>
          </NavLink> */}
          {/* <NavLink
            to="/profile"
            className="verti-nav-cell-link mt-5"
            activeClassName="active"
          > */}
          <div className="verti-nav-cell-link" style={{marginTop:"100%"}}>
            <div className="verti-nav-cell-link">
              <div className="col-12 verti-nav-cell">
                <span className="verti-nav-icons">
                  {" "}
                  {
                    JSON.parse(window.localStorage.user).profileImgUrl
                      ?
                      <img
                        width="20"
                        height="20"
                        style={{
                          borderRadius: 100
                        }}
                        src={JSON.parse(window.localStorage.user).profileImgUrl}
                        alt=""
                      >
                      </img>
                      :
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8 2C9.1 2 10 2.9 10 4C10 5.1 9.1 6 8 6C6.9 6 6 5.1 6 4C6 2.9 6.9 2 8 2ZM8 11C10.7 11 13.8 12.29 14 13V14H2V13.01C2.2 12.29 5.3 11 8 11ZM8 0C5.79 0 4 1.79 4 4C4 6.21 5.79 8 8 8C10.21 8 12 6.21 12 4C12 1.79 10.21 0 8 0ZM8 9C5.33 9 0 10.34 0 13V16H16V13C16 10.34 10.67 9 8 9Z"
                          fill="black"
                        />
                      </svg>
                  }
                </span>
                {JSON.parse(window.localStorage.user).username}
              </div>
            </div>
            {/* </NavLink> */}

            <NavLink
              to="/get-help"
              className="verti-nav-cell-link"
              activeClassName="active"
              onClick={this.props.hideHamburgerMenu}

            >
              <div className="col-12 verti-nav-cell">
                <span className="verti-nav-icons">
                  {" "}
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 16H11V14H9V16ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 4C7.79 4 6 5.79 6 8H8C8 6.9 8.9 6 10 6C11.1 6 12 6.9 12 8C12 10 9 9.75 9 13H11C11 10.75 14 10.5 14 8C14 5.79 12.21 4 10 4Z"
                      fill="#323232"
                    />
                  </svg>
                </span>
                Help
              </div>
            </NavLink>

            {/* <NavLink
            to="/terms-and-condition"
            className="verti-nav-cell-link"
            activeClassName="active"
          >
            <div className="col-12 verti-nav-cell">
              <span className="verti-nav-icons">
                {" "}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 16H11V14H9V16ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 4C7.79 4 6 5.79 6 8H8C8 6.9 8.9 6 10 6C11.1 6 12 6.9 12 8C12 10 9 9.75 9 13H11C11 10.75 14 10.5 14 8C14 5.79 12.21 4 10 4Z"
                    fill="#323232"
                  />
                </svg>
              </span>
              Terms and condition
            </div>
          </NavLink>

          <NavLink
            to="/privacy-policy"
            className="verti-nav-cell-link"
            activeClassName="active"
          >
            <div className="col-12 verti-nav-cell">
              <span className="verti-nav-icons">
                {" "}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 16H11V14H9V16ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 4C7.79 4 6 5.79 6 8H8C8 6.9 8.9 6 10 6C11.1 6 12 6.9 12 8C12 10 9 9.75 9 13H11C11 10.75 14 10.5 14 8C14 5.79 12.21 4 10 4Z"
                    fill="#323232"
                  />
                </svg>
              </span>
              Privacy policy
            </div>
          </NavLink> */}

            <div className="verti-nav-cell-link" onClick={this.props.logOutUser}>
              <div className="col-12 verti-nav-cell">
                <span className="verti-nav-icons">
                  {" "}
                  {/* <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9 16H11V14H9V16ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 4C7.79 4 6 5.79 6 8H8C8 6.9 8.9 6 10 6C11.1 6 12 6.9 12 8C12 10 9 9.75 9 13H11C11 10.75 14 10.5 14 8C14 5.79 12.21 4 10 4Z"
                    fill="#323232"
                  />
                </svg> */}
                  <img src={process.env.PUBLIC_URL + "/logout.png"} width={18} height={18} alt=""></img>
                </span>
                Logout
              </div>
            </div>
          </div>
        </div>
      </div >
    );
  }
}

export default NavBar;
