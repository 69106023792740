import React, { Component } from "react";
import MathJax from 'react-mathjax-preview';
import StepPage from "../Steppage";
import { withRouter } from "react-router-dom";
import qs from "qs";
import axios from "axios";

class SolutionCard extends Component {

    state = {
        isExplainSteps: true,
        isBookmarked: false
    }

    async componentDidMount() {
        let queryParams = qs.parse(this.props.location.search.split("?")[1]);

        if (queryParams["isBookmarked"] === "true") {
            this.setState({
                isBookmarked: true
            })
        } else {
            this.setState({
                isBookmarked: false
            })
        }
    }

    goBackPage = () => {
        this.props.history.goBack()
    }


    toogleBookmark = (isBookmarked) => {
        this.setState({
            isBookmarked: isBookmarked
        });
        this.updateBookmark(isBookmarked);
    }

    updateBookmark = (isBookmarked) => {
        var url = window.location.href
        var r = new URL(url)
        r.searchParams.set("isBookmarked", isBookmarked)
        const newUrl = r.href
        window.history.replaceState({ path: newUrl }, '', newUrl);
    }

    makeBookmark = () => {
        // this.props.bookmark(history);
        // this.loadHistoryAndSaved(this.state.currentValueSelected);

        var url = window.location.href
        var r = new URL(url)

        if (r.searchParams.get("history-id") !== null && r.searchParams.get("history-id") !== "") {
            var data = {
                histRef: r.searchParams.get("history-id"),
                isBookmarked: !this.state.isBookmarked
            }

            var userData = JSON.parse(window.localStorage.user).data
            var that = this
            axios
                .put(
                    process.env.REACT_APP_API_URL + "/v1/history/" + userData.user.id,
                    data,
                    {

                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + userData.token.accessToken
                        },


                    },
                )
                .then(function (response) {
                    that.toogleBookmark(data.isBookmarked);
                })
                .catch(function (error) {
                });
        }
    }

    renderStep(solution) {
        return (
            <div style={{ marginTop: 10 }}>
                {this.state.isExplainSteps && (
                    <div className="solcont">
                        <div className="solcontrow">
                            {this.state.isExplainSteps}
                            <div className="equationbox box2">
                                {/* <div className="row">
                                    <div className="backArrowBoxMobile" onClick={this.goBackPage}>
                                        <img style={{ marginRight: '10px', marginLeft: '7px' }} src={process.env.PUBLIC_URL + "/ic_arrow_back.png"} width={20} height={20} alt=""></img>
                                    </div>
                                    <div className="bookmarkMobile" onClick={this.makeBookmark}>
                                        <img style={{ marginRight: '10px', marginLeft: '7px' }} src={this.state.isBookmarked ? process.env.PUBLIC_URL + "/bookmark-icon-new.png" : process.env.PUBLIC_URL + "/bookmark-icon-outline-new.png"} width={20} height={20} alt=""></img>
                                    </div>
                                </div> */}
                                <div>
                                    {solution.action}
                                </div>
                                <div className="sol-divider-line divider"></div>
                                <div className="primary-color" style={{ fontSize: "20px" }}>
                                    {solution.problemTex !== undefined && solution.problemTex.includes("frac") ?
                                        <MathJax math={'\\(' + solution.problemTex + '\\)'} />
                                        :
                                        (solution.problemTex !== undefined && solution.problemTex.includes("^") ?
                                            <MathJax math={'\\(' + solution.problemTex + '\\)'} />
                                            :
                                            (solution.problemTex !== undefined && solution.problemTex.includes("pi") ?
                                                <MathJax math={solution.problemTex.replace('pi', "π")} />
                                                :
                                                <MathJax math={solution.problemTex} />
                                            )
                                        )
                                    }
                                </div>
                                {/* <div className="row">
                                    <div className="dot1 clipdot"></div>
                                    <div className="dot2 clipdot"></div>
                                </div> */}
                            </div>
                            {/* <div className="line1 clipline"></div>
                            <div className="line2 clipline"></div>
                            <div className="line3 clipline"></div>
                            <div className="line4 clipline"></div> */}

                            {/* <br>
                            </br> */}
                            <div className="solutionbox box2" style={{ marginTop: 15 }}>
                                <div className="row">
                                    <img className="solClip1" src={process.env.PUBLIC_URL + "/ic_paperclip_solution_screen.png"} alt=""></img>
                                    <img className="solClip2" src={process.env.PUBLIC_URL + "/ic_paperclip_solution_screen.png"} alt=""></img>
                                </div>
                                {/* <div className="row">
                                    <div className="dot3 clipdot"></div>
                                    <div className="dot4 clipdot"></div>
                                </div> */}
                                <div>
                                    Solution
                                </div>
                                <div className="sol-divider-line divider"></div>
                                <div style={{ fontSize: "20px" }}>
                                    <MathJax math={solution.resultTex} />
                                </div>
                                <button
                                    type="submit"
                                    className="solbtn"
                                    onClick={() => {
                                        this.props.moveToSolutionSteps()
                                        this.setState({
                                            isExplainSteps: !this.state.isExplainSteps
                                        })
                                    }}
                                >
                                    See Steps
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                <div>
                    {!this.state.isExplainSteps &&
                        <StepPage
                            solution={this.props.solution}
                            bookmark={this.makeBookmark}
                        ></StepPage>
                    }
                </div>
            </div>
        );
    }
    render() {
        return (
            <div className="solPageUI">
                <div className="row">
                    <div className="backArrowBox" style={{ marginTop: 15 }} onClick={this.goBackPage}>
                        <img style={{ marginRight: '10px', marginLeft: '7px' }} src={process.env.PUBLIC_URL + "/ic_arrow_back.png"} width={20} height={20} alt=""></img>
                    </div>
                    <div className="SolutionStepbox">
                        <div className="col-12">
                            {this.state.isExplainSteps && <div className="row">
                                <div className="backArrowBoxMobile" onClick={this.goBackPage}>
                                    <img style={{ marginRight: '10px', marginLeft: '12px' }} src={process.env.PUBLIC_URL + "/ic_arrow_back.png"} width={20} height={20} alt=""></img>
                                </div>
                                <div className="bookmarkMobile" onClick={this.makeBookmark}>
                                    <img style={{ marginRight: '7px', marginLeft: '7px' }} src={this.state.isBookmarked ? process.env.PUBLIC_URL + "/bookmark-icon-new.png" : process.env.PUBLIC_URL + "/bookmark-icon-outline-new.png"} width={20} height={20} alt=""></img>
                                </div>
                            </div>}
                            {this.state.isExplainSteps && <div className="solTitle" style={{ textAlign: "center", marginBottom: "20px", fontSize: "20px", fontWeight: '500', fontFamily: 'RobotoRegular' }}>
                                Solution
                            </div>
                            }
                            {this.renderStep(this.props.solution)}
                        </div>
                    </div>

                    <div className="solBookmark" style={{ marginTop: 15 }} onClick={this.makeBookmark}>
                        <img style={{ marginRight: '10px', marginLeft: '7px' }} src={this.state.isBookmarked ? process.env.PUBLIC_URL + "/bookmark-icon-new.png" : process.env.PUBLIC_URL + "/bookmark-icon-outline-new.png"} width={20} height={20} alt=""></img>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(SolutionCard);
