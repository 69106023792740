import React from "react";
import { Component } from "react";
import Segment from "./common/Segment"
import HistoryCard from "./common/HistoryCard"
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import axios from "axios";
import Pagination from '@material-ui/lab/Pagination';
import { createTheme, ThemeProvider } from "@material-ui/core";
const theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: '#01497c',
    },
    secondary: {
      // This is green.A700 as hex.
      main: '#01497c',
    },
  },
});
class History extends Component {

  state = {
    historyList: [],
    savedList: [],
    currentValueSelected: -1,
    loading: true,
    data: {},
    pageCount: 10,
    totalHistoryPageCount: 10,
    totalSavedPageCount: 10,
    historyCurrentPage: 1,
    savedCurrentPage: 1
  }

  componentDidMount() {

    this.setState({
      data: JSON.parse(window.localStorage.user).data,
    })

    var data = JSON.parse(window.localStorage.user).data
    this.loadHistoryAndSaved(1);
    this.loadHistoryData(data.user.id, data.token.accessToken, 1)
  }

  deleteHistoryData(item, userId, token, newValue) {
    const that = this;
    // var data = {
    //   "histRef": item.histRef,
    // }
    axios
      .delete(
        process.env.REACT_APP_API_URL + "/v1/history/" + userId,
        {
          data: {
            "histRef": item.histRef,
          },
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
          },
        },
      )
      .then(function (response) {
        that.setState({
          loading: true,
        })
        that.loadHistoryData(that.state.data.user.id, that.state.data.token.accessToken, that.state.currentValueSelected)
        // }

      })
      .catch(function (error) {
      });
  }
  loadHistoryData(userId, token, newValue) {
    const that = this;

    axios
      .get(
        process.env.REACT_APP_API_URL + "/v1/history/" + userId,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + token
          },
        }
      )
      .then(function (response) {
        if (response !== null && response.data !== null && response.data.items !== null) {

          var hlist = response.data.items
          var slist = []

          slist = (hlist).filter((item) => {
            return item.isBookmarked && item.isBookmarked === true;
          });

          var historyPageCount = Math.ceil(hlist.length / that.state.pageCount)
          var savedPageCount = Math.ceil(slist.length / that.state.pageCount)


          var historyCurrentPage = that.state.historyCurrentPage
          if (that.state.historyCurrentPage > historyPageCount) {
            historyCurrentPage = historyPageCount
          }


          var savedCurrentPage = that.state.savedCurrentPage
          if (that.state.savedCurrentPage > savedPageCount) {
            savedCurrentPage = savedPageCount
          }


          console.log("xx count : ", slist, " : savedPageCount :", savedPageCount)
          that.setState({
            historyList: hlist,
            currentValueSelected: 1,
            savedList: slist,
            loading: false,
            totalHistoryPageCount: (historyPageCount),
            totalSavedPageCount: (savedPageCount),
            historyCurrentPage: historyCurrentPage,
            savedCurrentPage: savedCurrentPage
          })
          that.loadHistoryAndSaved(newValue)
        }
      })
      .catch(function (error) {
      });
  }
  loadHistoryAndSaved(newValue) {
    this.setState({
      currentValueSelected: newValue,
      loading: false,
    })
  }


  getHistoryPaginatedData = () => {
    const startIndex = this.state.historyCurrentPage * this.state.pageCount - this.state.pageCount;
    const endIndex = startIndex + this.state.pageCount;
    return this.state.historyList.slice(startIndex, endIndex);
  };

  getSavedPaginatedData = () => {
    const startIndex = this.state.savedCurrentPage * this.state.pageCount - this.state.pageCount;
    const endIndex = startIndex + this.state.pageCount;
    return this.state.savedList.slice(startIndex, endIndex);
  };


  makeBookmark(history) {
    var data = history
    data.isBookmarked = !history.isBookmarked

    var that = this
    axios
      .put(
        process.env.REACT_APP_API_URL + "/v1/history/" + that.state.data.user.id,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer " + that.state.data.token.accessToken
          },
        },
      )
      .then(function (response) {
        that.loadHistoryData(that.state.data.user.id, that.state.data.token.accessToken, that.state.currentValueSelected)

      })
      .catch(function (error) {
      });
  }

  remove(history) {
    this.deleteHistoryData(history, this.state.data.user.id, this.state.data.token.accessToken, this.state.currentValueSelected)
  }

  renderItems() {
    if (this.state.currentValueSelected === 1) {
      const listItems = this.getHistoryPaginatedData().map((item, i) =>
        <HistoryCard key={i} item={item} bookmark={() => this.makeBookmark(item)} remove={() => this.remove(item)}></HistoryCard>
      );
      return listItems;
    } else {
      const listItems = this.getSavedPaginatedData().map((item, i) =>
        <HistoryCard key={i} item={item} bookmark={() => this.makeBookmark(item)} remove={() => this.remove(item)}></HistoryCard>
      );
      return listItems;
    }
  }

  valueDidChange(newValue) {
    if (this.state && this.state.currentValueSelected !== -1) {
      this.loadHistoryAndSaved(newValue)
    }
  }
  handlePageClick = (event, page) => {
    if (this.state.currentValueSelected === 1) {
      this.setState({
        historyCurrentPage: page,
        loading: false,
      })
    } else {
      this.setState({
        savedCurrentPage: page,
        loading: false,
      })
    }
    window.scrollTo(0, 0)

  }
  render() {
    return (
      <div className="history-container container">
        <div className="tittle">
          History
        </div>
        <div className="histcont">
          <div>
            <Segment segmentItems={['History', 'Saved']} valueDidChange={(newvalue) => { this.loadHistoryAndSaved(newvalue) }}></Segment>
          </div>
          <div style={{ fontSize: 15, textAlign: "right", marginBottom: "5px" }}>Clear all</div>
          {this.state.loading && <Skeleton count={5} width={500} />}
          {!this.state.loading &&
            this.renderItems()
          }
          <ThemeProvider theme={theme}>

            {this.state.currentValueSelected === 1 ?
              <Pagination color='primary' style={{ marginBottom: 10 }} count={this.state.totalHistoryPageCount} onChange={(event, page) => {
                this.handlePageClick(event, page)
              }}
              />
              :
              <Pagination color='primary' style={{ marginBottom: 10 }} count={this.state.totalSavedPageCount} onChange={(event, page) => {
                this.handlePageClick(event, page)
              }}
              />
            }
          </ThemeProvider>

        </div>
      </div >
    );
  }
}

export default History;