/* eslint-disable array-callback-return */
import { EquationElement, EquationSymbol, Expression, FractionExpression, SquareExpression } from "./CalculatorUtil";

export default function CalculatorTextArea(props) {
    const checkHaveSquare = (exp1) => {
        let squareB = 0 
        exp1.map((eq1) =>{
            if(eq1 instanceof SquareExpression){
                let expBottom = checkHaveSquare(eq1.elements)
                let expB = expBottom  + 12
                if (expB > squareB) {
                    squareB = expB
                }
            }
        })
        return squareB;

    }

    const getMargin = (exp1) => {
        let squareB = 0 
        exp1.map((eq1) =>{
            console.log("getMargin : " , eq1)
            if(eq1 instanceof SquareExpression){
                let expBottom = checkHaveSquare(eq1.elements)
                let expB = expBottom  + 12
                if (expB > squareB) {
                    squareB = expB
                }
            }
        })
        console.log("squareB : " , squareB)
        return squareB;
    }

    const renderEquations = (equations, oldIndex, makeEmptyAsBox = false, fontSize = 20  ) => {

        let return_elements = []
        let e_index = 0

        const checkHaveFraction = (exp) => {
            let bottom = 0
            exp.map((eq) => {
                if (eq instanceof FractionExpression) {
                    let bottom_numerator = checkHaveFraction(eq.elements)
                    let bottom_denaminator = checkHaveFraction(eq.secondElements)
                    let bot = 26 + bottom_numerator + bottom_denaminator
                    if (bot > bottom) {
                        bottom = bot
                    }
                }
            })
            return bottom
        }


    


        let b = 0
        equations.map((eq) => {
            var margin = 12
            // if (isSquare) {
            //     margin = 0
            // }
            if (eq instanceof FractionExpression) {
                b = margin + checkHaveFraction(eq.secondElements)
            }
        })

        let cursor = {
            backgroundColor: 'red',
            width: 2,
            height: 24,
            marginBottom: b
        }


        let hasCursor = false


        if (props.currentCursorItemId === -1 && !makeEmptyAsBox) {
            return_elements.push(
                <text style={cursor}></text>
            )
        }
        equations.map((e) => {
            let current_index = oldIndex + e_index
            if (oldIndex !== "") {
                current_index = oldIndex + '.' + e_index
            }

            if (props.currentCursorItemId === e.id) {
                hasCursor = true
            } else {
                hasCursor = false
            }


            if (e instanceof EquationSymbol) {
                let symbol = {
                    color: 'grey',
                    fontSize: fontSize,
                    paddingLeft: 2,
                    paddingRight: 2,
                    height: 25,
                    marginBottom: b,
                    // height: '100%',
                }
                return_elements.push(
                    <text style={symbol} onClick={() => { props.onElementPress(current_index, e.id) }}>{e.element}</text>
                )
            } else if (e instanceof EquationElement) {
                let s = {
                    color: 'black',
                    fontSize: fontSize,
                    textAlign: 'center',
                    textAlignVertical: 'center',
                    alignItems: 'center',
                    height: 25,
                    // height: '100%',
                    minWidth: 5
                }
                if (e.element === '') {
                    if ((makeEmptyAsBox && (equations.length <= 1)) || hasCursor) {
                        s.width = 15
                        s.borderWidth = 1
                        s.borderColor = '#01497C'
                        s.borderStyle = 'solid'
                    } else {
                        s.width = 10
                    }
                }
                s.marginBottom = b
                return_elements.push(
                    <div style={s} onClick={() => { props.onElementPress(current_index, e.id) }} >
                        {e.element}
                    </div>
                )
            } else if (e instanceof FractionExpression) {
                current_index = oldIndex + (e_index + 1) + '.0'
                if (oldIndex !== "") {
                    current_index = oldIndex + '.' + (e_index + 1) + '.0'
                }

                let current_index_1 = oldIndex + (e_index + 1) + '.1'
                if (oldIndex !== "") {
                    current_index_1 = oldIndex + '.' + (e_index + 1) + '.1'
                }
                return_elements.push(
                    <div style={{ flexDirection: 'column', justifyContent: "center", marginLeft: 2, marginRight: 2 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'flex-end' }}>
                            {renderEquations(e.elements, current_index, true, fontSize)}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'flex-end', borderTopColor: 'black', borderTopWidth: 2, borderTopStyle: 'solid' }}>
                            {renderEquations(e.secondElements, current_index_1, true, fontSize)}
                        </div>
                    </div>
                )
            } else if (e instanceof SquareExpression) {
                current_index = oldIndex + (e_index + 1) + '.0'
                if (oldIndex !== "") {
                    current_index = oldIndex + '.' + (e_index + 1) + '.0'
                }

                let current_index_1 = oldIndex + (e_index + 1) + '.1'
                if (oldIndex !== "") {
                    current_index_1 = oldIndex + '.' + (e_index + 1) + '.1'
                }
                return_elements.push(
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", marginLeft: 2, marginRight: 2, marginBottom: b }}>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'flex-end' }}>
                            {renderEquations(e.elements, current_index, true, fontSize)}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: 'flex-end', marginLeft: 2, paddingBottom: getMargin(e.elements) + 12  }}>
                            {renderEquations(e.secondElements, current_index_1, true, (fontSize - 2) < 8 ? 8 : (fontSize - 2) )} 
                        </div>
                    </div>
                )
            } else if (e instanceof Expression) {
                return_elements.push(
                    <div style={{ flexDirection: 'row', justifyContent: "center" }}>
                        <div style={{ flexDirection: 'row' }}>
                            {renderEquations(e.elements, current_index)}
                        </div>
                    </div>
                )
            }
            e_index += 1
            current_index = oldIndex + e_index
            if (oldIndex !== "") {
                current_index = oldIndex + '.' + e_index
            }
            
            if (props.currentCursorItemId === e.id) {
                return_elements.push(
                    <text style={cursor}></text>
                )
            }
        })
        return return_elements
    }

    return (
        <div style={{ width: 250, whiteSpace: "nowrap", display: 'flex', flexWrap: "nowrap", overflowX: "scroll", marginLeft: 10, marginRight: 10, alignItems: 'flex-end' }} >
            {renderEquations(props.equations, "", false)}
        </div>
    )
}