import React from "react";
import MathJax from 'react-mathjax-preview';

export default function HistoryCard(props) {
    function editEquation() {
        let calcExpression = props.item.equation.replace(/ /g, "");
        window.location.href =
            window.location.origin + "/calculator/" + calcExpression;
    }

    function gotoSolutionSteps() {
        if (props.item.type === 'text') {
            window.location.href =
                window.location.origin +
                "/solution/" +
                encodeURIComponent(props.item.equationText) + "?type=new-native-text&history-id=" + props.item.histRef + "&isBookmarked=" + props.item.isBookmarked;
        } else {
            window.location.href =
                window.location.origin +
                "/solution/" +
                encodeURIComponent(props.item.equation) + "?type=new-native&history-id=" + props.item.histRef + "&isBookmarked=" + props.item.isBookmarked;
        }
        // let calcExpression = props.item.equation.replace(/ /g, "");
        // window.location.href =
        //     window.location.origin + "/solution/" + calcExpression + "?type=new_native&history-id=" + props.item.histRef+"&isBookmarked=" +props.item.isBookmarked ;
    }
    return (
        <div className="history-card-container">
            <div className="history-equation-card">
                <div style={{ position: "relative" }} >
                    Equation
                    <div className="history-equation-options">
                        <img className=" history-equation-option-icon" width={20} height={20} style={{ fill: "#01497c"  }} src={process.env.PUBLIC_URL + "/Icon_edit.png"} onClick={() => editEquation()} alt=""></img>
                        {
                            props.item.isBookmarked === true ?
                                <img className="history-equation-option-icon" src={process.env.PUBLIC_URL + "/bookmark-icon-new.png"} width={22} height={22} alt="" onClick={() => props.bookmark(props.item)}></img>
                                :
                                <img className="history-equation-option-icon" src={process.env.PUBLIC_URL + "/bookmark-icon-outline-new.png"} width={22} height={22} alt="" onClick={() => props.bookmark(props.item)}></img>
                        }
                        <img src={process.env.PUBLIC_URL + "/Icon_delete.png"} width={20} height={20} alt="" onClick={() => props.remove(props.item)}></img>
                    </div>
                </div>
                <div style={{ marginTop: 15 }}>

                    {/* <div className="problembox box1"> */}
                    {props.item.equation.includes("pi") ?
                        <MathJax math={props.item.equation.replace('pi', "π")} />
                        :
                        <MathJax math={props.item.equation} />
                    }
                    {/* </div> */}

                    {/* <MathJax.Provider>
                        <div
                            style={{
                                fontWeight: 500,
                                textAlign: 'left',
                                fontSize: 20,
                                color: '#01497C',
                                paddingTop: "5px"
                            }}
                        >
                            <MathJax.Node formula={props.item.equation} />
                            {props.item.equation}
                        </div>
                    </MathJax.Provider> */}
                </div>
            </div>
            <div className="history-divider-line divider"> </div>
            <div className="history-solution-card"
                onClick={() => gotoSolutionSteps()}
            >
                <div>
                    <label>Solution</label>
                </div>
                <div>
                    {/* <div className="problembox box1"> */}
                    <MathJax math={props.item.solution} />
                    {/* </div> */}

                    {/* <MathJax.Provider>
                        <div
                            style={{
                                fontWeight: 500,
                                textAlign: "left",
                                fontSize: 20,
                                color: '#01497C',
                            }}
                        >
                            {props.item.solution}
                        </div>
                    </MathJax.Provider> */}
                </div>
                {/* <div>
                    <button className="theme-button see-steps-button" onClick={() => gotoSolutionSteps()}>
                        See steps
                    </button>
                </div> */}
                {/* <div className="history-date">
                    {props.item.date.toString()}
                </div> */}
            </div>
        </div >
    )
}

