let user = JSON.parse(localStorage.getItem("user"));
const initialState = user ? { isLoggedIn: true, user } : {};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN":
      return action.payload;
    case "LOGOUT":
      return window.location.reload();
    default:
      return state;
  }
};
export default reducer;
