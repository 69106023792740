import React, { useRef } from "react";

export const AddImageCardType = {
    SelectImage: 1,
    CaptureImage: 2
};

export default function AddImageCard(props) {
    var inputElement = useRef(null)

    return (
        <div className="imagebox" style={{margin:'auto' , marginTop:10 , marginBottom : 10}}>
            <div className="imginsidebox">
                <button
                    className="imgbtn"
                    type="submit"
                    style={{ position: 'absolute', right: 0 }}
                    onClick={() => {
                        inputElement.value = null
                        props.remove()
                    }}>
                    {
                        props.imageUrl !== null && props.imageUrl !== undefined &&
                        <img src={process.env.PUBLIC_URL + "/close.png"} width={20} height={20} alt=""></img>
                    }
                </button>
                {
                props.imageUrl !== null && props.imageUrl !== undefined ?
                        (
                            props.croppedImageUrl !== null && props.croppedImageUrl !== undefined ?
                                <img src={props.croppedImageUrl} style={{ width: '100%', height: '100%', objectFit: 'scale-down' }} alt=""></img> :
                                <img src={props.imageUrl} style={{ width: '100%', height: '100%', objectFit: 'scale-down' }} alt=""></img>
                        )
                        :
                        (
                            props.type === AddImageCardType.SelectImage
                                ?
                                <img src={process.env.PUBLIC_URL + "/addimage.png"} style={{ width: '100%', height: '100%', padding: '50px' }} alt=""></img> :
                                <img src={process.env.PUBLIC_URL + "/webcam.png"} style={{ width: '100%', height: '100%', padding: '50px' }} alt=""></img>
                        )
                }
                <button
                    className="imgbtn"
                    type="submit"
                    style={{ position: 'absolute', right: -4, top: 170 }}
                    onClick={props.crop}
                >   {
                        props.imageUrl !== null && props.imageUrl !== undefined &&
                        (<div className="imgbtn1">
                            <img src={process.env.PUBLIC_URL + "/crop-white.png"} width={20} height={20} color="white" alt=""></img>
                            Crop
                        </div>)
                    }

                </button>
            </div>
            <h3 className="imgtittle">
                {
                    props.type === AddImageCardType.SelectImage ?
                        'Insert an Image' : 'Click an Image'
                }
            </h3>
            <div style={{ margin: "25px", fontSize: "16px", fontWeight: "400" }}>
                {
                    props.type === AddImageCardType.SelectImage ?
                        'Drop an image here or click "Add Image" button below.' :
                        'Click a picture of the problem statement using your Webcam'
                }
            </div>
            <input
                ref={input => inputElement = input}
                style={{ opacity: 0, height: 0, display: 'none' }}
                type="file" id="image_uploads"
                name="image_uploads"
                accept=".jpg, .jpeg, .png"                
                onChange={(e) => {
                        const file = e.target.files[0];
                        // const file = e.target.files[0];        
                        if (!file) return; // else it will throw an parse error         
                        const url = URL.createObjectURL(file);
                        props.addImage(url)
                }}
            />
            <button
                className="submit-btn-fm btn"
                type="submit "
                onClick={() => {                    
                    if (props.imageUrl !== null && props.imageUrl !== undefined) {
                        inputElement.value = null
                        props.submit()
                    } else if (props.imageSrc !== null && props.imageSrc !== undefined) {
                        props.submit()
                    } else {
                        if (props.type === AddImageCardType.SelectImage) {
                            inputElement.click()
                        } else {
                            props.captureImage()
                        }
                    }
                }}
            >
                {
                    (props.imageUrl !== null && props.imageUrl !== undefined) ? "Submit" : (props.type === AddImageCardType.SelectImage ? "+ Add Image" : "+ Open Webcam")
                }
            </button>
        </div>
    )
} 