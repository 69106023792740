import React from "react";
import MathJax from "react-mathjax";

import { Component } from "react";


class FinalSolutionCard extends Component {
  async componentDidMount() {}

  showSolutionSteps(event) {
    event.preventDefault();
    this.props.showSolutionSteps();
  }

  render() {
    return (
      <div className="row">
        <div className="col-12">
          <div className="sol-card-box bl-theme final-solution">
            <div className="sol-card-title bl-theme">
              {" "}
              Solution{""}
              <span className="sol-type">
                {" "}
                (from {this.props.solutionType} )
              </span>{" "}
            </div>
            <span className="final-soln-txt">Your final solution is:</span>
            <MathJax.Provider>
              <MathJax.Node formula={this.props.finalSolution} />
              <button
              type="submit"
              className="btn rounded-pill submit-btn-fm small mt-2 mt-4"
              onClick={(event) => this.showSolutionSteps(event)}
            >
              Explain how
            </button>
            </MathJax.Provider>
          </div>
        </div>
      </div>
    );
  }
}



export default FinalSolutionCard;
