import React from "react";
import { SegmentedControl } from "segmented-control";

export default function Segment(props) {
  return (
      <div>
        <div>
          <SegmentedControl
            name="oneDisabled"
            options={[
              { label: "History", value: 1, default: true},
              { label: "Bookmarks", value: 2 },
            ]}
            setValue={(newValue) => {
              props.valueDidChange(newValue);
            }}
            style={{
              width: 300,
              height: 40,
              borderRadius: 10,
              color: "#01497C", 
              // alignSelf: "center",
              lineHeight: 0,
            }} // purple400
          />
        </div>
      </div>
  );
}
