import React from "react";

export default function TermsAndCondition() {
    const myHTML = `<html>

    <head>
    <meta http-equiv=Content-Type content="text/html; charset=utf-8">
    <meta name=Generator content="Microsoft Word 15 (filtered)">
    <style>
    <!--
     /* Font Definitions */
     @font-face
        {font-family:"Cambria Math";
        panose-1:2 4 5 3 5 4 6 3 2 4;}
    @font-face
        {font-family:Calibri;
        panose-1:2 15 5 2 2 2 4 3 2 4;}
     /* Style Definitions */
     p.MsoNormal, li.MsoNormal, div.MsoNormal
        {margin-top:0in;
        margin-right:0in;
        margin-bottom:8.0pt;
        margin-left:0in;
        line-height:107%;
        font-size:11.0pt;
        font-family:"Calibri",sans-serif;}
    p.MsoListParagraph, li.MsoListParagraph, div.MsoListParagraph
        {margin-top:0in;
        margin-right:0in;
        margin-bottom:8.0pt;
        margin-left:.5in;
        line-height:107%;
        font-size:11.0pt;
        font-family:"Calibri",sans-serif;}
    p.MsoListParagraphCxSpFirst, li.MsoListParagraphCxSpFirst, div.MsoListParagraphCxSpFirst
        {margin-top:0in;
        margin-right:0in;
        margin-bottom:0in;
        margin-left:.5in;
        line-height:107%;
        font-size:11.0pt;
        font-family:"Calibri",sans-serif;}
    p.MsoListParagraphCxSpMiddle, li.MsoListParagraphCxSpMiddle, div.MsoListParagraphCxSpMiddle
        {margin-top:0in;
        margin-right:0in;
        margin-bottom:0in;
        margin-left:.5in;
        line-height:107%;
        font-size:11.0pt;
        font-family:"Calibri",sans-serif;}
    p.MsoListParagraphCxSpLast, li.MsoListParagraphCxSpLast, div.MsoListParagraphCxSpLast
        {margin-top:0in;
        margin-right:0in;
        margin-bottom:8.0pt;
        margin-left:.5in;
        line-height:107%;
        font-size:11.0pt;
        font-family:"Calibri",sans-serif;}
    .MsoChpDefault
        {font-family:"Calibri",sans-serif;}
    .MsoPapDefault
        {margin-bottom:8.0pt;
        line-height:107%;}
    @page WordSection1
        {size:595.3pt 841.9pt;
        margin:1.0in 1.0in 1.0in 1.0in;}
    div.WordSection1
        {page:WordSection1;}
     /* List Definitions */
     ol
        {margin-bottom:0in;}
    ul
        {margin-bottom:0in;}
    -->
    </style>
    
    </head>
    
    <body lang=EN-US link=blue vlink="#954F72" style='word-wrap:break-word'>
    
    <div class=WordSection1>
    
    <p class=MsoNormal><b><span lang=EN-IN style='font-size:12.0pt;line-height:
    107%'>TERMS OF USE</span></b></p>
    
    <p class=MsoNormal><span lang=EN-IN style='font-size:12.0pt;line-height:107%'>&nbsp;</span></p>
    
    <p class=MsoNormal><b><span lang=EN-IN style='font-size:12.0pt;line-height:
    107%;color:#333333'>Introduction</span></b></p>
    
    <p class=MsoNormal><span lang=EN-IN style='font-size:12.0pt;line-height:107%;
    color:#333333'>Thank you for visiting&nbsp;<span style=''>FEVMath</span>&nbsp;which
    is owned and operated by FEVTutor, Inc. DBA <span style=''>FEVMath</span>.
    These Website Standard Terms And Conditions (these “Terms” or these “Website
    Standard Terms And Conditions”) contained herein on this webpage, shall govern
    your use of this website, including all pages within this website including
    (collectively referred to herein below as this “Website”). These Terms apply in
    full force and effect to your use of this Website and by using this Website,
    you expressly accept all terms and conditions contained herein in full. You
    must not use this Website, if you have any objection to any of these Website Standard
    Terms And Conditions.</span></p>
    
    <p class=MsoNormal><b><span lang=EN-IN style='font-size:12.0pt;line-height:
    107%;color:#333333'>Intellectual Property Rights.</span></b></p>
    
    <p class=MsoNormal><span lang=EN-IN style='font-size:12.0pt;line-height:107%;
    color:#333333'>Other than content you own, which you may have opted to include
    on this Website, under these Terms, <span style=''>FEVMath</span>
    and/or its licensors own all rights to the intellectual property and material
    contained in this Website, and all such rights are reserved.</span></p>
    
    <p class=MsoNormal><span lang=EN-IN style='font-size:12.0pt;line-height:107%;
    color:#333333'>You are granted a limited license only, subject to the
    restrictions provided in these Terms, for purposes of viewing the material
    contained on this Website.</span></p>
    
    <p class=MsoNormal><b><span lang=EN-IN style='font-size:12.0pt;line-height:
    107%;color:#333333'>Restrictions.</span></b></p>
    
    <p class=MsoNormal><b><span lang=EN-IN style='font-size:12.0pt;line-height:
    107%;color:#333333'>You are expressly and emphatically restricted from all of
    the following:</span></b></p>
    
    <p class=MsoListParagraphCxSpFirst style='text-indent:-.25in'><span lang=EN-IN
    style='font-size:12.0pt;line-height:107%;color:#333333'>1.<span
    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
    lang=EN-IN style='font-size:12.0pt;line-height:107%;color:#333333'>publishing
    any Website material in any media;</span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span
    lang=EN-IN style='font-size:12.0pt;line-height:107%;color:#333333'>2.<span
    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
    lang=EN-IN style='font-size:12.0pt;line-height:107%;color:#333333'>selling,
    sublicensing and/or otherwise commercializing any Website material;</span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span
    lang=EN-IN style='font-size:12.0pt;line-height:107%;color:#333333'>3.<span
    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
    lang=EN-IN style='font-size:12.0pt;line-height:107%;color:#333333'>publicly
    performing and/or showing any Website material;</span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span
    lang=EN-IN style='font-size:12.0pt;line-height:107%;color:#333333'>4.<span
    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
    lang=EN-IN style='font-size:12.0pt;line-height:107%;color:#333333'>using this
    Website in any way that is, or may be, damaging to this Website;</span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span
    lang=EN-IN style='font-size:12.0pt;line-height:107%;color:#333333'>5.<span
    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
    lang=EN-IN style='font-size:12.0pt;line-height:107%;color:#333333'>using this
    Website in any way that impacts user access to this Website;</span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span
    lang=EN-IN style='font-size:12.0pt;line-height:107%;color:#333333'>6.<span
    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
    lang=EN-IN style='font-size:12.0pt;line-height:107%;color:#333333'>using this
    Website contrary to applicable laws and regulations, or in a way that causes,
    or may cause, harm to the Website, or to any person or business entity;</span></p>
    
    <p class=MsoListParagraphCxSpMiddle style='text-indent:-.25in'><span
    lang=EN-IN style='font-size:12.0pt;line-height:107%;color:#333333'>7.<span
    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
    lang=EN-IN style='font-size:12.0pt;line-height:107%;color:#333333'>engaging in
    any data mining, data harvesting, data extracting or any other similar activity
    in relation to this Website, or while using this Website;</span></p>
    
    <p class=MsoListParagraphCxSpLast style='text-indent:-.25in'><span lang=EN-IN
    style='font-size:12.0pt;line-height:107%;color:#333333'>8.<span
    style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span></span><span
    lang=EN-IN style='font-size:12.0pt;line-height:107%;color:#333333'>using this
    Website to engage in any advertising or marketing;</span></p>
    
    <p class=MsoNormal><span lang=EN-IN style='font-size:12.0pt;line-height:107%;
    color:#333333'>Certain areas of this Website are restricted from access by you
    and <span style=''>FEVMath</span> may further restrict access
    by you to any areas of this Website, at any time, in its sole and absolute
    discretion. Any user ID and password you may have for this Website are
    confidential and you must maintain confidentiality of such information.</span></p>
    
    <p class=MsoNormal><b><span lang=EN-IN style='font-size:12.0pt;line-height:
    107%;color:#333333'>Your Content.</span></b></p>
    
    <p class=MsoNormal><span lang=EN-IN style='font-size:12.0pt;line-height:107%;
    color:#333333'>In these Website Standard Terms And Conditions, “Your Content”
    shall mean any audio, video, text, images or other material you choose to
    display on this Website. With respect to Your Content, by displaying it, you
    grant <span style=''>FEVMath</span> a non-exclusive,
    worldwide, irrevocable, royalty-free, sublicensable license to use, reproduce,
    adapt, publish, translate and distribute it in any and all media.</span></p>
    
    <p class=MsoNormal><span lang=EN-IN style='font-size:12.0pt;line-height:107%;
    color:#333333'>Your Content must be your own and must not be infringing on any
    third party’s rights. <span style=''>FEVMath</span> reserves
    the right to remove any of Your Content from this Website at any time, and for
    any reason, without notice.</span></p>
    
    <p class=MsoNormal><b><span lang=EN-IN style='font-size:12.0pt;line-height:
    107%;color:#333333'>No warranties.</span></b></p>
    
    <p class=MsoNormal><span lang=EN-IN style='font-size:12.0pt;line-height:107%;
    color:#333333'>This Website is provided “as is,” with all faults, and <span
    style=''>FEVMath</span> makes no express or implied
    representations or warranties, of any kind related to this Website or the
    materials contained on this Website. Additionally, nothing contained on this
    Website shall be construed as providing consult or advice to you.</span></p>
    
    <p class=MsoNormal><b><span lang=EN-IN style='font-size:12.0pt;line-height:
    107%;color:#333333'>Limitation of liability.</span></b></p>
    
    <p class=MsoNormal><span lang=EN-IN style='font-size:12.0pt;line-height:107%;
    color:#333333'>In no event shall <span style=''>FEVMath</span>,
    nor any of its officers, directors and employees, be liable to you for anything
    arising out of or in any way connected with your use of this Website, whether
    such liability is under contract, tort or otherwise, and <span
    style=''>FEVMath</span>, including its officers, directors and
    employees shall not be liable for any indirect, consequential or special
    liability arising out of or in any way related to your use of this Website.</span></p>
    
    <p class=MsoNormal><b><span lang=EN-IN style='font-size:12.0pt;line-height:
    107%;color:#333333'>Indemnification</span></b><span lang=EN-IN
    style='font-size:12.0pt;line-height:107%;color:#333333'>.</span></p>
    
    <p class=MsoNormal><span lang=EN-IN style='font-size:12.0pt;line-height:107%;
    color:#333333'>You hereby indemnify to the fullest extent <span
    style=''>FEVMath</span> from and against any and all
    liabilities, costs, demands, causes of action, damages and expenses (including
    reasonable attorney’s fees) arising out of or in any way related to your breach
    of any of the provisions of these Terms.</span></p>
    
    <p class=MsoNormal><b><span lang=EN-IN style='font-size:12.0pt;line-height:
    107%;color:#333333'>Severability.</span></b></p>
    
    <p class=MsoNormal><span lang=EN-IN style='font-size:12.0pt;line-height:107%;
    color:#333333'>If any provision of these Terms is found to be unenforceable or
    invalid under any applicable law, such unenforceability or invalidity shall not
    render these Terms unenforceable or invalid as a whole, and such provisions shall
    be deleted without affecting the remaining provisions herein.</span></p>
    
    <p class=MsoNormal><b><span lang=EN-IN style='font-size:12.0pt;line-height:
    107%;color:#333333'>Variation of Terms.</span></b></p>
    
    <p class=MsoNormal><span lang=EN-IN style='font-size:12.0pt;line-height:107%;
    color:#333333;'>FEVMath</span><span lang=EN-IN
    style='font-size:12.0pt;line-height:107%;color:#333333'> is permitted to revise
    these Terms at any time as it sees fit, and by using this Website you are
    expected to review such Terms on a regular basis to ensure you understand all
    terms and conditions governing use of this Website.</span></p>
    
    <p class=MsoNormal><b><span lang=EN-IN style='font-size:12.0pt;line-height:
    107%;color:#333333'>Assignment.</span></b></p>
    
    <p class=MsoNormal><span lang=EN-IN style='font-size:12.0pt;line-height:107%;
    color:#333333;'>FEVMath</span><span lang=EN-IN
    style='font-size:12.0pt;line-height:107%;color:#333333'> shall be permitted to
    assign, transfer, and subcontract its rights and/or obligations under these
    Terms without any notification or consent required. However you shall not be
    permitted to assign, transfer, or subcontract any of your rights and/or
    obligations under these Terms.</span></p>
    
    <p class=MsoNormal><b><span lang=EN-IN style='font-size:12.0pt;line-height:
    107%;color:#333333'>Entire Agreement.</span></b></p>
    
    <p class=MsoNormal><span lang=EN-IN style='font-size:12.0pt;line-height:107%;
    color:#333333'>These Terms, including any legal notices and disclaimers
    contained on this Website, constitute the entire agreement between <span
    style=''>FEVMath</span> and you in relation to your use of
    this Website, and supersede all prior agreements and understandings with
    respect to the same.</span></p>
    
    <p class=MsoNormal><b><span lang=EN-IN style='font-size:12.0pt;line-height:
    107%;color:#333333'>Governing Law &amp; Jurisdiction.</span></b></p>
    
    <p class=MsoNormal><span lang=EN-IN style='font-size:12.0pt;line-height:107%;
    color:#333333'>These Terms will be governed by and construed in accordance with
    the laws of the State of Massachusetts, and you submit to the non-exclusive
    jurisdiction of the state and federal courts located in Massachusetts for the
    resolution of any disputes.</span></p>
    
    <p class=MsoNormal><b><span lang=EN-IN style='font-size:12.0pt;line-height:
    107%;color:#333333'>Questions, Cancellations and Refunds:</span></b></p>
    
    <p class=MsoNormal><span lang=EN-IN style='font-size:12.0pt;line-height:107%;
    color:#333333'>Customer satisfaction is our priority and we will do everything
    possible to make sure you are satisfied. If you have any questions about the
    Site or the Terms of Use, or have a refund request, please contact us at:</span></p>
    
    <p class=MsoNormal><span lang=EN-IN style='font-size:12.0pt;line-height:107%;
    '>FEVMath</span></p>
    
    <p class=MsoNormal><span lang=EN-IN style='font-size:12.0pt;line-height:107%'>500
    W Cummings Park, Suite 2700</span></p>
    
    <p class=MsoNormal><span lang=EN-IN style='font-size:12.0pt;line-height:107%'>Woburn,
    MA 02155</span></p>
    
    <p class=MsoNormal><span lang=EN-IN style='font-size:12.0pt;line-height:107%'>Phone:
    +1 781 497 5144</span></p>
    
    <p class=MsoNormal><span lang=EN-IN style='font-size:12.0pt;line-height:107%'>Email:
    <span style=''>info@fevmath.com</span></span></p>
    
    </div>
    
    </body>
    
    </html>
    `;

    return (
        <div className="container" style={{ color: "black" }}>
            <h2>Terms of use</h2>
            <div style={{ marginTop: 30 }}>
                <div dangerouslySetInnerHTML={{ __html: myHTML }} />
            </div>
        </div>
    );
}
