// import logo from "./logo.svg";
import React from "react";
import { BrowserRouter, Link, Redirect, Route, Switch } from "react-router-dom";

import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";

import ScrollToTop from "./components/common/ScrollToTop";
import {
  faCheckSquare,
  faCoffee,
  faEye,
  faEyeSlash,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";

// import Home from "./components/Home";
import AddImage from "./components/AddImage";
import CalculatorNew1 from "./components/CalculatorNew1";
import GetHelp from "./components/GetHelp";
import History from "./components/History";
// import Profile from "./components/Profile";
import Solution from "./components/solution/Solution";

import { connect } from "react-redux";
import { login, logout, history } from "./actions";

import "./App.css";
import { Component } from "react";
import axios from "axios";
import NavBar from "./NavBar";
import ReactGA from 'react-ga';
// import Change from "./Change";

// import FacebookProvider, { Like, Login } from "react-facebook-sdk";
import GoogleLogin from "react-google-login";

import "react-image-crop/dist/ReactCrop.css";
import "react-dropzone-uploader/dist/styles.css";

import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import { store } from "react-notifications-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TermsAndCondition from "./components/TermsAndCondition";
import PrivacyPolicy from "./components/PrivacyPolicy";
import SupportedProblemTypes from "./components/SupportedProblemTypes";
import About from "./components/About";
import { Typewriter } from "react-simple-typewriter";
import AppStore from "./components/AppStore";
import InfoCards from "./components/InfoCards";
import OnboardingFeatures from "./components/OnboardingFeatures";
import aboutImg from "./assets/svg/about.svg";
import landing from "./assets/svg/landing.svg";
import signup from "./assets/svg/signup.svg";
import Footer from "./components/Footer";

library.add(
  fab,
  faCheckSquare,
  faCoffee,
  faEye,
  faEyeSlash,
  faAngleDown,
  faAngleUp
);

const TRACKING_ID = "G-YMM134WM6P";
ReactGA.initialize(TRACKING_ID);


// import { stat } from "fs";

// const imageToBase64 = require("image-to-base64");

class App extends Component {
  // constructor(props) {
  //   super(props);

  //   this.signUpUser = this.signUpUser.bind(this);
  // }
  state = {
    // showPopup: false,
    emailLogin: false,
    emailSignup: false,
    forgotPassword: false,
    resetPasswordLink: false,
    verifyEmailLink: false,
    termsAndCondition: false,
    privacyPolicy: false,
    getHelp: false,
    about: false,
    signup: {
      username: "",
      password: "",
    },
    login: {
      username: "",
      password: "",
    },
    forgotPasswordEmail: "",
    resetPassword: "",
    resetPasswordAgain: "",
    hamburgerStatus: false,
    query_params: {},
    showPassword: false,
    sessionObj: {},
  };

  async componentDidMount() {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.pageview(window.location.pathname);

    // console.log("$$$ Environment variable:");
    // console.log(process.env.REACT_APP_ELECTRON_SOURCE_URL);
    // Bypass UI and login the user directly for testing
    // let sessionObj = {
    //   username: "Chaitanya",
    //   emailId: "chaitanya@transnoesis.com",
    //   loginType: "Email",
    //   isLoggedIn: true,
    // };
    // this.props.login(sessionObj);

    this.query_params = this.parseQuery(window.location.search.substring(1));

    if (
      this.query_params["resetToken"] &&
      this.query_params["resetToken"] !== ""
    ) {
      this.setState({
        resetPasswordLink: true,
      });
    } else if (
      this.query_params["emailVerificationToken"] &&
      this.query_params["emailVerificationToken"] !== ""
    ) {
      this.setState({
        verifyEmailLink: true,
      });
      this.verifyEmail();
    } else if (this.checkPath(window.location.href, "termsAndCondition")) {
      this.setState({
        termsAndCondition: true,
      });
    } else if (this.checkPath(window.location.href, "privacyPolicy")) {
      this.setState({
        privacyPolicy: true,
      });
    } else if (this.checkPath(window.location.href, "getHelp")) {
      this.setState({
        getHelp: true,
      });
    } else if (this.checkPath(window.location.href, "about")) {
      this.setState({
        about: true,
      });
    }
  }

  // this.props.history([]);

  parseQuery = (queryString) => {
    var query = {};
    var pairs = (
      queryString[0] === "?" ? queryString.substr(1) : queryString
    ).split("&");
    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split("=");
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
    }
    return query;
  };

  checkPath = (path, checkPath) => {
    let paths = path.split("/");
    if (paths[paths.length - 1] === checkPath) {
      return true;
    }
    return false;
  };

  // handleOnCropChange = (files, rejectedFiles) => {
  //   if (rejectedFiles && rejectedFiles.length > 0) {
  //     // this.ver
  //   }
  // }

  isEquation = (mathInput) => {
    const comparators = ["<=", ">=", "=", "<", ">"];
    let isEquation = false;

    comparators.forEach((comparator) => {
      if (mathInput.includes(comparator)) isEquation = true;
    });
    return isEquation;
  };

  // togglePopup = () => {
  //   this.setState({
  //     equation: {
  //       asciimath: "",
  //       latex: "",
  //     },
  //     showPopup: false,
  //   });
  // };

  toggleShowPassword = () => {
    this.setState({
      showPassword: !this.state.showPassword,
    });
  };

  fbNewLoginBtn = () => {
    // login with facebook then authenticate with the API to get a JWT auth token
    const that = this;

    window.FB.login(function (response) {
      console.log("Inside login response!!!");
      console.log(response);

      if (response && response.authResponse) {
        that.handleSocialLogin(response.authResponse.accessToken, "facebook");
      }
    });
  };

  handleSocialLogin = (accessToken, platform) => {
    const that = this;

    const accessTokenData = {
      access_token: accessToken,
    };

    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "/v1/auth/" + platform,
          accessTokenData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          if (
            response.data.user &&
            response.data.user.email &&
            response.data.user.email !== ""
          ) {
            let sessionObj = {
              username: response.data.user.name,
              emailId: response.data.user.email,
              loginType: "facebook",
              isLoggedIn: true,
              profileImgUrl: response.data.user.picture,
              data: response.data
            };

            that.setState({
              sessionObj: sessionObj,
            });

            that.props.login(sessionObj);
          }
        })
        .catch(function (error) {
          console.log("Response in then catch!!");
          console.log(error);

          if (
            (error.response && error.response.status === 409) ||
            (error.code && error.code === 409)
          ) {
            store.addNotification({
              title: "Unable to create account!",
              message:
                "There seems to be an account with same email. Please try again with different email ID",
              type: "danger",
              insert: "top",
              container: "bottom-left",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
              },
            });
          }

          // that.resetSignUp();
        });
    } catch (error) {
      console.log("Response in try catch!!");
      console.log(error);

      store.addNotification({
        title: "Unable to login to your account!",
        message:
          "We have not been able to login to your account. Please try again later",
        type: "danger",
        insert: "top",
        container: "bottom-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });

      // that.resetSignUp();
    }
  };

  handleFBError = (error) => {
    this.setState({ error });
  };

  addHistory = (data) => {
    // console.log("user : ", this.props.user)

    // var userData = JSON.parse(window.localStorage.user).data


    // axios
    //   .patch(
    //     process.env.REACT_APP_API_URL + "/v1/history/" + userData.user.id,
    //     data,
    //     {

    //       headers: {
    //         "Content-Type": "application/json",
    //         "Authorization": "Bearer " + userData.token.accessToken
    //       },


    //     },
    //   )
    //   .then(function (response) {
    //     console.log("Test Response in then!!");
    //     console.log(response);
    //   })
    //   .catch(function (error) {
    //     console.log("Response in then catch!!");
    //     console.log(error);
    //   });
  };

  toogleBookmark = (history) => {
    if (window.localStorage.history !== undefined) {
      let hl = JSON.parse(window.localStorage.history);
      let index = hl.findIndex((item) => {
        return item.identifier && item.identifier === history.identifier;
      });
      if (index >= 0) {
        hl[index] = {
          identifier: hl[index]["identifier"],
          equation: hl[index]["equation"],
          result: hl[index]["result"],
          date: hl[index]["date"],
          isBookmarked: !hl[index]["isBookmarked"],
        };
        this.props.history(hl);
      }
    }
  };

  removeHistory = (history) => {
    console.log("removehistory: ", history);
    if (window.localStorage.history !== undefined) {
      let hl = JSON.parse(window.localStorage.history);
      const filteredItems = hl.filter(
        (item) => item.identifier !== history.identifier
      );
      this.props.history(filteredItems);
    }
  };

  responseGoogle = (response) => {
    const that = this;

    if (response && response.accessToken) {
      that.handleSocialLogin(response.accessToken, "google");
    }
  };

  showEmailLogin = () => {
    this.setState({
      emailLogin: true,
      showPassword: false,
    });
  };

  showTermsAndConditions = () => {
    this.setState({
      termsAndCondition: true,
    });
  };

  showPrivacyPolicy = () => {
    this.setState({
      privacyPolicy: true,
    });
  };

  showHelp = () => {
    this.setState({
      getHelp: true,
    });
  };

  hideEmailLogin = () => {
    this.setState({
      emailLogin: false,
    });
  };

  resetLogin = () => {
    this.setState({
      login: {
        email: "",
        password: "",
      },
    });
  };

  showEmailSignup = () => {
    this.setState({
      emailSignup: true,
      showPassword: false,
    });
  };

  hideEmailSignup = () => {
    this.setState({
      emailSignup: false,
    });
  };

  resetSignUp = () => {
    this.setState({
      signup: {
        email: "",
        password: "",
      },
    });
  };

  signUpUser = (event) => {
    event.preventDefault();
    const that = this;

    var parts = this.state.signup.email.split('@');

    const signUpData = {
      email: this.state.signup.email,
      password: this.state.signup.password,
      name: parts[0],
      services: "",
      role: "user",
      picture: "",
    };

    console.log("signUpData", signUpData)
    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/v1/auth/register", signUpData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          console.log("Response in then!!");
          console.log(response);

          store.addNotification({
            title: "Account created successfully!",
            message:
              "Your account has been successfully created. Please login to be able to solve your math problems",
            type: "success",
            insert: "top",
            container: "bottom-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });

          that.resetSignUp();
          that.hideEmailSignup();

          console.log("============ response.data", response.data);
          console.log("*************************");
          console.log("============ response.data", response.data.user);

          let sessionObj = {
            username: response.data.user.name,
            emailId: response.data.user.email,
            loginType: "Email",
            isLoggedIn: true,
            profileImgUrl: response.data.user.picture,
            data: response.data
          };

          that.props.login(sessionObj);
        })
        .catch(function (error) {
          console.log("Response in then catch!!");
          console.log(error);

          if (
            (error.response && error.response.status === 409) ||
            (error.code && error.code === 409)
          ) {
            store.addNotification({
              title: "Unable to create account!",
              message:
                "There seems to be an account with same email. Please try again with different email ID",
              type: "danger",
              insert: "top",
              container: "bottom-left",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
              },
            });
          } else {
            store.addNotification({
              title: "Unable to create account!",
              message:
                "We have not been able to create your account. Please try again later",
              type: "danger",
              insert: "top",
              container: "bottom-left",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
              },
            });

          }

          that.resetSignUp();
        });
    } catch (error) {
      console.log("Response in try catch!!");
      console.log(error);

      store.addNotification({
        title: "Unable to create account!",
        message:
          "We have not been able to create your account. Please try again later",
        type: "danger",
        insert: "top",
        container: "bottom-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });

      that.resetSignUp();
    }
  };

  loginUser = (event) => {
    event.preventDefault();
    const that = this;

    const loginData = {
      email: this.state.login.email,
      password: this.state.login.password,
    };

    try {
      axios
        .post(process.env.REACT_APP_API_URL + "/v1/auth/login", loginData, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function (response) {
          console.log("Response in then!!");
          console.log(response);

          store.addNotification({
            title: "Logged In successfully!",
            message:
              "Your have been successfully logged into your account. Go ahead and get your math solved!",
            type: "success",
            insert: "top",
            container: "bottom-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });

          that.resetLogin();
          that.hideEmailLogin();

          console.log("============ response.data", response.data);

          let sessionObj = {
            username: response.data.user.name,
            emailId: response.data.user.email,
            loginType: "Email",
            isLoggedIn: true,
            profileImgUrl: response.data.user.picture,
            data: response.data
          };

          console.log("sessionObj", sessionObj)
          that.props.login(sessionObj);
        })
        .catch(function (error) {
          console.log("Response in then catch!!");
          console.log(error);

          if (
            (error.response && error.response.status === 401) ||
            (error.code && error.code === 401)
          ) {
            store.addNotification({
              title: "Username and Password don't match!",
              message: "Please verify your login credentials and try again",
              type: "danger",
              insert: "top",
              container: "bottom-left",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
              },
            });
          }

          that.resetLogin();
        });
    } catch (error) {
      console.log("Response in try catch!!");
      console.log(error);

      store.addNotification({
        title: "Something is wrong!",
        message:
          "There seems to be an issue with this login call. Please try again later",
        type: "danger",
        insert: "top",
        container: "bottom-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });

      that.resetLogin();
    }
  };

  handleSignupChangeEmail = (event) => {
    this.setState({
      signup: {
        email: event.target.value,
        password: this.state.signup.password,
      },
    });
  };

  handleChangePassword = (event) => {
    this.setState({
      signup: {
        email: this.state.signup.email,
        password: event.target.value,
      },
    });
  };

  handleLoginChangeEmail = (event) => {
    this.setState({
      login: {
        email: event.target.value,
        password: this.state.login.password,
      },
    });
  };

  handleForgotPasswordChangeEmail = (event) => {
    this.setState({
      forgotPasswordEmail: event.target.value,
    });
  };

  handleLoginChangePassword = (event) => {
    this.setState({
      login: {
        email: this.state.login.email,
        password: event.target.value,
      },
    });
  };

  handleResetPassword = (event) => {
    this.setState({
      resetPassword: event.target.value,
    });
  };

  handleResetAgainPassword = (event) => {
    this.setState({
      resetPasswordAgain: event.target.value,
    });
  };

  showForgotPassword = () => {
    this.hideEmailLogin();
    this.hideEmailSignup();
    this.setState({
      forgotPassword: true,
    });
  };

  hideForgotPassword = () => {
    this.setState({
      forgotPassword: false,
    });
  };

  resetForgotPassword = () => {
    this.setState({
      forgotPasswordEmail: "",
    });
  };

  resetResetPassword = () => {
    this.setState({
      resetPassword: "",
    });
  };

  hideResetPassword = () => {
    this.setState({
      resetPasswordLink: "",
    });
  };

  sendResetPasswordLink = (event) => {
    event.preventDefault();
    const that = this;

    const forgotPassworData = {
      email: this.state.forgotPasswordEmail,
    };

    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "/v1/auth/send-password-reset",
          forgotPassworData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          console.log("Response in then!!");
          console.log(response);

          store.addNotification({
            title: "Reset password link sent successfully!",
            message:
              "A reset password link has been sent to your email. Please follow the instructions in the email!",
            type: "success",
            insert: "top",
            container: "bottom-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });

          that.resetForgotPassword();
          that.hideForgotPassword();

          // let sessionObj = {
          //   username: response.data.user.name,
          //   emailId: response.data.user.email,
          //   loginType: "Email",
          //   isLoggedIn: true,
          // };

          // that.props.login(sessionObj);
        })
        .catch(function (error) {
          console.log("Response in then catch!!");
          console.log(error);

          store.addNotification({
            title: "Unable to send reset password link!",
            message:
              "We are unable to send the reset password link to your email ID. Please try again later",
            type: "danger",
            insert: "top",
            container: "bottom-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });

          that.resetForgotPassword();
        });
    } catch (error) {
      console.log("Response in try catch!!");
      console.log(error);

      store.addNotification({
        title: "Something is wrong!",
        message:
          "There seems to be an issue with this login call. Please try again later",
        type: "danger",
        insert: "top",
        container: "bottom-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });

      that.resetLogin();
    }
  };

  resetUserPassword = (event) => {
    event.preventDefault();
    const that = this;

    const resetPassworData = {
      email: this.query_params["email"],
      password: this.state.resetPassword,
      resetToken: this.query_params["resetToken"],
    };

    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "/v1/auth/reset-password",
          resetPassworData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          console.log("Response in then!!");
          console.log(response);

          store.addNotification({
            title: "Password reset successfully!",
            message:
              "Your password has been reset successfully! Please login to account with your new password",
            type: "success",
            insert: "top",
            container: "bottom-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });

          // that.resetResetPassword();
          // that.hideResetPassword();

          window.location.href = "/";

          // let sessionObj = {
          //   username: response.data.user.name,
          //   emailId: response.data.user.email,
          //   loginType: "Email",
          //   isLoggedIn: true,
          // };

          // that.props.login(sessionObj);
        })
        .catch(function (error) {
          console.log("Response in then catch!!");
          console.log(error);

          store.addNotification({
            title: "Unable to reset password!",
            message:
              "We are unable to send the reset password for your email ID. Please try again later",
            type: "danger",
            insert: "top",
            container: "bottom-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });

          that.resetResetPassword();
        });
    } catch (error) {
      console.log("Response in try catch!!");
      console.log(error);

      store.addNotification({
        title: "Something is wrong!",
        message:
          "There seems to be an issue with this reset password call. Please try again later",
        type: "danger",
        insert: "top",
        container: "bottom-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });

      that.resetLogin();
    }
  };

  verifyEmail = () => {
    // event.preventDefault();
    const that = this;

    const verifyEmailData = {
      email: this.query_params["email"],
      resetToken: this.query_params["emailVerificationToken"],
    };

    try {
      axios
        .post(
          process.env.REACT_APP_API_URL + "/v1/auth/verify-email",
          verifyEmailData,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          console.log("Response in then!!");
          console.log(response);

          if (response.data === "Email already verified") {
            store.addNotification({
              title: "Account is already verified!",
              message:
                "Your account is already verified! Please login to your account using your username and password",
              type: "success",
              insert: "top",
              container: "bottom-left",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
              },
            });
          } else {
            store.addNotification({
              title: "Account verified successfully!",
              message:
                "Your account is verified successfully! Please login to your account using your username and password",
              type: "success",
              insert: "top",
              container: "bottom-left",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
              },
            });
          }

          // that.resetResetPassword();
          // that.hideResetPassword();

          setTimeout(function () {
            window.location.href = "/";
          }, 3000);

          // let sessionObj = {
          //   username: response.data.user.name,
          //   emailId: response.data.user.email,
          //   loginType: "Email",
          //   isLoggedIn: true,
          // };

          // that.props.login(sessionObj);
        })
        .catch(function (error) {
          console.log("Response in then catch!!");
          console.log(error);

          store.addNotification({
            title: "Unable to verify your account!",
            message:
              "We are unable to verify your account. Please try again later",
            type: "danger",
            insert: "top",
            container: "bottom-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });

          // that.resetResetPassword();
        });
    } catch (error) {
      console.log("Response in try catch!!");
      console.log(error);

      store.addNotification({
        title: "Something is wrong!",
        message:
          "There seems to be an issue with your account verification. Please try again later",
        type: "danger",
        insert: "top",
        container: "bottom-left",
        animationIn: ["animate__animated", "animate__fadeIn"],
        animationOut: ["animate__animated", "animate__fadeOut"],
        dismiss: {
          duration: 5000,
        },
      });

      that.resetLogin();
    }
  };

  logOutUser = () => {
    this.props.logout();
    window.location.href = window.location.origin
  };

  isEmail = (input) => {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (
      this.state.signup.username !== "" &&
      this.state.signup.username.match(validRegex)
    ) {
      // alert("Valid email address!");
      // document.form1.text1.focus();
      return true;
    } else {
      // alert("Invalid email address!");
      // document.form1.text1.focus();
      return false;
    }
  };

  hideHamburgerMenu = () => {
    this.setState({
      hamburgerStatus: false,
    });
  };

  showHamburgerMenu = () => {
    this.setState({
      hamburgerStatus: true,
    });
  };

  scrollToTop = () => {
    document.getElementById("landing").scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  };

  scrollToSignUp = () => {
    document.getElementById("SignUpContent").scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  };

  scrollToAbout = () => {
    document.getElementById("aboutContent").scrollIntoView({
      behavior: "smooth",
      block: "center",
      inline: "nearest",
    });
  };

  scrollToOnboardingFeatures = () => {
    document.getElementById("onboardingFeatures").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  scrollToInfoCards = () => {
    document.getElementById("infocards").scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  render() {
    const { user } = this.props;

    return (
      <div className="App">
        <ReactNotification className="text-left" />
        {/* User Value is:
          <br />
          Button Login: <button onClick={login}> Login </button>
          <br />
          Button Logout: <button onClick={logout}> Logout </button> */}

        {this.state.termsAndCondition ? (
          <TermsAndCondition></TermsAndCondition>
        ) : this.state.privacyPolicy ? (
          <PrivacyPolicy></PrivacyPolicy>
        ) : this.state.about ? (
          <About></About>
        ) : this.state.getHelp ? (
          <GetHelp></GetHelp>
        ) : (
          <div className="App-Layout">
            {user.isLoggedIn && (
              <div className="container-fluid">
                <BrowserRouter>
                  <div className="row">
                    <div className="col-12">
                      {this.state.hamburgerStatus && (
                        <div className="vertical-menu-slide d-md-none">
                          <NavBar
                            type="slide"
                            hideHamburgerMenu={this.hideHamburgerMenu}
                            logOutUser={this.logOutUser}
                            sessionObj={this.state.sessionObj}
                          />
                        </div>
                      )}
                    </div>
                    <div className="vertical-menu-inline col-lg-2 col-md-3 d-md-block d-none d-sm-none">
                      <NavBar type="inline" logOutUser={this.logOutUser} />
                    </div>
                    <div className="vertical-body col-lg-10 col-md-9 col-sm-12">
                      <div className="row">
                        <div className="col-12">
                          <div className="vb-header d-md-none text-left">
                            <span
                              className="hamburger-menu"
                              onClick={this.showHamburgerMenu}
                            >
                              <span className="hambg-icn">
                                <svg
                                  viewBox="0 0 80 70"
                                  width="30"
                                  height="30"
                                  fill="#5d5c5c"
                                >
                                  <rect width="70" height="10"></rect>
                                  <rect y="20" width="70" height="10"></rect>
                                  <rect y="40" width="70" height="10"></rect>
                                </svg>
                              </span>
                            </span>
                            <span className="fm-main-logo">
                              <svg
                                width="32"
                                height="32"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M30.6666 25.3333C30.6666 26.0406 30.3856 26.7189 29.8855 27.219C29.3854 27.719 28.7072 28 27.9999 28H3.99992C3.29267 28 2.6144 27.719 2.1143 27.219C1.6142 26.7189 1.33325 26.0406 1.33325 25.3333V10.6667C1.33325 9.95942 1.6142 9.28115 2.1143 8.78105C2.6144 8.28095 3.29267 8 3.99992 8H9.33325L11.9999 4H19.9999L22.6666 8H27.9999C28.7072 8 29.3854 8.28095 29.8855 8.78105C30.3856 9.28115 30.6666 9.95942 30.6666 10.6667V25.3333Z"
                                  fill="white"
                                  stroke="#01497C"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M30.6667 25.3333C30.6667 26.0406 30.3858 26.7189 29.8857 27.219C29.3856 27.719 28.7073 28 28 28H15.8667C15.8667 27.3333 15.8667 25.4667 15.8667 25.0667C15.8667 24.4 15.8667 22.7072 15.8667 22V10.6667C15.8667 10.6667 15.8667 9.18105 15.8667 8.78105C15.8667 8.24772 15.8667 8 15.8667 7.33333V4H20.0001L22.6667 8H28C28.7073 8 29.3856 8.28095 29.8857 8.78105C30.3858 9.28115 30.6667 9.95942 30.6667 10.6667V25.3333Z"
                                  fill="#01497C"
                                />
                                <path
                                  d="M7.48682 22.6667H9.84651V19.2795H13.3333V16.9872H9.84651V13.6H7.48682V16.9872H4V19.2795H7.48682V22.6667Z"
                                  fill="#01497C"
                                />
                                <rect
                                  x="18.9333"
                                  y="16.8"
                                  width="8.8"
                                  height="2.4"
                                  fill="white"
                                />
                              </svg>
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="row" style={{ paddingTop: 80 }}>
                        <div className="col-12">
                          <Switch>
                            <Route
                              exact
                              path="/"
                              render={() => {
                                return (
                                  <Redirect to="/add-image" />
                                )
                              }}
                            />
                            {/* <Route path="/" component={Home}></Route> */}
                            <Route
                              path="/add-image"
                              component={AddImage}
                            ></Route>
                            <Route path="/calculator">
                              <CalculatorNew1 addHistory={this.addHistory} />
                            </Route>
                            <Route path="/history">
                              <History
                                bookmark={this.toogleBookmark}
                                remove={this.removeHistory}
                                user={user}
                              />
                            </Route>
                            {/* <Route path="/profile">
                                    <Profile />
                                  </Route> */}
                            <Route path="/get-help">
                              <GetHelp />
                            </Route>
                            <Route path="/terms-and-condition">
                              <TermsAndCondition />
                            </Route>
                            <Route path="/privacy-policy">
                              <PrivacyPolicy />
                            </Route>
                            <Route path="/supported-problem-types">
                              <SupportedProblemTypes />
                            </Route>
                            <Route
                              path="/solution/:equation"
                              component={Solution}
                            // component={props => (<Solution {...this.props} user={user}></Solution>)}
                            >
                              {/* <Solution user={user} /> */}
                            </Route>
                          </Switch>
                        </div>
                      </div>
                    </div>
                  </div>
                </BrowserRouter>
              </div>
            )}

            {!user.isLoggedIn && (
              <BrowserRouter>
                <ScrollToTop>
                  <Switch>
                    <Route path="/get-help">
                      <GetHelp />
                    </Route>
                    <Route path="/terms-and-condition">
                      <TermsAndCondition />
                    </Route>
                    <Route path="/privacy-policy">
                      <PrivacyPolicy />
                    </Route>
                    <Route path="/supported-problem-types">
                      <SupportedProblemTypes />
                    </Route>
                    <Route path="/">
                      <div>
                        {!this.state.emailSignup &&
                          !this.state.emailLogin &&
                          !this.state.forgotPassword &&
                          !this.state.resetPasswordLink &&
                          !this.state.verifyEmailLink && (
                            <div className="App-header">
                              <nav id="navbar">
                                <div
                                  className="text-center mt-4 mb-4 Logo-text"
                                  style={{ display: "flex", marginRight: "auto" }}
                                >
                                  <div
                                    onClick={() => {
                                      this.scrollToTop();
                                    }}
                                    style={{
                                      cursor: "pointer",
                                      textAlign: "center",
                                      display: "flex",
                                      alignItems: "center",
                                      paddingLeft: 24,
                                      fontSize: 18,
                                      marginRight: "auto",
                                    }}
                                  >
                                    <svg
                                      width="32"
                                      height="32"
                                      viewBox="0 0 64 64"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        d="M61.3334 50.6667C61.3334 52.0812 60.7715 53.4377 59.7713 54.4379C58.7711 55.4381 57.4146 56 56.0001 56H8.00008C6.58559 56 5.22904 55.4381 4.22885 54.4379C3.22865 53.4377 2.66675 52.0812 2.66675 50.6667V21.3333C2.66675 19.9188 3.22865 18.5623 4.22885 17.5621C5.22904 16.5619 6.58559 16 8.00008 16H18.6667L24.0001 8H40.0001L45.3334 16H56.0001C57.4146 16 58.7711 16.5619 59.7713 17.5621C60.7715 18.5623 61.3334 19.9188 61.3334 21.3333V50.6667Z"
                                        fill="white"
                                      />
                                      <path
                                        d="M32.2334 55.5V50.1333V44V21.3333H31.7334L32.2334 21.3333V21.3332V21.3328V21.3312V21.3248L32.2334 21.2998L32.2334 21.2041L32.2334 20.8566L32.2334 19.7477L32.2334 17.5621V14.6667V8.5H39.7325L44.9174 16.2773C45.0101 16.4164 45.1663 16.5 45.3334 16.5H56.0001C57.282 16.5 58.5114 17.0092 59.4178 17.9157C60.3242 18.8221 60.8334 20.0515 60.8334 21.3333V50.6667C60.8334 51.9485 60.3242 53.1779 59.4178 54.0844C58.5114 54.9908 57.282 55.5 56.0001 55.5H32.2334Z"
                                        fill="#01497C"
                                        stroke="white"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      />
                                      <path
                                        d="M14.9736 45.3335H19.693V38.5591H26.6667V33.9746H19.693V27.2002H14.9736V33.9746H8V38.5591H14.9736V45.3335Z"
                                        fill="#01497C"
                                      />
                                      <rect
                                        x="37.8667"
                                        y="33.6001"
                                        width="17.6"
                                        height="4.8"
                                        fill="white"
                                      />
                                    </svg>
                                    <div style={{ paddingLeft: 10 }}>FEVMath</div>
                                  </div>
                                </div>
                                <div
                                  className="nav-Btn"
                                  onClick={() => {
                                    this.scrollToAbout();
                                  }}
                                >
                                  {"  "}About{"  "}
                                </div>
                                <div
                                  className="nav-Btn"
                                  onClick={() => {
                                    this.scrollToSignUp();
                                  }}
                                >
                                  {"  "}Sign Up{"  "}
                                </div>
                              </nav>
                            </div>
                          )}
                        <div className="App-body landing-body">
                          <div id="landing">
                            <div className="landing-display">
                              <div className="landingImgDiv">
                                <img
                                  src={landing}
                                  alt="landing"
                                  className="landingImage"
                                ></img>
                              </div>
                              <div className="landing-content">
                                {" "}
                                <p className="landing-para">
                                  Want to learn how to{" "}
                                  <span>
                                    <Typewriter
                                      words={[
                                        "perform addition or subtraction?",
                                        "compute the product?",
                                        "do a long division?",
                                        "understand placevalues in a number?",
                                        "perform arithmetic calculations?",
                                      ]}
                                      loop={0}
                                      cursor
                                      cursorStyle="_"
                                      typeSpeed={100}
                                      deleteSpeed={70}
                                      delaySpeed={800}
                                    />
                                  </span>
                                </p>
                                <div className="landing-intro">
                                  Just <span>FEVMath</span> it.
                                </div>
                                <p className="landing-des">
                                  We will help you learn with ease!!
                                </p>
                                <AppStore></AppStore>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="App-body landingAbout">
                          <div id="aboutContent">
                            <div className="contentAbout">
                              <div className="about-intro">A little more about us</div>
                              <div className="about-text">
                                {
                                  "FEVMath is an AI-based on-demand math assistance app available on Android, iOS devices as well as a web application. It is developed to solve K-5 math problems instantly. Artificial intelligence technology predicts the equation and solves it in real-time, just like a math tutor would do on the chalkboard. Learners have to simply scan a math problem with their mobile camera or upload an image on the web application and they get a detailed step-by-step explanation to the math expressions."
                                }
                                <br></br>
                                {
                                  "The app can recognize both handwritten and printed text and has an intuitive math keyboard feature. AI specializes in offering virtual calculation steps for any equation or expression, currently for K-5 math expressions and in subsequent versions up to high school. In addition to this, the app provides visual representations to help drive complete conceptual understanding to the students."
                                }
                                <br></br>
                                {
                                  "The main focus of the app is to help students not only get the solution to a math expression but also understand the step by step explanations aided by animations for a better understanding."
                                }
                              </div>
                            </div>
                            <div className="aboutImgDiv">
                              <img
                                src={aboutImg}
                                alt="about"
                                className="aboutImage"
                              ></img>
                            </div>
                          </div>
                        </div>

                        {/* <header className="App-header">
                <span> Hello, {user.isLoggedIn && user.username} </span>
                <button className="btn btn-success ml-2" onClick={login}>
            {" "}
            Login{" "}
          </button>
                {!user.isLoggedIn &&
                  "Please Signup / Login to solve your math problem!"}
                {user.isLoggedIn && (
                  <button className="btn btn-success ml-2" onClick={logout}>
                    {" "}
                    Logout{" "}
                  </button>
                )}
              </header> */}
                        <div className="App-body signup-body">
                          {/* <FacebookProvider appId="346455980014470">
                          <Like
                            href="https://fm-dev.transnoesis.com/"
                            colorScheme="dark"
                            showFaces
                            share
                          />
                        </FacebookProvider> */}
                          <div id="SignUpContent">
                            <div className="contentSignUp">
                              <div className="signUpImgDiv">
                                <img
                                  src={signup}
                                  alt="signup"
                                  className="signupImg"
                                ></img>
                              </div>
                              <div className="signupForm">
                                {!this.state.emailSignup &&
                                  !this.state.emailLogin &&
                                  !this.state.forgotPassword &&
                                  !this.state.resetPasswordLink &&
                                  !this.state.verifyEmailLink && (
                                    <div>
                                      {" "}
                                      <GoogleLogin
                                        clientId={
                                          process.env.REACT_APP_GOOGLE_OAUTH_CRED
                                        }
                                        render={(renderProps) => (
                                          <div
                                            className="Login-Btn mt-3"
                                            onClick={renderProps.onClick}
                                            disabled={renderProps.disabled}
                                          >
                                            <span className="Login-Icon">
                                              <img
                                                className="Google-Icn"
                                                src={process.env.PUBLIC_URL + "/google-icn.png"}
                                                alt="Google-Icn"
                                              />
                                            </span>
                                            <span>Sign up with Google</span>
                                          </div>
                                        )}
                                        buttonText="Login"
                                        onSuccess={this.responseGoogle}
                                        onFailure={this.responseGoogle}
                                        cookiePolicy={"single_host_origin"}
                                      />
                                      <div
                                        className="Login-Btn mt-3"
                                        onClick={this.fbNewLoginBtn}
                                      >
                                        <span className="Login-Icon">
                                          <svg
                                            width="20"
                                            height="21"
                                            viewBox="0 0 20 21"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M20 10.5C20 4.98 15.52 0.5 10 0.5C4.48 0.5 0 4.98 0 10.5C0 15.34 3.44 19.37 8 20.3V13.5H6V10.5H8V8C8 6.07 9.57 4.5 11.5 4.5H14V7.5H12C11.45 7.5 11 7.95 11 8.5V10.5H14V13.5H11V20.45C16.05 19.95 20 15.69 20 10.5Z"
                                              fill="#fff"
                                            />
                                          </svg>
                                        </span>
                                        <span>Sign up with Facebook</span>
                                      </div>
                                      {/* <FacebookProvider appId="346455980014470">
                        <Login
                          scope="email"
                          onResponse={this.handleFBResponse}
                          onError={this.handleFBError}
                        >
                          <div className="Login-Btn mt-3">
                            <span className="Login-Icon">
                              <svg
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M20 10.5C20 4.98 15.52 0.5 10 0.5C4.48 0.5 0 4.98 0 10.5C0 15.34 3.44 19.37 8 20.3V13.5H6V10.5H8V8C8 6.07 9.57 4.5 11.5 4.5H14V7.5H12C11.45 7.5 11 7.95 11 8.5V10.5H14V13.5H11V20.45C16.05 19.95 20 15.69 20 10.5Z"
                                  fill="#01497C"
                                />
                              </svg>
                            </span>
                            <span>Sign up with Facebook</span>
                          </div>
                        </Login>
                      </FacebookProvider> */}
                                      {/* Secret: jiWwAXaE8c8grLqDPqgdnp31 */}
                                      <div
                                        className="Login-Btn mt-3"
                                        onClick={this.showEmailSignup}
                                      >
                                        <span className="Login-Icon">
                                          <svg
                                            width="20"
                                            height="17"
                                            viewBox="0 0 20 17"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <path
                                              d="M20 2.5C20 1.4 19.1 0.5 18 0.5H2C0.9 0.5 0 1.4 0 2.5V14.5C0 15.6 0.9 16.5 2 16.5H18C19.1 16.5 20 15.6 20 14.5V2.5ZM18 2.5L10 7.5L2 2.5H18ZM18 14.5H2V4.5L10 9.5L18 4.5V14.5Z"
                                              fill="#fff"
                                            />
                                          </svg>
                                        </span>
                                        <span>Sign up with Email</span>
                                      </div>{" "}
                                      <div className="Login-cta">
                                        <span className="text-blue">
                                          Have an account?{" "}
                                        </span>
                                        <span
                                          className="font-weight-bold Signin-btn text-blue"
                                          onClick={() => this.showEmailLogin()}
                                        >
                                          {" "}
                                          Sign In{" "}
                                        </span>
                                      </div>{" "}
                                      <div className="Login-cta text-blue">
                                        {" "}
                                        <Link to="/terms-and-condition" style={{ color: '#01497c', textDecoration: 'underline' }}> Terms & Conditions</Link>

                                        {/* <span
                                        className="TermsAndCondition-btn text-blue"
                                        onClick={() => this.showTermsAndConditions()}
                                      >
                                        {" "}
                                        Terms and Conditions
                                      </span> */}
                                        {" | "}
                                        {/* <span
                                        className="pricacyPolicy-btn text-blue"
                                        onClick={() => this.showPrivacyPolicy()}
                                      >
                                        {" "}
                                        Privacy Policy
                                      </span> */}
                                        <Link to="/privacy-policy" style={{ color: '#01497c', textDecoration: 'underline' }}> Privacy Policy</Link>

                                        {" | "}
                                        {/* <span
                                        className="getHelp-btn text-blue"
                                        onClick={() => this.showHelp()}
                                      >
                                        {" "}
                                        Help
                                      </span> */}
                                        {/* <li> */}
                                        <Link to="/get-help" style={{ color: '#01497c', textDecoration: 'underline' }}>Help</Link>
                                        <Link to="/supported-problem-types" style={{ color: '#01497c', textDecoration: 'underline' }}>Supported Problem Types</Link>
                                        {/* </li> */}
                                      </div>{" "}
                                    </div>
                                  )}
                                {this.state.emailSignup && (
                                  <div>
                                    <div className="mb-5 sb-heading-login">
                                      {" "}
                                      Sign up with email!{" "}
                                    </div>
                                    <form>
                                      <div className="form-group">
                                        <input
                                          type="email"
                                          className="form-control Input-theme"
                                          id="exampleInputEmail1"
                                          aria-describedby="emailHelp"
                                          placeholder="Enter email"
                                          value={this.state.signup.email}
                                          onChange={this.handleSignupChangeEmail}
                                          required
                                        />
                                        {/* <small id="emailHelp" className="form-text text-muted">
                        We'll never share your email with anyone else.
                      </small> */}
                                      </div>
                                      <div className="form-group position-relative">
                                        <input
                                          // type="password"
                                          type={
                                            this.state.showPassword
                                              ? "text"
                                              : "password"
                                          }
                                          className="form-control Input-theme pr-5"
                                          id="exampleInputPassword1"
                                          placeholder="Password"
                                          value={this.state.signup.password}
                                          onChange={this.handleChangePassword}
                                          required
                                        />
                                        <span
                                          className="position-absolute cursor-pointer primary-blue-font toggle-show-password"
                                          onClick={() => this.toggleShowPassword()}
                                        >
                                          {this.state.showPassword ? (
                                            <FontAwesomeIcon icon="eye-slash" />
                                          ) : (
                                            <FontAwesomeIcon icon="eye" />
                                          )}
                                        </span>
                                      </div>
                                      {/* <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                      />
                      <label className="form-check-label" for="exampleCheck1">
                        Check me out
                      </label>
                    </div> */}
                                      <div className="mt-5">
                                        <button
                                          type="submit"
                                          disabled={
                                            this.state.signup.username === "" ||
                                            this.state.signup.password === "" ||
                                            this.state.signup.password.length < 8
                                          }
                                          className="btn rounded-pill submit-btn-fm"
                                          onClick={(event) => this.signUpUser(event)}
                                        >
                                          Submit
                                        </button>
                                        <button
                                          className="btn ml-3 rounded-pill cancel-btn-fm"
                                          onClick={() => this.hideEmailSignup()}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                )}
                                {this.state.emailLogin && (
                                  <div>
                                    <div className="mb-5 sb-heading-login">
                                      {" "}
                                      Sign In with email!{" "}
                                    </div>
                                    <form>
                                      <div className="form-group">
                                        <input
                                          type="email"
                                          className="form-control Input-theme"
                                          id="exampleInputEmail3"
                                          aria-describedby="emailHelp"
                                          placeholder="Enter email"
                                          value={this.state.login.email}
                                          onChange={this.handleLoginChangeEmail}
                                          required
                                        />
                                        {/* <small id="emailHelp" className="form-text text-muted">
                        We'll never share your email with anyone else.
                      </small> */}
                                      </div>
                                      <div className="form-group position-relative">
                                        <input
                                          type={
                                            this.state.showPassword
                                              ? "text"
                                              : "password"
                                          }
                                          className="form-control Input-theme pr-5"
                                          id="exampleInputPassword4"
                                          placeholder="Password"
                                          value={this.state.login.password}
                                          onChange={this.handleLoginChangePassword}
                                          required
                                        />
                                        <span
                                          className="position-absolute cursor-pointer primary-blue-font toggle-show-password"
                                          onClick={() => this.toggleShowPassword()}
                                        >
                                          {this.state.showPassword ? (
                                            <FontAwesomeIcon icon="eye-slash" />
                                          ) : (
                                            <FontAwesomeIcon icon="eye" />
                                          )}
                                        </span>
                                      </div>
                                      <div
                                        className="forgot-password"
                                        onClick={this.showForgotPassword}
                                      >
                                        <span className="text-blue">
                                          Forgot password?
                                        </span>
                                      </div>
                                      {/* <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                      />
                      <label className="form-check-label" for="exampleCheck1">
                        Check me out
                      </label>
                    </div> */}
                                      <div className="mt-5">
                                        <button
                                          type="submit"
                                          disabled={
                                            this.state.login.email === "" ||
                                            this.state.login.password === "" ||
                                            this.state.login.password.length < 8
                                          }
                                          className="btn rounded-pill submit-btn-fm"
                                          onClick={(event) => this.loginUser(event)}
                                        >
                                          Submit
                                        </button>
                                        <button
                                          className="btn rounded-pill ml-3 cancel-btn-fm"
                                          onClick={() => this.hideEmailLogin()}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </form>
                                    <div className="Login-cta">
                                      <span className="text-blue">
                                        New to FEVMath?{" "}
                                      </span>
                                      <span
                                        className="font-weight-bold Signup-btn text-blue"
                                        onClick={() => this.hideEmailLogin()}
                                      >
                                        {" "}
                                        Sign Up{" "}
                                      </span>
                                    </div>{" "}
                                  </div>
                                )}
                                {this.state.forgotPassword && (
                                  <div>
                                    <div className="mb-5 sb-heading-login">
                                      {" "}
                                      Reset password link!{" "}
                                    </div>
                                    <form>
                                      <div className="form-group">
                                        <input
                                          type="email"
                                          className="form-control Input-theme"
                                          id="exampleInputEmail4"
                                          aria-describedby="emailHelp"
                                          placeholder="Enter email"
                                          value={this.state.forgotPasswordEmail}
                                          onChange={
                                            this.handleForgotPasswordChangeEmail
                                          }
                                          required
                                        />
                                        {/* <small id="emailHelp" className="form-text text-muted">
                        We'll never share your email with anyone else.
                      </small> */}
                                      </div>
                                      {/* <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                      />
                      <label className="form-check-label" for="exampleCheck1">
                        Check me out
                      </label>
                    </div> */}
                                      <div className="mt-5">
                                        <button
                                          type="submit"
                                          disabled={
                                            this.state.forgotPasswordEmail === ""
                                          }
                                          className="btn rounded-pill submit-btn-fm"
                                          onClick={(event) =>
                                            this.sendResetPasswordLink(event)
                                          }
                                        >
                                          Submit
                                        </button>
                                        <button
                                          className="btn rounded-pill cancel-btn-fm ml-3"
                                          onClick={() => this.hideForgotPassword()}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                )}
                                {this.state.resetPasswordLink && (
                                  <div>
                                    <div className="mb-5 sb-heading-login">
                                      {" "}
                                      Please enter new password to reset your password!{" "}
                                    </div>
                                    <form>
                                      <div className="form-group">
                                        <input
                                          type="password"
                                          className="form-control Input-theme mx-auto"
                                          id="exampleInputPassword5"
                                          placeholder="Password"
                                          value={this.state.resetPassword}
                                          onChange={this.handleResetPassword}
                                          required
                                        />
                                      </div>
                                      <div className="form-group">
                                        <input
                                          type="password"
                                          className="form-control Input-theme mx-auto"
                                          id="exampleInputPassword6"
                                          placeholder="Re-enter Password"
                                          value={this.state.resetPasswordAgain}
                                          onChange={this.handleResetAgainPassword}
                                          required
                                        />
                                      </div>
                                      {/* <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="exampleCheck1"
                      />
                      <label className="form-check-label" for="exampleCheck1">
                        Check me out
                      </label>
                    </div> */}
                                      <div className="mt-5">
                                        <button
                                          type="submit"
                                          disabled={
                                            this.state.resetPassword === "" ||
                                            this.state.resetPassword.length < 8 ||
                                            this.state.resetPasswordAgain === "" ||
                                            this.state.resetPasswordAgain.length < 8
                                          }
                                          className="btn btn-success"
                                          onClick={(event) =>
                                            this.resetUserPassword(event)
                                          }
                                        >
                                          Submit
                                        </button>
                                        <button
                                          className="btn btn-secondary ml-3"
                                          onClick={() => this.hideResetPassword()}
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </form>
                                  </div>
                                )}

                                {this.state.verifyEmailLink && (
                                  <div class="text-white">
                                    <div className="mb-5"> Account verification! </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div id="onboardingFeatures">
                          <OnboardingFeatures></OnboardingFeatures>
                        </div>
                        <div id="infocards">
                          <InfoCards></InfoCards>
                        </div>
                        <Footer
                          home={this.scrollToTop}
                          howItWords={this.scrollToOnboardingFeatures}
                          whyUs={this.scrollToInfoCards}
                          terms={this.showTermsAndConditions}
                          privacy={this.showPrivacyPolicy}
                          help={this.showHelp}
                        />
                      </div>
                    </Route>
                  </Switch>
                </ScrollToTop>
              </BrowserRouter>)}
          </div>
        )}
      </div>
    );
    // } else {
    //   return (
    //     <div className="App">
    //       <header className="App-header"></header>
    //     </div>
    //   );
    // }
  }
}

const mapStateToProps = (state) => {
  return {
    user: state,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    login: (sessionObj) => dispatch(login(sessionObj)),
    logout: () => dispatch(logout()),
    history: (historyItems) => dispatch(history(historyItems)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);

// export default ;
