import React from "react";
import EquationCard from "./cards/EquationCard";
import FinalSolutionCard from "./cards/FinalSolutionCard";
import SolutionSteps from "./SolutionSteps";
import WolframSolutionSteps from "./WolframSolutionSteps";
import SolutionCard from "./SolutionCard"
import { store } from "react-notifications-component";

import axios from "axios";
import asciimath2latex from "asciimath-to-latex";
import qs from "qs";

import { Component } from "react";
import $ from "jquery";
import { withRouter } from "react-router-dom";

class Solution extends Component {
  constructor(props) {
    super(props);
    this.showSolutionSteps = this.showSolutionSteps.bind(this);
    // this.updateAsciiMathEquation = this.updateAsciiMathEquation.bind(this);
  }

  state = {
    solutionStepsUI: false,
    equationFiltered: "",
    equation: {
      asciimath: "",
      latex: "",
    },
    solution: {
      final: "",
      steps: [],
      type: "",
      isSimplified: false,
    },
    data: {},
    redirect: null,
    solutionType: "native", // wolfram or native
    mathML: {},
    loading: true,
    title: "Solutions"
  };

  async componentDidMount() {
    let queryParams = qs.parse(this.props.location.search.split("?")[1]);
    // const { equation } = useParams();

    const equationFiltered = decodeURIComponent(
      this.props.location.pathname.replace("/solution/", "")
    ).replace(" ", "");

    this.setState({
      equationFiltered: equationFiltered,
    });

    if (queryParams["type"] === "wolfram") {
      this.setState({
        solutionType: "wolfram",
      });
      this.getWolframSolution(equationFiltered);
    } else if (queryParams["type"] === "native") {
      this.setState({
        solutionType: "native",
      });
      this.solveAsciiEquation(equationFiltered);
    } else if (queryParams["type"] === "new-native-text") {
      this.setState({
        solutionType: "new-native",
      });
      this.solveTextEquation(equationFiltered, "new-native");
    } else {
      this.setState({
        solutionType: "new-native",
      });
      this.solveAsciiEquation(equationFiltered, "new-native");
    }
  }

  showSolutionSteps() {
    this.setState({
      solutionStepsUI: true,
    });
  }

  updateBookmark = (historyId) => {
    var url = window.location.href
    var r = new URL(url)
    r.searchParams.set("history-id", historyId)
    const newUrl = r.href
    window.history.replaceState({ path: newUrl }, '', newUrl);
  }

  getWolframSolution = (equation) => {
    axios
      .get(process.env.REACT_APP_API_URL + "/v1/mathsolver/wolfram", {
        params: {
          problem: equation,
        },
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then(
        (response) => {
          let mathMlWolfram = "";
          try {
            mathMlWolfram = response.data.solution.queryresult.pods[1]
              .subpods[1]
              ? response.data.solution.queryresult.pods[1].subpods[1].mathml
              : response.data.solution.queryresult.pods[1].subpods[0].mathml;
          } catch (error) {
          }

          const respMathML = mathMlWolfram
            .replace(/\n/g, "")
            .replace(/mtext/g, "mi");

          this.setState({
            mathML: $(respMathML).children("mtable").children("mtr"),
          });
        },
        (error) => {
        }
      );
  };

  updateAsciiMathEquation = (equation) => {
    window.location.href =
      window.location.origin +
      "/solution/" +
      equation +
      "?type=" +
      this.state.solutionType;
    const latexEquation = asciimath2latex(equation);
    this.setState({
      equation: {
        ascii: equation,
        latex: latexEquation,
      },
    });

    if (this.state.solutionType === "native") {
      this.solveAsciiEquation(equation);
    } else if (this.state.solutionType === "new-native") {
      this.solveAsciiEquation(equation, "new-native");
    } else if (this.state.solutionType === "wolfram") {
      this.getWolframSolution(equation);
    }
  };

  solveAsciiEquation = (equation, api_type) => {
    let apiUrl = "";
    if (api_type && api_type !== "" && api_type === "new-native") {
      apiUrl = process.env.REACT_APP_API_URL + "/v1/mathsolver/" + api_type +
        "?problem=" + encodeURIComponent(equation) + "&solver=v3-stage";
    } else {
      apiUrl =
        process.env.REACT_APP_API_URL +
        "/v1/mathsolver/steps?problem=" +
        encodeURIComponent(equation);
    }
    // const mathsteps = require("mathsteps");

    equation = equation.replace(/\*\*/gi, "*");
    equation = equation.replace(/xx/gi, "*");
    equation = equation.replace(/\/\//gi, "/");
    equation = equation.replace(/-:/gi, "/");

    const type = 1;
    if (type === 1) {
      axios
        .get(apiUrl, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(
          (response) => {
            if (response && response.data && response.data.solution && response.data.solution.expr_type === 'unsupported') {
              store.addNotification({
                title: "Unable to Solve!",
                message: response.data.solution.not_supported_msg,
                type: "danger",
                insert: "top",
                container: "bottom-left",
                animationIn: ["animate__animated", "animate__fadeIn"],
                animationOut: ["animate__animated", "animate__fadeOut"],
                dismiss: {
                  duration: 5000,
                },
              });

              this.props.history.goBack()

            } else {
              let finalSolution;

              if (
                response.data.solution &&
                response.data.solution === "object"
              ) {
                finalSolution =
                  response.data.solution.solution.final ||
                  response.data.solution.solution.finalSolution;
              }
              else {
                finalSolution = response.data?.solution?.solution;
              }

              let resText = "", proText = "";
              if (this.state.solutionType === "new-native") {
                finalSolution = (response.data.solution.problemTex) + ' = ' + response.data.solution.resultTex;
                resText = (response.data.solution.resultTex)
                proText = (response.data.solution.problemTex)
              }

              var data = {
                "time": Date.now(),
                "equation": equation,
                "solution": resText,
                "action": response.data?.solution?.action,
                "isBookmarked": false,
                "type": "latex",
                "equationText": ""
              };

              this.addHistory(data)

              this.setState({
                equation: {
                  asciimath: this.state.solutionType === "new-native" ? proText : response.data?.solution?.problem?.problem,
                  latex: this.state.solutionType === "new-native" ? proText : this.state.equation.latex,
                },
                solution: {
                  final: finalSolution,
                  problemTex: equation,
                  resultTex: resText,
                  steps: this.state.solutionType === "new-native" ? response.data.solution.steps : response.data?.solution?.solution?.steps,
                  isSimplified: false,
                  type: response.data?.solution?.type,
                  action: response.data?.solution?.action
                },
                loading: false
              });
            }
          },
          (error) => {
            store.addNotification({
              title: "Unable to Solve!",
              message:
                "Your expression isn't supported yet but we're learning fast! Once we support it, we will let you know.",
              type: "danger",
              insert: "top",
              container: "bottom-left",
              animationIn: ["animate__animated", "animate__fadeIn"],
              animationOut: ["animate__animated", "animate__fadeOut"],
              dismiss: {
                duration: 5000,
              },
            });
            this.props.history.goBack()
          }
        );
    } else {
      const isEquation = this.isEquation(equation);
      // const steps = isEquation
      //   ? mathsteps.solveEquation(equation)
      //   : mathsteps.simplifyExpression(equation);

      this.setState({
        ...this.state,
        isSimplified: !isEquation,
      });
    }
  };

  solveTextEquation = (equation, api_type) => {
    let apiUrl = process.env.REACT_APP_API_URL + "/v1/mathsolver/mmd"

    let data = {
      "text": equation, //"\\begin{tabular}{r}\n24\\\\\n\\( +13 \\) \\\\\n\\hline\n\\end{tabular}",
      "solver": "v3-stage"
    }
    // console.log("solveTextEquation")
    // console.log("data: ", data)

    var headers = {
      'Content-Type': 'application/json; charset=utf-8',
    }
    // headers['X-GUEST-TOKEN'] = 'Bearer .eyJleHAiOjExNTI1MzU1ODQ5MSwiaWF0IjoxNjQ4NTMxMjkxLCJzdWIiOnsiZmlyc3RVc2VkIjoxNjQ4NTMxMjkxMTg0LCJ1c2FnZUNhbGN1bGF0b3JQZXJEYXkiOnsiMSI6MCwiMiI6MCwiMyI6MCwiNCI6MCwiNSI6MCwiNiI6MCwiNyI6MH0sInVzYWdlSW1hZ2VTY2FuUGVyRGF5Ijp7IjEiOjAsIjIiOjAsIjMiOjAsIjQiOjAsIjUiOjAsIjYiOjAsIjciOjB9LCJ1c2FnZVBlckRheSI6eyIxIjoxLCIyIjowLCIzIjowLCI0IjowLCI1IjowLCI2IjowLCI3IjowfX19._EEkZD3k6xgtiWXk7YddVcD3flouPgfc6Blr2UzNNXM';
    // headers['X-GUEST-TOKEN'] = 'Bearer ' + this.props.user.user.data.token.accessToken;

    axios.post(
      apiUrl,
      data,
      headers
    ).then(
      (response) => {
        if (response && response.data && response.data.solution && response.data.solution.expr_type === 'unsupported') {
          store.addNotification({
            title: "Unable to Solve!",
            message: response.data.solution.not_supported_msg,
            type: "danger",
            insert: "top",
            container: "bottom-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
            },
          });

          this.props.history.goBack()

        } else {
          let finalSolution;

          if (
            response.data.solution &&
            response.data.solution === "object"
          ) {
            finalSolution =
              response.data.solution.solution.final ||
              response.data.solution.solution.finalSolution;
          }
          else {
            finalSolution = response.data?.solution?.solution;
          }

          let resText = "", proText = "";
          if (this.state.solutionType === "new-native") {
            finalSolution = (response.data.solution.problemTex) + ' = ' + response.data.solution.resultTex;
            resText = (response.data.solution.resultTex)
            proText = (response.data.solution.problemTex)
          }

          var data = {
            "time": Date.now(),
            "equation": proText,
            "solution": resText,
            "action": response.data?.solution?.action,
            "isBookmarked": false,
            "type": "text",
            "equationText": equation
          };

          this.addHistory(data)

          this.setState({
            equation: {
              asciimath: this.state.solutionType === "new-native" ? proText : response.data?.solution?.problem?.problem,
              latex: this.state.solutionType === "new-native" ? proText : this.state.equation.latex,
            },
            solution: {
              final: finalSolution,
              problemTex: equation,
              resultTex: resText,
              steps: this.state.solutionType === "new-native" ? response.data.solution.steps : response.data?.solution?.solution?.steps,
              isSimplified: false,
              type: response.data?.solution?.type,
              action: response.data?.solution?.action
            },
            loading: false
          });
        }
      },
      (error) => {
        store.addNotification({
          title: "Unable to Solve!",
          message:
            "Your expression isn't supported yet but we're learning fast! Once we support it, we will let you know.",
          type: "danger",
          insert: "top",
          container: "bottom-left",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000,
          },
        });

        this.props.history.goBack()
      }
    );
  };

  addHistory = (data) => {
    // var url = window.location.href
    // var r = new URL(url)
    let queryParams = qs.parse(this.props.location.search.split("?")[1]);

    if (!queryParams["history-id"]) {
      var userData = JSON.parse(window.localStorage.user).data
      var that = this;
      axios
        .patch(
          process.env.REACT_APP_API_URL + "/v1/history/" + userData.user.id,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": "Bearer " + userData.token.accessToken
            },
          },
        )
        .then(function (response) {
          if (response.data !== null && response.data.items !== null && response.data.items.length > 0) {
            that.updateHistoryRef(response.data.items[0].histRef)
          }
        })
        .catch(function (error) {
        });
    }
  };

  updateHistoryRef = (id) => {
    var url = window.location.href
    var r = new URL(url)
    r.searchParams.set("history-id", id)
    const newUrl = r.href
    window.history.replaceState({ path: newUrl }, '', newUrl);
  }

  gotoAddImage() {
    this.props.history.push("/add-image");
  }

  gotoSolutionSteps(type) {
    window.location.href =
      window.location.origin + window.location.pathname + "?type=" + type;
  }

  render() {
    const latexEquation = asciimath2latex(this.state.equationFiltered);
    return (
      <div  >

        <div className="tittle" style={{ fontSize: '20px', marginBottom: "30px", width: "350px" }}>
          {/* {
            this.state.isBookmarked ?
              <img className="bkm" src={process.env.PUBLIC_URL + "/bookmark-icn-select.png"} alt=""></img>
              :
              <img className="bkm" src={process.env.PUBLIC_URL + "/bookmark-icn-select-outline.png"} alt=""></img>
          } */}
        </div>
        {/* <div className="col-12"> */}
        {this.state.solutionType === "native" && (
          <div className="row">
            <div className="col-12">
              <EquationCard
                asciimathEquation={this.state.equationFiltered}
                latexEquation={latexEquation}
                updateAsciiMathEquation={this.updateAsciiMathEquation}
              ></EquationCard>

              {this.state.solutionStepsUI && (
                <SolutionSteps
                  solutionSteps={this.state.solution.steps[0]}
                ></SolutionSteps>
              )}

              <FinalSolutionCard
                finalSolution={this.state.solution.final}
                solutionType={this.state.solution.type}
                showSolutionSteps={this.showSolutionSteps}
              ></FinalSolutionCard>
            </div>
          </div>
        )}
        {this.state.solutionType === "wolfram" && (
          <div className="row">
            <div className="col-12">
              <EquationCard
                asciimathEquation={this.state.equationFiltered}
                latexEquation={latexEquation}
                updateAsciiMathEquation={this.updateAsciiMathEquation}
              ></EquationCard>

              <WolframSolutionSteps
                solutionSteps={this.state.mathML}
              ></WolframSolutionSteps>

              {/* <FinalSolutionCard
                  finalSolution={this.state.solution.final}
                  solutionType={this.state.solution.type}
                  showSolutionSteps={this.showSolutionSteps}
                ></FinalSolutionCard> */}
            </div>
          </div>
        )}
        {this.state.solutionType === "new-native" && (
          // <div className="row">
          //   <div className="col-12">
          <div>
            {/* {this.state.loading && <SkeletonCard />}
            {!this.state.loading && */}
            <SolutionCard
              solution={this.state.solution}
              moveToSolutionSteps={() => {
                this.setState({
                  title: ""
                })
              }}
            >
            </SolutionCard>
            {/* }  */}
            {/* <StepPage
                    solution={this.state.solution}>
                  </StepPage> */}

            {/* {this.state.loading && <SkeletonCard/>}
                {!this.state.loading &&
                  <StepPage
                    solution={this.state.solution}>
                  </StepPage>
                } */}


            {/* {this.state.solutionStepsUI && (
                  this.state.solution.steps.map(() =>
                    <NewNativeSolutionStep
                      solution={this.state.solution}
                    ></NewNativeSolutionStep>
                  )
                )} */}
            {/* <EquationCard
                  asciimathEquation={this.state.equationFiltered}
                  latexEquation={latexEquation}
                  updateAsciiMathEquation={this.updateAsciiMathEquation}
                ></EquationCard>

                <FinalSolutionCard
                  showSolutionSteps={this.showSolutionSteps}
                >
                </FinalSolutionCard>  */}
            {/* <div className="row">
            <div className="col-12">
              {this.state.allSteps.map((value, i) => {
                return <div key={i}>{value}</div>;
              })}
            </div>
          </div> */}
            {/* </div>
          </div> */}
          </div>
        )}
        <div>
          {/* <button
              className="btn rounded-pill submit-btn-fm small bl-theme mt-5 mt-5"
              onClick={() => this.gotoAddImage()}
            >
              Done
            </button> */}
        </div>
        {/* </div> */}
      </div >
    );
  }
}

export default withRouter(Solution);
