import React from "react";
import MathJax from "react-mathjax";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Component } from "react";

class SolutionStepCard extends Component {
  async componentDidMount() {}

  toggleExpandView(i) {
    this.props.toggleExpandView(i);
  }

  render() {
    let stepNumber = parseInt(this.props.i) + 1;
    return (
      <div>
        <div className="sol-card-box sol-step" key={`step-${this.props.i}`}>
          <div>
            <div></div>
            <div>
              {this.props.isMainStep ? (
                <span className="sol-card-title">Step {stepNumber}</span>
              ) : (
                <div></div>
              )}
              {this.props.step.explanations &&
              this.props.step.explanations.length > 0 ? (
                <MathJax.Provider>
                  <div
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      fontSize: 25,
                      width: "100%",
                    }}
                  >
                    <MathJax.Node formula={this.props.step.explanations} />
                  </div>
                </MathJax.Provider>
              ) : (
                <div></div>
              )}

              <MathJax.Provider>
                <div
                  style={{
                    fontWeight: "bold",
                    textAlign: "center",
                    fontSize: 25,
                    width: "100%",
                  }}
                >
                  <MathJax.Node formula={this.props.step.result} />
                </div>
              </MathJax.Provider>
            </div>
            <div className="multi-steps-arrow">
              {this.props.step.steps !== null &&
              this.props.step.steps.length > 0 &&
              this.props.isMainStep ? (
                <span onClick={() => this.toggleExpandView(this.props.i)}>
                  {/* <span> */}
                  {this.props.expandedKeys.includes(this.props.i) ? (
                    <FontAwesomeIcon icon="angle-up" />
                  ) : (
                    <FontAwesomeIcon icon="angle-down" />
                  )}
                </span>
              ) : (
                <span></span>
              )}
            </div>
          </div>
          {this.props.step.steps !== null &&
          this.props.step.steps.length > 0 &&
          this.props.isMainStep &&
          this.props.expandedKeys.includes(this.props.i) ? (
            this.props.renderSubSteps(this.props.step.steps)
          ) : (
            <div></div>
          )}
        </div>
      </div>
    );
  }
}

export default SolutionStepCard;
