import React, { PureComponent } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';


export const CropImageSource = {
    SelectedImage: 1,
    CaptureImage: 2
};

export default class CropImage extends PureComponent {
    state = {
        crop: {
            unit: 'px',
            x: 100,
            y: 100,
            width: 100,
            height: 30,
        }
    };

    // If you setState the crop in here you should return false.
    onImageLoaded = (e) => {
        this.imageRef = e;
    };

    onCropComplete = (crop) => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const pixelRatio = window.devicePixelRatio;
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const ctx = canvas.getContext('2d');

        canvas.width = crop.width * pixelRatio * scaleX;
        canvas.height = crop.height * pixelRatio * scaleY;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width * scaleX,
            crop.height * scaleY
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    if (!blob) {
                        //reject(new Error('Canvas is empty'));
                        console.error('Canvas is empty');
                        return;
                    }
                    blob.name = fileName;
                    window.URL.revokeObjectURL(this.fileUrl);
                    this.fileUrl = window.URL.createObjectURL(blob);
                    resolve(this.fileUrl);
                },
                'image/jpeg',
                1
            );
        });
    }

    render() {
        const { crop, croppedImageUrl } = this.state;
        return (
            <div >
                <button
                    className='imgbtn'
                    style={{ position: 'absolute', right: 5, top: 5 }}
                    onClick={this.props.cancel}>
                    <img src={process.env.PUBLIC_URL + "/close.png"} width={25} height={25} alt=""></img>
                </button>
                {this.props.src && (
                    <div className='col-12' style={{width : '70%' , height:'70%' , position:'relative'}}>
                        <ReactCrop
                            src={this.props.src}
                            crop={crop}
                            ruleOfThirds
                            imageStyle={{ margin :'auto' }}
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                        />
                    </div>
                )}
                <br></br>
                <button
                    className='submit-btn-fm btn'
                    style={{ position : 'absolute' , right : 5 , bottom : 10}}
                    onClick={() => {
                        this.props.crop(croppedImageUrl)
                    }}>
                    Crop
                </button>

            </div>
        );
    }
}