import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationArrow,
  faMobileAlt,
  faVoicemail,
} from "@fortawesome/free-solid-svg-icons";
import AppStore from "./AppStore";
import { Link } from "react-router-dom";

export default function Footer(props) {
  return (
    <div className="footer">
      <div className="footer-info">
        <div className="footer-address-infos">
          <div
            style={{
              cursor: "pointer",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              fontSize: 18,
            }}
            onClick={() => {
              props.home();
            }}
          >
            <svg
              width="32"
              height="32"
              viewBox="0 0 64 64"
              fill="none"
              stroke="#01497C"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M61.3334 50.6667C61.3334 52.0812 60.7715 53.4377 59.7713 54.4379C58.7711 55.4381 57.4146 56 56.0001 56H8.00008C6.58559 56 5.22904 55.4381 4.22885 54.4379C3.22865 53.4377 2.66675 52.0812 2.66675 50.6667V21.3333C2.66675 19.9188 3.22865 18.5623 4.22885 17.5621C5.22904 16.5619 6.58559 16 8.00008 16H18.6667L24.0001 8H40.0001L45.3334 16H56.0001C57.4146 16 58.7711 16.5619 59.7713 17.5621C60.7715 18.5623 61.3334 19.9188 61.3334 21.3333V50.6667Z"
                fill="white"
                stroke="#01497C"
              />
              <path
                d="M32.2334 55.5V50.1333V44V21.3333H31.7334L32.2334 21.3333V21.3332V21.3328V21.3312V21.3248L32.2334 21.2998L32.2334 21.2041L32.2334 20.8566L32.2334 19.7477L32.2334 17.5621V14.6667V8.5H39.7325L44.9174 16.2773C45.0101 16.4164 45.1663 16.5 45.3334 16.5H56.0001C57.282 16.5 58.5114 17.0092 59.4178 17.9157C60.3242 18.8221 60.8334 20.0515 60.8334 21.3333V50.6667C60.8334 51.9485 60.3242 53.1779 59.4178 54.0844C58.5114 54.9908 57.282 55.5 56.0001 55.5H32.2334Z"
                fill="#01497C"
                stroke="#01497C"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M14.9736 45.3335H19.693V38.5591H26.6667V33.9746H19.693V27.2002H14.9736V33.9746H8V38.5591H14.9736V45.3335Z"
                fill="#01497C"
                stroke="#01497C"
              />
              <rect
                x="37.8667"
                y="33.6001"
                width="17.6"
                height="4.8"
                fill="white"
              />
            </svg>
            <div style={{ paddingLeft: 10, fontWeight: "450" }}>FEVMath</div>
          </div>
          <span>
            <FontAwesomeIcon icon={faMobileAlt} size="xs" /> {"+1 781 497 5144"}
          </span>{" "}
          <span>
            <FontAwesomeIcon icon={faVoicemail} size="xs" />{" "}
            {"contact@fevmath.com"}
          </span>{" "}
          <span>
            <FontAwesomeIcon icon={faLocationArrow} size="xs" />{" "}
            {"US Office: 500 West Cummings Park, Suite 2700, Woburn, MA 01801"}
          </span>{" "}
          {/* <span>
            <FontAwesomeIcon icon={faLocationArrow} size="xs" />{" "}
            {
              "India Office: #31, 4th Floor, Above A2B Restaurant, Hebbal Outer Ring Road, Bangalore 560094"
            }
          </span> */}
        </div>
        <div className="footer-quick-links">
          <div style={{ fontWeight: "450" }}>{"Quick Links:"}</div>
          <br />
          <div className="footer-links">
            <ul className="ul-links-list">
              <li
                onClick={() => {
                  props.home();
                }}
              >
                Home
              </li>
              <li
                onClick={() => {
                  props.howItWords();
                }}
              >
                How does it work?
              </li>
              <li
                onClick={() => {
                  props.whyUs();
                }}
              >
                Why us?
              </li>
              <li>
                <Link to="/terms-and-condition" style={{ color: '#01497c' }}> Terms & Conditions</Link>
              </li>
              <li>
                <Link to="/privacy-policy" style={{ color: '#01497c' }}> Privacy Policy</Link>
              </li>
              <li>
                <Link to="/get-help" style={{ color: '#01497c' }}>Help</Link>
              </li>
              {/* <li
                onClick={() => {
                  props.terms();
                }}
              >
                Terms & Conditions
              </li>
              <li
                onClick={() => {
                  props.privacy();
                }}
              >
                Privacy Policy
              </li>
              <li
                onClick={() => {
                  props.help();
                }}
              >
                Help
              </li> */}
            </ul>
          </div>
        </div>
        <div className="footer-appstore-links">
          <div style={{ fontWeight: "450" }}>{"Get the Apps"}</div>
          <AppStore />
        </div>
      </div>
      <div className="footer-copyright-info">
        &copy; FEVMath Copyright 2022. All Rights Reserved
        <br></br>
      </div>
    </div>
  );
}
