export function login(sessionObj) {
  localStorage.setItem("user", JSON.stringify(sessionObj));
  return {
    type: "LOGIN",
    payload: sessionObj,
  };
}

export function logout() {
  localStorage.removeItem("user");
  return { type: "LOGOUT" };
}

export function history(historyItems) {
  localStorage.setItem("history", JSON.stringify(historyItems));
  return {
    type: "HISTORY",
    payload: historyItems,
  };
}