import React from "react"

export default function SupportedProblemItemHeader(props) {

    return (
        <div>
            <div style={{ backgroundColor: '#D3D3D3', display: 'flex', height: 30, alignItems: 'center', paddingLeft: 10 }}>
                <text style={{ color: 'black', fontSize: 20 }}>{props.title}</text>
            </div>
            <div style={{ backgroundColor: '#F6F6F6', height: 2 }}></div>
        </div>
    );
}
