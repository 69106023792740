/**
 * This file contains the component for the scan, calculator,
 * solve and learn information cards used on the landing page.
 */

import React from "react";
import scan from "../assets/svg/scan.svg";
import calculate from "../assets/svg/calculate.svg";
import solve from "../assets/svg/solve.svg";
import learn from "../assets/svg/learn.svg";

export default function InfoCards() {
  return (
    <div className="App-body mobileView">
      <div className="info-intro">
        It's easy to learn with <br></br>
        <span>FEVMath</span>
      </div>
      <div className="cardsContent">
        <div className="cards">
          <img src={scan} className="imgCard" alt="scan"></img>
          <div className="cardTitle">Scan</div>
          <div className="cardContent">
            <p style={{ fontWeight: 450, fontSize: "14px" }}>
              {
                "Start by scanning your math problem using the FEVMath App or Web Application"
              }
            </p>
          </div>
        </div>
        <div className="cards">
          <img src={calculate} className="imgCard" alt="calculator"></img>
          <div className="cardTitle">Calculate</div>
          <div className="cardContent">
            <p style={{ fontWeight: 450, fontSize: "14px" }}>
              {
                "Or you can use our built in calculator to input your math problem"
              }
            </p>
          </div>
        </div>
        <div className="cards">
          <img src={solve} className="imgCard" alt="solve"></img>
          <div className="cardTitle">Solve</div>
          <div className="cardContent">
            <p style={{ fontWeight: 450, fontSize: "14px" }}>
              {
                "Relax and let us do some magic behind the scenes on your math problems. Watch FEVMath produce perfect solutions to your problems"
              }
            </p>
          </div>
        </div>
        <div className="cards">
          <img src={learn} className="imgCard" alt="learn"></img>
          <div className="cardTitle">Learn</div>
          <div className="cardContent">
            <p style={{ fontWeight: 450, fontSize: "14px" }}>
              {
                "Learn to solve the math problems with easy and clear steps. Dig deeper into each solution"
              }
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
