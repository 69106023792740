import React from "react";

export default function PrivacyPolicy() {
    const myHTML = `<html>

    <head>
    <meta http-equiv=Content-Type content="text/html; charset=utf-8">
    <meta name=Generator content="Microsoft Word 15 (filtered)">
    <style>
    <!--
     /* Font Definitions */
     @font-face
        {font-family:"Cambria Math";
        panose-1:2 4 5 3 5 4 6 3 2 4;}
    @font-face
        {font-family:Calibri;
        panose-1:2 15 5 2 2 2 4 3 2 4;}
     /* Style Definitions */
     p.MsoNormal, li.MsoNormal, div.MsoNormal
        {margin-top:0in;
        margin-right:0in;
        margin-bottom:8.0pt;
        margin-left:0in;
        line-height:107%;
        font-size:11.0pt;
        font-family:"Calibri",sans-serif;}
    a:link, span.MsoHyperlink
        {color:#0563C1;}
    .MsoChpDefault
        {font-family:"Calibri",sans-serif;}
    .MsoPapDefault
        {margin-bottom:8.0pt;
        line-height:107%;}
    @page WordSection1
        {size:595.3pt 841.9pt;
        margin:1.0in 1.0in 1.0in 1.0in;}
    div.WordSection1
        {page:WordSection1;}
     /* List Definitions */
     ol
        {margin-bottom:0in;}
    ul
        {margin-bottom:0in;}
    -->
    </style>
    
    </head>
    
    <body lang=EN-US link="#0563C1" vlink="#954F72" style='word-wrap:break-word'>
    
    <div class=WordSection1>
    
    <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>FEVTutor, Inc DBA <span style=''>FEVMath</span>
    and its direct and indirect subsidiaries, and such entities’ predecessors,
    successors, divisions, and groups (hereinafter <span style=''>FEVMath</span>)
    respect the privacy and values the confidence of its customers, employees, and
    business partners, and strives at all times to comply with the laws of the
    countries in which it does business, including those countries in which it
    collects Sensitive and Personal Data.&nbsp; This Privacy Policy describes how <span
    style=''>FEVMath</span> collects and uses the personal data
    you provide on our website:&nbsp;</span><span lang=EN-IN style='color:black'><a
    href="https://www.fevmath.com"><span style=''>https://www.fevmath.com</span></a></span></p>
    
    <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>&nbsp;</span></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>This Privacy Policy covers the following:</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;text-indent:-.25in;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='font-size:
    10.0pt;font-family:Symbol;color:#2A2B2C'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN style='color:#2A2B2C'>Who we are?</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;text-indent:-.25in;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='font-size:
    10.0pt;font-family:Symbol;color:#2A2B2C'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN style='color:#2A2B2C'>What information we
    collect?</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;text-indent:-.25in;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='font-size:
    10.0pt;font-family:Symbol;color:#2A2B2C'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN style='color:#2A2B2C'>How we use your
    information?</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;text-indent:-.25in;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='font-size:
    10.0pt;font-family:Symbol;color:#2A2B2C'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN style='color:#2A2B2C'>Who may use your
    information?</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;text-indent:-.25in;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='font-size:
    10.0pt;font-family:Symbol;color:#2A2B2C'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN style='color:#2A2B2C'>Third-party links and
    services.</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;text-indent:-.25in;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='font-size:
    10.0pt;font-family:Symbol;color:#2A2B2C'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN style='color:#2A2B2C'>How we protect your information?</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;text-indent:-.25in;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='font-size:
    10.0pt;font-family:Symbol;color:#2A2B2C'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN style='color:#2A2B2C'>Where your information is
    processed?</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;text-indent:-.25in;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='font-size:
    10.0pt;font-family:Symbol;color:#2A2B2C'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN style='color:#2A2B2C'>Marketing Communications.</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;text-indent:-.25in;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='font-size:
    10.0pt;font-family:Symbol;color:#2A2B2C'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN style='color:#2A2B2C'>How long we keep your
    information?</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;text-indent:-.25in;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='font-size:
    10.0pt;font-family:Symbol;color:#2A2B2C'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN style='color:#2A2B2C'>Your rights.</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;text-indent:-.25in;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='font-size:
    10.0pt;font-family:Symbol;color:#2A2B2C'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN style='color:#2A2B2C'>How you can contact us?</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='color:#2A2B2C'>&nbsp;</span></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>This Privacy Policy applies to all of the personal data
    we collect about you, during personal visit and information collected on our
    website and systems and any other websites (including mobile access) where this
    policy is linked to; all of which are referred to as “Site” and “Website” in
    this policy.</span></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>By providing information to us, you expressly agree to
    this Privacy Policy. &nbsp;This Privacy Policy was last updated on Aug 18 2021.
    and applies from that date.</span></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>We may occasionally amend this Privacy Policy. &nbsp;Any
    new policy will automatically be effective when it is published on the website.
    &nbsp;You should therefore return here regularly to view our most up to date
    Privacy Policy. &nbsp;You should also print a copy for your records.</span></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>We rely on each Parent to obtain consent and provide
    appropriate and disclosure, if necessary, for us to collect any student
    information from students under thirteen (13), as permitted by the Children’s
    Online Privacy Protection Act (COPPA). We do not knowingly collect or process
    data from anyone under the age of thirteen (13) years old.</span></p>
    
    <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
    normal;vertical-align:baseline'><b><span lang=EN-IN
    style='color:#2A2B2C;border:none windowtext 1.0pt;padding:0in'>Who are we?</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C;'>FEVMath</span><span lang=EN-IN
    style='color:#2A2B2C'> is an AI-based on-demand math assistance app to solve
    K-5 math problems instantly. Learners have to simply scan a math problem with
    their mobile camera or upload an image on their web browser and they get a
    detailed step-by-step mathematical solution.</span></p>
    
    <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='font-family:"Arial",sans-serif;color:#444444;background:#F7F7F7'>&nbsp;</span></p>
    
    <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
    normal;vertical-align:baseline'><b><span lang=EN-IN
    style='color:#2A2B2C;border:none windowtext 1.0pt;padding:0in'>What information
    do we collect?</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>Most of the personal data that <span style=''>FEVMath</span> holds is collected directly from its customers/clients
    engaged with <span style=''>FEVMath</span> (either directly or
    through a third party).</span></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C;'>FEVMath</span><span lang=EN-IN
    style='color:#2A2B2C'> also collects personal data that you provide to us on
    our website, which may include:</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;text-indent:-.25in;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='font-size:
    10.0pt;font-family:Symbol;color:#2A2B2C'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN style='color:#2A2B2C'>Contact and personal
    information – [name, email address, physical mailing address, telephone number,
    age, grade, school, Username and Passwords of students or parents (Only if
    students are minor).</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;text-indent:-.25in;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='font-size:
    10.0pt;font-family:Symbol;color:#2A2B2C'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN style='color:#2A2B2C'>Professional information –
    [such as your Educational background, related information];</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;text-indent:-.25in;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='font-size:
    10.0pt;font-family:Symbol;color:#2A2B2C'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN style='color:#2A2B2C'>Responses to surveys –
    [information you provide in responding to a survey on the website, via an app
    or email, on the telephone or otherwise];</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='color:#2A2B2C'>&nbsp;</span></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>We also collect information through means such as cookies
    – for more on this please see the next section below on how we use your
    information.&nbsp; We collect personal data from you only when you voluntarily
    provide it to us. &nbsp;You may withdraw your consent to us using your personal
    data at any time.&nbsp; Finally, we may use publicly accessible information to
    verify the information we are provided with and to manage and expand our
    business.</span></p>
    
    <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
    normal;vertical-align:baseline'><b><span lang=EN-IN
    style='color:#2A2B2C;border:none windowtext 1.0pt;padding:0in'>How do we use
    your information?</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>We use information collected about you as outlined in
    this Privacy Policy.</span></p>
    
    <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
    normal;vertical-align:baseline'><b><i><span lang=EN-IN
    style='color:#2A2B2C;border:none windowtext 1.0pt;padding:0in'>General</span></i></b></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>Our primary purpose in collecting your personal data is
    to provide you with the best available coaching service. We may use your
    personal data for the following purposes:</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;text-indent:-.25in;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='font-size:
    10.0pt;font-family:Symbol;color:#2A2B2C'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN style='color:#2A2B2C'>To contact you with
    additional information about <span style=''>FEVMath</span>’s
    product offerings;</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;text-indent:-.25in;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='font-size:
    10.0pt;font-family:Symbol;color:#2A2B2C'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN style='color:#2A2B2C'>To provide the
    information, services or support you request and related services after the
    site visit.;</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;text-indent:-.25in;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='font-size:
    10.0pt;font-family:Symbol;color:#2A2B2C'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN style='color:#2A2B2C'>To identify you, and to
    contact you from time to time with service updates;</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;text-indent:-.25in;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='font-size:
    10.0pt;font-family:Symbol;color:#2A2B2C'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN style='color:#2A2B2C'>To send other messages
    that are useful to the service we provide;</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;text-indent:-.25in;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='font-size:
    10.0pt;font-family:Symbol;color:#2A2B2C'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN style='color:#2A2B2C'>To manage our relationship
    with you and to carry out any related administration;</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;text-indent:-.25in;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='font-size:
    10.0pt;font-family:Symbol;color:#2A2B2C'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN style='color:#2A2B2C'>To promote our services,
    or the services of our partners, including by email and via social media
    platforms;</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;text-indent:-.25in;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='font-size:
    10.0pt;font-family:Symbol;color:#2A2B2C'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN style='color:#2A2B2C'>To compare information for
    accuracy, and verify it with third parties;</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;text-indent:-.25in;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='font-size:
    10.0pt;font-family:Symbol;color:#2A2B2C'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN style='color:#2A2B2C'>To detect, investigate and
    prevent activity we think may be potentially illegal, unlawful or harmful and
    to enforce our Privacy Policy and/or our Terms or any other purpose referenced
    herein or therein; and</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;text-indent:-.25in;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='font-size:
    10.0pt;font-family:Symbol;color:#2A2B2C'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN style='color:#2A2B2C'>To carry out research,
    including market research, statistical research on website traffic, sales and
    other commercial information to assist us in improving the services we provide
    to you.</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='color:#2A2B2C'>&nbsp;</span></p>
    
    <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
    normal;vertical-align:baseline'><b><i><span lang=EN-IN
    style='color:#2A2B2C;border:none windowtext 1.0pt;padding:0in'>Social Media</span></i></b></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>Our Site includes social media features, such as the
    Facebook “Like” button and widgets, such as the “Share this” button or
    interactive mini-programs that run on our Site. These Features may collect your
    IP address, which page you are visiting on our Site, and may set a cookie to
    enable the feature to function properly. &nbsp;Your IP address is a number that
    is automatically assigned to the computer that you are using by your Internet
    Service Provider. This number is identified and logged automatically whenever
    you visit the Site, along with the time(s) of your visit(s) and the page(s)
    that you visited.&nbsp; Social media features and widgets are either hosted by
    a third party or hosted directly on our Site.&nbsp; Your interactions with
    these features are governed by the Privacy Policy of the company providing the
    feature.</span></p>
    
    <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
    normal;vertical-align:baseline'><b><span lang=EN-IN
    style='color:#2A2B2C;border:none windowtext 1.0pt;padding:0in'>Who may use your
    information?</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>We may disclose your personal data to enforce our
    policies, to comply with our legal obligations or in the interests of security,
    public interest or law enforcement in any country where we have entities or
    affiliates. &nbsp;For example, we may respond to a request by a law enforcement
    agency or regulatory or governmental authority. &nbsp;We may also disclose data
    in connection with actual or proposed litigation, or to protect our property, security,
    people and other rights or interests.</span></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>We share your information with third parties who help
    deliver our products and services to you. &nbsp;Examples include hosting our
    web servers, analyzing data, providing marketing assistance, and providing
    customer service. &nbsp;These companies will have access to your personal
    information as necessary to perform their functions, but they may not use that
    data for any other purpose.</span></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>We may also share your personal data:</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;text-indent:-.25in;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='font-size:
    10.0pt;font-family:Symbol;color:#2A2B2C'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN style='color:#2A2B2C'>With companies in our
    group and/or our affiliates; or</span></p>
    
    <p class=MsoNormal style='margin-top:0in;margin-right:0in;margin-bottom:0in;
    margin-left:1.0in;text-align:justify;text-indent:-.25in;line-height:normal;
    vertical-align:baseline'><span lang=EN-IN style='font-size:
    10.0pt;font-family:Symbol;color:#2A2B2C'>·<span style='font:7.0pt "Times New Roman"'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span></span><span lang=EN-IN style='color:#2A2B2C'>With any third party, you
    have asked us to share your personal data with – such as Facebook or any other
    social media site if you have asked us to connect with your account.</span></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>You may have accessed our website through a hyperlink
    from the website of one of our trading partners.&nbsp; If so, you consent to
    your personal details and purchase information, including behavioural patterns,
    being shared with that trading partner in accordance with our contractual
    relationship with them.</span></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>If we or any part of our group is sold, or some of its
    assets transferred to a third party, your personal information, as a valuable
    asset, may also be transferred to the acquirer, even if they are not in the
    same line of business as us. &nbsp;Our customer database could be sold separately
    from the rest of the business, in whole or in several parts. &nbsp;Potential
    purchasers and their advisors may have limited access to data as part of the
    sale process. &nbsp;However, the use of your personal information will remain
    subject to this Privacy Policy.</span></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>Similarly, your personal information may be passed on to
    a successor in interest in the unlikely event of a liquidation, bankruptcy or
    administration.</span></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>Please remember that when you share information publicly
    on the Website, for example, a comment on a blog post, it may be indexable by
    search engines, including Google, which may mean that the information is made
    public.</span></p>
    
    <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
    normal;vertical-align:baseline'><b><span lang=EN-IN
    style='color:#2A2B2C;border:none windowtext 1.0pt;padding:0in'>Third-party
    links and services</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>The website may contain links to third parties websites.
    &nbsp;We are not responsible for the privacy practices or the content of those
    websites. &nbsp;Therefore, please read carefully any privacy policies on those
    links or websites before either agreeing to their terms or using those
    websites.</span></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>We may also use Google Analytics to gather statistics on
    site usage. &nbsp;This may involve the use of cookies. &nbsp;We have explained
    about cookies above – however, you should also look at Google’s own privacy
    policy for details of their data collection practices. &nbsp;Google may
    aggregate data they collect from their various services including Google
    Analytics, Google Translate, Google Maps and YouTube. &nbsp;You acknowledge and
    accept that we have no control over Google’s data collection.</span></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>We may use AWS (Amazon Web Service), Azure, Oracle Cloud
    and other cloud platforms for data centre management. This means we shall share
    your information provided to engage their services. For more information,
    please refer to their privacy policy pages.</span></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>If you have asked us to share data with third-party
    websites (such as social media sites), their servers may not be secure.</span></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>Note that, despite the measures taken by us and the third
    parties we engage, the internet is not secure. &nbsp;As a result, others may
    nevertheless unlawfully intercept or access private transmissions or data.</span></p>
    
    <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
    normal;vertical-align:baseline'><b><span lang=EN-IN
    style='color:#2A2B2C;border:none windowtext 1.0pt;padding:0in'>How Do We Protect
    Your Information?</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>We take what we consider to be appropriate technical and
    organisational measures to guard against unauthorised or unlawful processing of
    your personal data and against accidental loss or destruction of, or damage to,
    your personal data. &nbsp;While no system is completely secure, we believe the
    measures implemented by <span style=''>FEVMath</span> reduce
    vulnerability to security problems to a level appropriate to the type of data
    involved.&nbsp; We have best and latest security measures in place to protect
    our user database and access to this database is restricted internally.&nbsp;</span></p>
    
    <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
    normal;vertical-align:baseline'><b><span lang=EN-IN
    style='color:#2A2B2C;border:none windowtext 1.0pt;padding:0in'>Where is your
    information processed?</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C;'>FEVMath</span><span lang=EN-IN
    style='color:#2A2B2C'> does not sell, distribute or otherwise transfer Personal
    Data to third parties, except that <span style=''>FEVMath</span>
    may, on occasion, rely on third parties for information technology and data
    center management purposes, assistance with maintenance and support of
    computing infrastructure, and to provide additional data processing, storage,
    or remote recognition resources or systems.&nbsp; In such cases, <span
    style=''>FEVMath</span> will only transfer Personal Data to an
    agent, such as a service provider, vendor or other third party acting as a
    processor of Personal Data for <span style=''>FEVMath</span> where
    such agent has provided assurances that it provides at least the same material
    level of privacy protection as is required by this Privacy Policy. &nbsp;Where <span
    style=''>FEVMath</span> has knowledge that one of its agents
    is using or sharing Personal Data in a way that is contrary to this Privacy
    Policy, <span style=''>FEVMath</span> will take reasonable and
    appropriate steps to prevent or stop such processing.</span></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>We may need to transfer your personal data to the United
    States, India for the purpose of servicing, implementing, supporting, and
    maintaining <span style=''>FEVMath</span> supplied products
    and services; for management and administration functions; and to comply with <span
    style=''>FEVMath</span>’s legal obligations.&nbsp; To the
    extent applicable, any transfer of your data will be subject to the privacy
    laws of the US that will safeguard your privacy rights and give you remedies in
    the unlikely event of a security breach.</span></p>
    
    <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
    normal;vertical-align:baseline'><b><span lang=EN-IN
    style='color:#2A2B2C;border:none windowtext 1.0pt;padding:0in'>Marketing
    communications</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>If you have given permission, we may share your personal
    data with organisations who are our business partners and we or they may contact
    you (unless you have asked us or them not to do so) by mail, telephone, SMS,
    text/picture/video message, fax, email, other about products, services,
    promotions, and special offers that may be of interest to you.&nbsp; If you
    prefer not to receive any further direct marketing communications from us or
    our business partners, you can opt-out at any time. See further ‘Your rights,
    below.</span></p>
    
    <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
    normal;vertical-align:baseline'><b><span lang=EN-IN
    style='color:#2A2B2C;border:none windowtext 1.0pt;padding:0in'>How long do we
    keep your information?</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>We will retain your personal data only as long as is
    necessary for the purposes set out in this Privacy Policy, to deliver services
    to the relevant <span style=''>FEVMath</span> customer, in
    addition to retaining and using personal data as is necessary to comply with <span
    style=''>FEVMath</span>’s legal obligations, to resolve
    customer disputes, and to enforce <span style=''>FEVMath</span>’s
    agreements with its customers, after which we will delete it.</span></p>
    
    <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
    normal;vertical-align:baseline'><b><span lang=EN-IN
    style='color:#2A2B2C;border:none windowtext 1.0pt;padding:0in'>Other</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C;'>FEVMath</span><span lang=EN-IN
    style='color:#2A2B2C'> has put in place mechanisms to verify its ongoing
    compliance with this Privacy Policy.&nbsp; Any <span style=''>FEVMath</span>
    employee found to have violated the privacy principles in this Privacy Policy
    will be subject to appropriate corrective actions.</span></p>
    
    <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
    normal;vertical-align:baseline'><b><span lang=EN-IN
    style='color:#2A2B2C;border:none windowtext 1.0pt;padding:0in'>Your rights</span></b></p>
    
    <p class=MsoNormal style='margin-bottom:0in;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>Although <span style=''>FEVMath</span> receives,
    handles and in some cases, stores personal data, <span style=''>FEVMath</span>’s
    customers are typically the owners of such personal data. </span></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>As explained above, you have the right to opt-out of
    receiving communications from us. &nbsp;In addition, to the extent permitted by
    law, you may have the right to object to the processing of your personal data
    for direct marketing purposes. &nbsp;If your objection is not to direct
    marketing in general, but to direct marketing by a particular channel e.g.
    email or telephone, please specify the channel you are objecting to.</span></p>
    
    <p class=MsoNormal style='margin-bottom:15.0pt;text-align:justify;line-height:
    normal;vertical-align:baseline'><span lang=EN-IN
    style='color:#2A2B2C'>&nbsp;</span></p>
    
    <p class=MsoNormal><span lang=EN-IN>&nbsp;</span></p>
    
    </div>
    
    </body>
    
    </html>
    `;

    return (
        <div className="container" style={{ color: "black" }}>
            <h2>Privacy policy</h2>
            <div style={{ marginTop: 30 }}>
                <div dangerouslySetInnerHTML={{ __html: myHTML }} />
            </div>
        </div>
    );
}
