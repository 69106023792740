import React from "react";
import { Component } from "react";
import FocusMathAnimation from "focusmath-package-solver-animation"
import { isMobile } from 'react-device-detect';
import axios from "axios";
// import { useSpeechSynthesis } from "react-speech-kit";
// import { json } from "mathjs";

class NewNativeSolutionStep extends Component {

    async componentDidMount() {
        this.solveEquation()
    }

    state = {
        isExplainSteps: false,
        steps: "",
        count: 0,
        view: false
    }

    solveEquation = () => {
        FocusMathAnimation(
            "focus_math_animation",
            this.props.step,
            {
                primaryColor: '#01497C',
                secondaryColor: '#0AA1DD',
                commentTextColor: '#01497C',
                commentFontSize: 16
            },
            ((data) => {
                let json = JSON.parse(data)

                if (json.type === "SUBSTEP") {
                    let equation = json.text
                    if (equation === "irrational numbers") {
                        this.setState({
                            steps: {},
                            isExplainSteps: true,
                            count: this.state.count + 1,
                            view: true
                        })
                        this.solveEquation1()
                    } else {
                        this.getSolutionSteps(equation)
                    }
                } else if (json.type === "SPEECH") {
                    window.speechSynthesis.cancel()
                    if (json.text !== undefined) {
                        const msg = new SpeechSynthesisUtterance()
                        msg.text = json.text
                        window.speechSynthesis.speak(msg)
                    }
                }
            }),
            "fm-1-",
            true,
            false
        )
    }

    getSolutionSteps(equation) {
        let apiUrl = process.env.REACT_APP_API_URL + "/v1/mathsolver/new-native" +
            "?problem=" + encodeURIComponent(equation) + "&solver=v3-stage";
        axios
            .get(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                },
            })
            .then(
                (response) => {
                    let subSteps = response.data.solution.steps[0]
                    this.setState({
                        steps: subSteps,
                        isExplainSteps: true,
                        count: this.state.count + 1,
                        view: true
                    })
                    this.solveEquation1()
                })
    }

    solveEquation1 = () => {
        FocusMathAnimation(
            "focus_math_animation1",
            this.state.steps,
            {
                primaryColor: '#01497C',
                secondaryColor: '#0AA1DD',
                commentTextColor: '#01497C',
                commentFontSize: 16,
            },
            ((data) => {
                let json = JSON.parse(data)

                if (json.type === "SUBSTEP") {
                    let equation = json.text
                    if (equation === "irrational numbers") {
                        this.setState({
                            steps: {},
                            isExplainSteps: true,
                            count: this.state.count + 1,
                            view: true
                        })
                        this.solveEquation1()
                    } else {
                        this.getSolutionSteps(equation)
                    }
                } else if (json.type === "SPEECH") {
                    window.speechSynthesis.cancel()
                    if (json.text !== undefined) {
                        const msg = new SpeechSynthesisUtterance()
                        msg.text = json.text
                        window.speechSynthesis.speak(msg)
                    }
                }
            }),
            "fm-12-" + this.state.count,
            true,
            false
        )
    }

    closeSteps = () => {
        this.setState({
            isExplainSteps: false,
            count: this.state.count - 1,
            view: false
        }, () => {
            this.solveEquation()

        })
    }

    render() {
        return (
            <div style={{ width: "100%", height: 500, position: 'relative' }}>
                {isMobile && <div>
                    <img style={{ position: 'absolute', right: 20, top: 20 }} src={process.env.PUBLIC_URL + "/close.png"} width={25} height={25} alt="" onClick={() => this.props.onClick()}></img>
                    <div className="" style={{ textAlign: "center", marginBottom: "20px", fontSize: "20px", fontWeight: '500', fontFamily: 'RobotoRegular', marginLeft: 30, marginRight: 30 }}>
                        {this.props.solution.action}
                    </div>
                </div>
                }
                {this.state.isExplainSteps === false &&
                    <div id="focus_math_animation" style={{ position: 'absolute', marginTop: isMobile ? 30 : 0, width: '100%', height: '100%' }}>
                    </div>
                }
                {/* {this.state.isExplainSteps === true && */}
                <div className="view" style={{ top: this.state.view === true && '-30px' }} >
                    <div style={{ fontSize: "20px", fontWeight: '500', fontFamily: 'RobotoRegular' }}>
                        {console.log("this.state.steps.action: ", this.state.steps.action)}
                        {this.state.steps.action === undefined ? "Irrational numbers" : this.state.steps.action}
                        <img src={process.env.PUBLIC_URL + "/close.png"} width={25} height={25} style={{ float: 'right' }} alt="" onClick={this.closeSteps}></img>
                    </div>
                    <div id="focus_math_animation1" style={{ position: 'absolute', marginTop: isMobile ? 30 : 0, width: '100%', height: 500, backgroundColor: 'white' }} >
                    </div>
                </div>
                {/* } */}
            </div>
        );
    }
}

export default NewNativeSolutionStep;