import React from "react";
import SolutionStepCard from "./cards/SolutionStepCard";

import { Component } from "react";

class SolutionStepsCard extends Component {
  constructor(props) {
    super(props);
    this.toggleExpandView = this.toggleExpandView.bind(this);
    this.renderSubSteps = this.renderSubSteps.bind(this);
    this.createStepJSX1 = this.createStepJSX1.bind(this);
  }

  state = {
    expandedKeys: [],
  };

  async componentDidMount() { }

  toggleExpandView(i) {
    const currentExpandedKeys = this.state.expandedKeys;
    if (currentExpandedKeys.includes(i)) {
      currentExpandedKeys.pop(i);

      const a = i + ".";
      const startsWithi = currentExpandedKeys.filter((ek) => ek.includes(a));
      currentExpandedKeys.pop(startsWithi);
    } else {
      currentExpandedKeys.push(i);
    }

    this.setState({
      expandedKeys: currentExpandedKeys,
    });
  }

  createStepJSX(step, i, numberOfSubStep, isMainStep) {
    return (
      // <div key={i} onClick={() => this.toggleExpandView(i)}>
      <div key={i}>
        <SolutionStepCard
          i={i}
          step={step}
          numberOfSubStep={numberOfSubStep}
          isMainStep={isMainStep}
          toggleExpandView={this.toggleExpandView}
          renderSubSteps={this.renderSubSteps}
          expandedKeys={this.state.expandedKeys}
        ></SolutionStepCard>
      </div>
    );
  }

  createStepJSX1(step, i, numberOfSubStep, isMainStep) {
    var ro = [];
    // var nss = numberOfSubStep;
    ro.push(this.createStepJSX(step, i, numberOfSubStep, isMainStep));
    // if (expanedKeys.includes(i)) {
    //     if (step.steps !== null && step.steps.length > 0) {
    //         step.steps.map((substep, a) =>
    //             ro.push(createStepJSX1(substep, i + "." + a.toString(), nss + 1), false)
    //         );
    //     }
    // }
    return ro;
  }

  renderSteps(steps) {
    return steps.map((step, i) =>
      this.createStepJSX1(step, i.toString(), 0, true)
    );
  }

  renderSubSteps(s) {
    return s.map((step, i) =>
      this.createStepJSX1(step, i.toString(), 0, false)
    );
  }

  render() {
    return (
      <div className="row">
        <div className="col-12">
          {this.props.solutionSteps && (
            <div>
              {/* <span>Steps count {this.props.solutionSteps.length}</span> */}
              <div className="row">
                <div className="col-12">
                  {this.renderSteps(this.props.solutionSteps)}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SolutionStepsCard;
