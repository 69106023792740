import React, { Component } from "react";
import MathJax from 'react-mathjax-preview';
import NewNativeSolutionStep from "./solution/NewNativeSolutionStep";
import 'react-loading-skeleton/dist/skeleton.css'
import { withRouter } from "react-router-dom";
import { isMobile } from 'react-device-detect';
import Modal from 'react-modal';
import qs from "qs";
import axios from "axios";

class StepPage extends Component {
    async componentDidMount() {
        let queryParams = qs.parse(this.props.location.search.split("?")[1]);

        if (queryParams["isBookmarked"] === "true") {
            this.setState({
                isBookmarked: true
            })
        } else {
            this.setState({
                isBookmarked: false
            })
        }
    }

    state = {
        isExplainSteps: false,
        isCollapsed: true,
        isBookmarked: false,
        collapsedIndex: -1,
    }

    cropCustomStyles = {
        content: {
            width: '100%',
            height: '100%',
            maxWidth: '100%',
            maxHeight: '100%',
            visibility: 'visible',
            overflow: 'hidden',
            inset: '0px'
        },
    };

    goBackPage = () => {
        this.props.history.goBack()
    }
    toogleBookmark = (isBookmarked) => {
        this.setState({
            isBookmarked: isBookmarked
        });
        this.updateBookmark(isBookmarked);
    }

    updateBookmark = (isBookmarked) => {
        var url = window.location.href
        var r = new URL(url)
        r.searchParams.set("isBookmarked", isBookmarked)
        const newUrl = r.href
        window.history.replaceState({ path: newUrl }, '', newUrl);
    }

    makeBookmark = () => {
        var url = window.location.href
        var r = new URL(url)

        if (r.searchParams.get("history-id") !== null && r.searchParams.get("history-id") !== "") {
            var data = {
                histRef: r.searchParams.get("history-id"),
                isBookmarked: !this.state.isBookmarked
            }

            var userData = JSON.parse(window.localStorage.user).data
            var that = this
            axios
                .put(
                    process.env.REACT_APP_API_URL + "/v1/history/" + userData.user.id,
                    data,
                    {

                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": "Bearer " + userData.token.accessToken
                        },
                    },
                )
                .then(function (response) {
                    that.toogleBookmark(data.isBookmarked);
                })
                .catch(function (error) {
                });
        }
    }

    closeModal = () => {
        this.setState({
            isCollapsed: !this.state.isCollapsed,
            collapsedIndex: -1
        })
    }

    renderAnimation(solution, step, i) {
        if (this.state.collapsedIndex === i &&
            step.resources.animations.length > 0 && !isMobile) {
            return (
                <NewNativeSolutionStep
                    solution={this.props.solution}
                    step={step}
                ></NewNativeSolutionStep>
            )
        } else if (this.state.collapsedIndex === i &&
            step.resources.animations.length > 0 && isMobile) {

            return (
                <Modal
                    closeTimeoutMS={2000}
                    isOpen={true}
                    onAfterOpen={() => { }}
                    onRequestClose={() => {

                    }}
                    style={this.cropCustomStyles}
                    contentLabel="WebcamModel" >

                    <NewNativeSolutionStep
                        solution={this.props.solution}
                        step={step}
                        onClick={this.closeModal}
                    ></NewNativeSolutionStep>
                </Modal>
            )
        }
    }
    renderStep(solution) {
        return (
            <div>
                <div className="primary-color solcont">
                    <div className="row">
                        <div className="backArrowBoxMobile" onClick={this.goBackPage}>
                            <img style={{ marginRight: '10px', marginLeft: '12px' }} src={process.env.PUBLIC_URL + "/ic_arrow_back.png"} width={20} height={20} alt=""></img>
                        </div>
                        <div className="bookmarkMobile" onClick={this.makeBookmark}>
                            <img style={{ marginLeft: '7px' }} src={this.state.isBookmarked ? process.env.PUBLIC_URL + "/bookmark-icon-new.png" : process.env.PUBLIC_URL + "/bookmark-icon-outline-new.png"} width={20} height={20} alt=""></img>
                        </div>
                    </div>
                    <div className="solExpTitle" style={{ textAlign: "center", marginBottom: "20px", fontSize: "20px", fontWeight: '500', fontFamily: 'RobotoRegular' }}>
                        {solution.action}
                    </div>
                    <div className="problembox box1">
                        {solution.problemTex !== undefined && solution.problemTex.includes("frac") ?
                            <MathJax math={'\\(' + solution.problemTex + '\\)'} />
                            :
                            (solution.problemTex !== undefined && solution.problemTex.includes("^") ?
                                <MathJax math={'\\(' + solution.problemTex + '\\)'} />
                                :
                                (solution.problemTex !== undefined && solution.problemTex.includes("pi") ?
                                    <MathJax math={solution.problemTex.replace('pi', "π")} />
                                    :
                                    <MathJax math={solution.problemTex} />
                                )
                            )
                        }
                    </div>
                    <div className="row">
                        <div style={{ width: 2, background: '#c2c2c25b', marginLeft: 25, marginTop: -10, marginBottom: 5 }}></div>
                        <div className="solcontbox box1">
                            {
                                this.props.solution.steps.map((step, i) =>
                                    <div>
                                        <div >
                                            <MathJax math={step.comment} />
                                            <div>{this.renderAnimation(solution, step, i)}</div>
                                        </div>
                                        <div>
                                            {step.resources.animations.length > 0 &&
                                                <button
                                                    type="submit"
                                                    className="submit-btn-fm"
                                                    onClick={() => {
                                                        this.setState({
                                                            collapsedIndex: (this.state.collapsedIndex === i ? -1 : i),
                                                            isCollapsed: !this.state.isCollapsed,
                                                        })
                                                    }}
                                                >
                                                    {((i !== this.state.collapsedIndex)) && (<img src={process.env.PUBLIC_URL + "/Icon_animation_play.png"} width={20} height={20} style={{ marginRight: "10px" }} alt=""></img>)}
                                                    {(i === this.state.collapsedIndex) ? "Close" : "Explain how"}
                                                </button>
                                            }
                                            {solution.steps.length > 1 && (
                                                <div className="step-divider-line divider"></div>
                                            )}
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div>
                    <div className="resultbox box1">
                        <MathJax math={solution.resultTex} />
                    </div>
                    <div style={{ textAlign: "center", marginTop: 35 }}>
                        <button
                            className="solbtn"
                            onClick={this.goBackPage}
                        >
                            Yay, I've Understood
                            {/* Solve more */}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
    render() {
        return (
            <div className="row">
                <div className="col-12">
                    {this.renderStep(this.props.solution)}
                </div>
            </div>
        );
    }
}
export default withRouter(StepPage);
