import React from "react";
import MathJax from "react-mathjax-preview";

import { Component } from "react";

class WolframSolutionSteps extends Component {
  async componentDidMount() { }

  renderSteps(steps) {
    if (!steps || steps === {}) {
      return;
    }
    // debugger;
    let stepsUI = "";
    for (let i = 0; i < steps.length; i++) {
      stepsUI =
        stepsUI +
        '<div class="equation-box"><math xmlns="http://www.w3.org/1998/Math/MathML" mathematica:form="StandardForm" xmlns:mathematica="http://www.wolfram.com/XML/"><mtable displaystyle="true">';
      stepsUI = stepsUI + steps[i].outerHTML;
      stepsUI = stepsUI + "</mtable> </math></div>";
    }

    return stepsUI;
  }

  render() {
    return (
      <div className="row">
        <div className="col-12">
          {this.props.solutionSteps && (
            <div>
              {/* <span>Steps count {this.props.solutionSteps.length}</span> */}
              <div className="row">
                <div className="col-12">
                  {/* <math
                    xmlns="http://www.w3.org/1998/Math/MathML"
                    mathematica:form="StandardForm"
                    xmlns:mathematica="http://www.wolfram.com/XML/"
                  >
                    <mtable displaystyle="true">
                      <MathJax
                        math={this.renderSteps(this.props.solutionSteps)}
                      />
                    </mtable>
                  </math> */}
                  <MathJax math={this.renderSteps(this.props.solutionSteps)} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default WolframSolutionSteps;
