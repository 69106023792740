import React from "react";

export default function About() {
    const myHTML = `<html>
    <p>FEVMath is an AI-based on-demand math assistance app available on Android, iOS devices as well as a web application. It is developed to solve math problems instantly. Artificial intelligence technology predicts the equation and solves it in real-time, just like a math tutor would do on the chalkboard. Learners have to simply scan a math problem with their mobile camera or upload an image on the web application and they get a detailed step-by-step explanation to the math expressions.</p>
    <p>The app can recognize both handwritten and printed text and has an intuitive math keyboard feature. AI specializes in offering virtual calculation steps for any equation or expression, currently for K-8 math expressions and in subsequent versions up to high school. In addition to this, the app provides visual representations to help drive complete conceptual understanding to the students.</p>
    <p>The main focus of the app is to help students not only get the solution to a math expression but also understand the step by step explanations aided by animations for a better understanding.</p>
    </html>
    `;

    return (
        <div className="container" style={{ color: "black" }}>
            <h2>About</h2>
            <div style={{ marginTop: 30 }}>
                <div dangerouslySetInnerHTML={{ __html: myHTML }} />
                {/* <text>{myHTML}</text> */}
            </div>
        </div>
    );
}
