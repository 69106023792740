/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
import $ from "jquery";
import * as math from 'mathjs';
import { Component } from "react";
import { Popover } from 'react-tiny-popover';
import CalculatorTextArea from "./common/calculatorTextArea";
import CalculatorUtil, { EquationElement, EquationSymbol, Expression, FractionExpression, SquareExpression } from "./common/CalculatorUtil";

class CalculatorNew1 extends Component {
    state = {
        cursorIndex: "0",
        onFocusTextInputKey: undefined,
        onFocusEquationElementBase: undefined,

        selected: 0,
        isBlocker: false,
        isLoading: false,
        isStandard: true,
        display: {
            value: "",
            innerHTML: "",
            result: ""
        },

        equations: [],
        currentCursorPosition: 0,
        values: [],
        equation: "",
        onSelectedPopoverKey: undefined,
        currentCursorItemId: ""
    };

    onSelectedKey = undefined

    async componentDidMount() {
        console.log("componentDidMount window.location.href", (window.location.href.split("/").length))

        $(".show-solution-steps").hide();
        $("calc-display-wrapper_display").hide();

        const equationFiltered = decodeURIComponent(
            window.location.pathname.replace("/calculator/", "")
        ).replace(" ", "");
        if (equationFiltered !== "") {
            this.decodeEquation(equationFiltered)
            this.setState({
                display: {
                    value: equationFiltered,
                    innerHTML: equationFiltered
                }
            });
        }
    }
    formEquationToSolve() {
        let equation = ""
        let ismmdEquation = false
        for (let i = 0; i < this.state.equations.length; i++) {
            let map = this.state.equations[i]
            let ap = this.appendEquation(map, true)
            equation += ap.equation
            if (ap.ismmdEquation) {
                ismmdEquation = true
            }
        }
        return { 'equation': equation, 'ismmdEquation': ismmdEquation }
    }

    gotoSolutionSteps() {
        let solve = this.formEquationToSolve()
        let equ = solve.equation
        let ismmdEquation = solve.ismmdEquation
        equ = equ.replace(/×/g, "*").replace(/÷/g, "/")

        var href = window.location.origin + "/solution/" + encodeURIComponent(equ) + "?type=" + (ismmdEquation ? "new-native-text" : "new-native");
        let calcExpression = href
        window.location.href = href;
        return calcExpression;
    }

    clear() {
        this.setState({
            equations: [],
            onFocusTextInputKey: 0,
            cursorIndex: '0',
            display: {
                value: "",
                innerHTML: "",
                result: ""
            }
        });
        $("calc-display-wrapper_display_clear_button").hide();
    }

    checkEquationContainEmptyElement = (arr, index) => {
        if (arr.length > index) {
            var e = arr[index + 1]
            if (e instanceof EquationElement) {
                if (e.element === "") {
                    return true
                }
            }
        }
        return false
    }


    getNextElementID = (arr) => {
        var data = undefined

        // console.log("this.state.currentCursorItemId  : ", this.state.currentCursorItemId)

        if (this.state.currentCursorItemId === -1) {
            if (arr.length > 0) {
                var firstElement = arr[0]
                if (firstElement instanceof Expression) {
                    return data = {
                        id: firstElement.elements[0].id,
                        index: 0,
                        arr: firstElement.elements
                    }
                } else {
                    return data = {
                        id: firstElement.id,
                        index: 0,
                        arr: arr
                    }

                }
            }

        } else {
            // console.log("XXXX##2")

            for (var index = 0; index < arr.length; index++) {
                var e = arr[index]
                if (e instanceof FractionExpression || e instanceof SquareExpression) {
                    for (var index1 = 0; index1 < e.elements.length; index1++) {
                        var firstE = e.elements[index1]

                        if (firstE instanceof Expression) {
                            data = this.getNextElementID(e.elements)
                            if (data !== undefined) {
                                // console.log("data :", data)
                                if (data.id === this.state.currentCursorItemId) {
                                    var nextElement3 = e.secondElements[0]
                                    if (nextElement3 instanceof Expression) {
                                        // console.log("nextElement", nextElement3)
                                        return data = {
                                            id: nextElement3.elements[0].id,
                                            index: 0,
                                            arr: nextElement3.elements
                                        }

                                    } else {
                                        return data = {
                                            id: nextElement3.id,
                                            index: 0,
                                            arr: e.secondElements
                                        }
                                    }
                                }
                                return data
                            }
                        } else if (firstE.id === this.state.currentCursorItemId) {
                            // console.log("firstE : ", firstE, "  id : ", this.state.currentCursorItemId)

                            if (index1 === e.elements.length - 1) {
                                var nextElement = e.secondElements[0]
                                if (nextElement instanceof Expression) {
                                    // console.log("nextElement", nextElement)
                                    return data = {
                                        id: nextElement.elements[0].id,
                                        index: 0,
                                        arr: nextElement.elements
                                    }

                                } else {
                                    return data = {
                                        id: nextElement.id,
                                        index: 0,
                                        arr: e.secondElements
                                    }
                                }

                            } else {

                                var nextElement2 = e.elements[index1 + 1]
                                if (nextElement2 instanceof Expression) {

                                    return data = {
                                        id: nextElement2.elements[0].id,
                                        index: 0,
                                        arr: nextElement2.elements
                                    }
                                } else {

                                    return data = {
                                        id: e.elements[index1 + 1].id,
                                        index: index1 + 1,
                                        arr: e.elements
                                    }
                                }
                            }
                        }
                    }

                    for (var index2 = 0; index2 < e.secondElements.length; index2++) {

                        var secondE = e.secondElements[index2]

                        if (secondE instanceof Expression) {
                            data = this.getNextElementID(e.secondElements)
                            if (data !== undefined) {
                                if (data.id === this.state.currentCursorItemId) {
                                    if (index < arr.length - 1) {
                                        var nextParentElement1 = arr[index + 1]
                                        if (nextParentElement1 instanceof Expression) {

                                            var cursorIndex2 = 0
                                            var cursorE2 = nextParentElement1.elements[cursorIndex2]
                                            return data = {
                                                id: cursorE2.id,
                                                index: cursorIndex2,
                                                arr: nextParentElement1.elements
                                            }

                                        } else {
                                            return data = {
                                                id: nextParentElement1.id,
                                                index: index + 1,
                                                arr: arr
                                            }
                                        }
                                    }
                                }
                                return data
                            }
                        } else if (secondE.id === this.state.currentCursorItemId) {

                            if (index2 === e.secondElements.length - 1) {
                                if (index < arr.length - 1) {
                                    var nextParentElement = arr[index + 1]
                                    if (nextParentElement instanceof Expression) {
                                        var cursorIndex = 0
                                        var cursorE = nextParentElement.elements[cursorIndex]
                                        return data = {
                                            id: cursorE.id,
                                            index: cursorIndex,
                                            arr: nextParentElement.elements
                                        }
                                    } else {
                                        return data = {
                                            id: nextParentElement.id,
                                            index: index + 1,
                                            arr: arr
                                        }
                                    }
                                }
                            } else {
                                return data = {
                                    id: e.secondElements[index2 + 1].id,
                                    index: index2 + 1,
                                    arr: e.secondElements
                                }
                            }
                        }
                    }
                } else if (e.id === this.state.currentCursorItemId) {

                    // console.log("XXX 2", arr)
                    if (index === arr.length - 1) {
                        // console.log("XXX 3")
                        return data = {
                            id: e.id,
                            index: index,
                            arr: arr
                        }
                    } else {

                        var secondNextElement = arr[index + 1]

                        // console.log("XXX 2nextElement", secondNextElement)


                        if (secondNextElement instanceof Expression) {
                            // console.log("XXX 3nextElement", secondNextElement)

                            var cursorIndex1 = 0
                            var cursorE1 = secondNextElement.elements[cursorIndex1]
                            return data = {
                                id: cursorE1.id,
                                index: cursorIndex1,
                                arr: secondNextElement.elements
                            }

                        } else {
                            // console.log("XXX 4nextElement", secondNextElement)

                            return data = {
                                id: secondNextElement.id,
                                index: index + 1,
                                arr: arr
                            }
                        }

                    }
                }
            }
        }
        return data
    }

    getPerviousElementID = (arr) => {
        var data = undefined
        // console.log("getPerviousElementID")
        arr.map((e, index) => {
            if (e instanceof FractionExpression || e instanceof SquareExpression) {
                e.elements.map((firstE, index1) => {
                    if (firstE instanceof FractionExpression) {
                        data = this.getPerviousElementID(e.elements)
                        if (data !== undefined) {
                            return data
                        }
                    } else if (firstE instanceof SquareExpression) {
                        data = this.getPerviousElementID(e.elements)
                        if (data !== undefined) {
                            return data
                        }
                    } else if (firstE.id === this.state.currentCursorItemId) {
                        if (index1 === 0) {
                            if (this.CheckEquationAsValue(e)) {
                                if (index > 0) {
                                    return data = {
                                        id: arr[index - 1].id,
                                        index: index - 1,
                                        arr: arr
                                    }
                                } else {
                                    return data = {
                                        id: -1,
                                        index: index,
                                        arr: arr
                                    }

                                }
                            } else {
                                if (index > 0) {
                                    console.log("getPerviousElementID6 : ", arr[index - 1])
                                    return data = {
                                        id: arr[index - 1].id,
                                        index: index - 1,
                                        arr: arr
                                    }
                                } else if (arr.length > 0) {
                                    var firstElement = arr[0]

                                    if (firstElement instanceof Expression) {
                                        var lastElement = firstElement.elements[firstElement.elements.length - 1]
                                        return data = {
                                            id: lastElement.id,
                                            index: 0,
                                            arr: arr
                                        }
                                    } else {
                                        return data = {
                                            id: firstElement.id,
                                            index: 0,
                                            arr: arr
                                        }
                                    }
                                }
                            }
                        } else {
                            return data = {
                                id: e.elements[index1 - 1].id,
                                index: index1 - 1,
                                arr: e.elements
                            }
                        }
                    }
                })

                e.secondElements.map((secondE, index2) => {
                    if (secondE instanceof FractionExpression) {
                        data = this.getPerviousElementID(e.secondElements)
                        if (data !== undefined) {
                            return data
                        }
                    } else if (secondE instanceof SquareExpression) {
                        data = this.getPerviousElementID(e.secondElements)
                        if (data !== undefined) {
                            return data
                        }
                    } else if (secondE.id === this.state.currentCursorItemId) {
                        if (index2 === 0) {
                            var previousElement = e.elements[e.elements.length - 1]
                            if (previousElement instanceof Expression) {
                                return data = {
                                    id: previousElement.secondElements[previousElement.secondElements.length - 1].id,
                                    index: previousElement.secondElements.length - 1,
                                    arr: previousElement.secondElements
                                }
                            } else {
                                return data = {
                                    id: e.elements[e.elements.length - 1].id,
                                    index: e.elements.length - 1,
                                    arr: e.elements
                                }
                            }
                        } else {
                            return data = {
                                id: e.secondElements[index2 - 1].id,
                                index: index2 - 1,
                                arr: e.secondElements
                            }

                        }

                    }
                })

            } else if (e.id === this.state.currentCursorItemId) {
                if (index === 0) {
                    return data = {
                        id: -1,
                        index: index,
                        arr: arr
                    }
                } else {
                    console.log("getPerviousElementID45 : ", arr)

                    var previousElement = arr[index - 1]
                    if (previousElement instanceof Expression) {

                        var cursorIndex = previousElement.secondElements.length - 1
                        var cursorE = previousElement.secondElements[cursorIndex]
                        return data = {
                            id: cursorE.id,
                            index: cursorIndex,
                            arr: previousElement.secondElements
                        }

                    } else {
                        return data = {
                            id: arr[index - 1].id,
                            index: index - 1,
                            arr: arr
                        }
                    }

                }
            }
        })

        return data
    }

    // CheckPrevoiusElement = (arr, index) => {

    //     if (index === 0) {

    //     } else {
    //         return data = {
    //             id: arr[index - 1].id,
    //             index: index - 1,
    //             arr: arr
    //         }
    //     }
    // }


    checkIsLastElement = (arr, id) => {
        if (arr[arr.length - 1].id === id) {
            return true
        }
        return false
    }
    removeElement = (arr) => {
        var data = undefined

        for (var index = 0; index < arr.length; index++) {

            var e = arr[index]
            // arr.map((e, index) => {
            if (e instanceof FractionExpression || e instanceof SquareExpression) {

                // e.elements.map((firstE, index1) => {
                for (var index1 = 0; index1 < e.elements.length; index1++) {
                    var firstE = e.elements[index1]
                    if (firstE instanceof FractionExpression) {
                        data = this.removeElement(e.elements)

                        if (data !== undefined) {
                            return data
                        }
                    } else if (firstE instanceof SquareExpression) {
                        data = this.removeElement(e.elements)
                        if (data !== undefined) {
                            return data
                        }
                    } else if (firstE.id === this.state.currentCursorItemId) {

                        if (index1 === 0) {
                            e.elements[index1].element = ""
                            if (this.CheckEquationAsValue(e)) {
                                if (index > 0) {
                                    return data = {
                                        id: arr[index - 1].id,
                                        index: index - 1,
                                        arr: arr
                                    }
                                }
                            } else {
                                if (this.checkEquationContainEmptyElement(arr, index) && e.elements[index1].parentIdentifier === '') {

                                    console.log("XX1")
                                    CalculatorUtil.remove(arr, index + 1)
                                } else if (!(this.checkEquationContainEmptyElement(arr, index)) && e.elements[index1].parentIdentifier !== '') {
                                    CalculatorUtil.insert(arr, index + 1, new EquationElement("EmptyObj", e.elements[index1].parentIdentifier, ""))
                                }
                                console.log("XX remove")
                                CalculatorUtil.remove(arr, index)

                                if (index > 0) {
                                    return data = {
                                        id: arr[index - 1].id,
                                        index: index - 1,
                                        arr: arr
                                    }
                                } else if (arr.length > 0) {

                                    var firstElement = arr[0]

                                    if (firstElement instanceof Expression) {
                                        var lastElement = firstElement.elements[firstElement.elements.length - 1]
                                        return data = {
                                            id: lastElement.id,
                                            index: 0,
                                            arr: arr
                                        }
                                    } else {
                                        return data = {
                                            id: firstElement.id,
                                            index: 0,
                                            arr: arr
                                        }
                                    }
                                }
                            }

                        } else {
                            console.log("XX9")

                            CalculatorUtil.remove(e.elements, index1)
                            return data = {
                                id: e.elements[index1 - 1].id,
                                index: index1 - 1,
                                arr: e.elements
                            }
                        }
                    }
                }

                for (var index2 = 0; index2 < e.secondElements.length; index2++) {

                    var secondE = e.secondElements[index2]
                    // e.secondElements.map((secondE, index2) => {
                    if (secondE instanceof FractionExpression) {
                        data = this.removeElement(e.secondElements)
                        if (data !== undefined) {
                            return data
                        }
                    } else if (secondE instanceof SquareExpression) {
                        data = this.removeElement(e.secondElements)
                        if (data !== undefined) {
                            return data
                        }
                    } else if (secondE.id === this.state.currentCursorItemId) {
                        if (index2 === 0) {
                            e.secondElements[index2].element = ""
                            var previousElement = e.elements[e.elements.length - 1]
                            if (previousElement instanceof Expression) {
                                var cursorIndex = previousElement.secondElements.length - 1
                                var cursorE = previousElement.secondElements[cursorIndex]
                                return {
                                    id: cursorE.id,
                                    index: cursorIndex,
                                    arr: previousElement.secondElements
                                }
                            } else {
                                return {
                                    id: previousElement.id,
                                    index: e.elements.length - 1,
                                    arr: e.elements
                                }
                            }
                        } else {
                            CalculatorUtil.remove(e.secondElements, index2)
                            return this.getPerviousCursorData(e.secondElements, index2)
                        }

                    }
                }

            } else if (e.id === this.state.currentCursorItemId) {

                if (index === 0) {
                    CalculatorUtil.remove(arr, index)
                    return data = {
                        id: e.id,
                        index: index,
                        arr: arr
                    }
                } else {
                    if (this.checkIsLastElement(arr, arr[index].id)) {
                        arr[index].element = ""
                    } else {
                        CalculatorUtil.remove(arr, index)
                    }
                    return this.getPerviousCursorData(arr, index)

                }
            }
        }
        return data
    }


    getPerviousCursorData = (arr, index) => {
        var previousElement = arr[index - 1]
        if (previousElement instanceof Expression) {
            var cursorIndex = previousElement.secondElements.length - 1
            var cursorE = previousElement.secondElements[cursorIndex]
            return {
                id: cursorE.id,
                index: cursorIndex,
                arr: previousElement.secondElements
            }
        } else {
            return {
                id: arr[index - 1].id,
                index: index - 1,
                arr: arr
            }
        }
    }
    getNeighbourElementID = (arr, isNext) => {

        // console.log("XXX 1")
        var data = undefined

        arr.map((e, index) => {
            if (e instanceof FractionExpression || e instanceof SquareExpression) {
                e.elements.map((firstE, index1) => {
                    if (firstE instanceof FractionExpression) {
                        data = this.perviousElementId(e.elements)
                        if (data !== undefined) {
                            return data
                        }
                    } else if (firstE instanceof SquareExpression) {
                        data = this.perviousElementId(e.elements)
                        if (data !== undefined) {
                            return data
                        }
                    } else if (firstE.id === this.state.currentCursorItemId) {
                        if (index1 === 0) {
                            return data = {
                                id: arr[index - 1].id,
                                index: index - 1,
                                arr: arr
                            }
                        } else {
                            return data = {
                                id: e.elements[index1 - 1].id,
                                index: index1 - 1,
                                arr: e.elements
                            }
                        }
                    }
                })

                e.secondElements.map((secondE, index2) => {
                    if (secondE instanceof FractionExpression) {
                        data = this.perviousElementId(e.secondElements)
                        if (data !== undefined) {
                            return data
                        }
                    } else if (secondE instanceof SquareExpression) {
                        data = this.perviousElementId(e.secondElements)
                        if (data !== undefined) {
                            return data
                        }
                    } else if (secondE.id === this.state.currentCursorItemId) {
                        if (index2 === 0) {
                            return data = {
                                id: e.elements[e.elements.length - 1].id,
                                index: e.elements.length - 1,
                                arr: e.elements
                            }
                        } else {
                            return data = {
                                id: e.secondElements[index2 - 1].id,
                                index: index2 - 1,
                                arr: e.secondElements
                            }

                        }

                    }
                })

            } else if (e.id === this.state.currentCursorItemId) {

                // console.log("XX2 : ", arr)
                if (index === 0) {
                    return data = {
                        id: e.id,
                        index: index,
                        arr: arr
                    }
                } else {

                    var cursorIndex = 0
                    if (isNext && arr.length > index + 1) {
                        cursorIndex = index + 1
                    } else {
                        cursorIndex = index - 1
                    }
                    return data = {
                        id: arr[cursorIndex].id,
                        index: cursorIndex,
                        arr: arr
                    }
                }
            }
        })
        return data
    }



    getCursorParentListWithIndex = (arr, parentIdentifier, currentCursorItemId = this.state.currentCursorItemId) => {
        var data = undefined
        arr.map((e, index) => {
            if (e instanceof FractionExpression || e instanceof SquareExpression) {
                e.elements.map((firstE, index) => {
                    if (firstE instanceof FractionExpression) {
                        // console.log("e1 : ", e)
                        data = this.getCursorParentListWithIndex(e.elements, e.id)
                        if (data !== undefined) {
                            return data
                        }
                    } else if (firstE instanceof SquareExpression) {
                        // console.log("e2 : ", e)

                        data = this.getCursorParentListWithIndex(e.elements, e.id)
                        if (data !== undefined) {
                            return data
                        }
                    } else if (firstE.id === currentCursorItemId) {
                        // console.log("e3 : ", e)

                        return data = {
                            arr: e.elements,
                            index: index,
                            parentIdentifier: e.id
                        }
                    }
                })

                e.secondElements.map((secondE, index) => {
                    if (secondE instanceof FractionExpression) {
                        data = this.getCursorParentListWithIndex(e.secondElements, e.id)
                        if (data !== undefined) {
                            return data
                        }
                    } else if (secondE instanceof SquareExpression) {
                        data = this.getCursorParentListWithIndex(e.secondElements, e.id)
                        if (data !== undefined) {
                            return data
                        }
                    } else if (secondE.id === currentCursorItemId) {
                        return data = {
                            arr: e.secondElements,
                            index: index,
                            parentIdentifier: e.id
                        }
                    }
                })

            } else if (e.id === currentCursorItemId) {
                data = {
                    arr: arr,
                    index: index,
                    parentIdentifier: parentIdentifier
                };
                return data
            }
        })
        return data
    }

    isEquationEmpty = (equtions) => {

        console.log("E : ", equtions)
        for (var index = 0; index < equtions.length; index++) {
            var e = equtions[index]
            if (e instanceof Expression) {

                var r = this.isEquationEmpty(e.elements)
                console.log("r :", r)
                if (!r) {
                    return r
                }

                var secondElmentR = this.isEquationEmpty(e.elements)
                console.log("r :", r)
                if (!secondElmentR) {
                    return secondElmentR
                }

            } else {
                console.log("e.element", e.element)
                if (e.element !== "") {
                    return false
                }
            }
        }

        return true
    }

    onCalculatorKeyPress(element) {
        if (
            element === 'EMPTY'
        ) {
            return
        }

        if (element === '=') {
            this.evalute()
            return
        }

        if (element === 'C') {
            this.clear()
            return
        }
        if (this.state.cursorIndex === "") {
            return
        }

        let newEquations = this.state.equations
        let cursorElementId = this.state.currentCursorItemId
        let newInputKeyIdentifier = this.state.inputKeyidentifier
        let newOnFocusTextInputKey = this.state.onFocusTextInputKey
        let newOnFocusEquationElementBase = this.state.onFocusEquationElementBase



        let cursor_indexs = 0
        // let edit_equations = newEquations



        if (element === 'MOVE_BACKWARD') {
            var cursorIndexData = this.getPerviousElementID(newEquations)
            if (cursorIndexData !== undefined) {
                cursorElementId = cursorIndexData.id
            }

        } else if (element === 'MOVE_FORWARD') {
            var nextCursorIndexData = this.getNextElementID(newEquations)

            // console.log("nextCursorIndexData : ", nextCursorIndexData)
            if (nextCursorIndexData !== undefined) {
                cursorElementId = nextCursorIndexData.id
            }

        } else if (element === "CE") {
            var elementData = this.removeElement(newEquations)
            console.log("XXX CheckEquationAsValue", this.isEquationEmpty(newEquations))

            if (this.isEquationEmpty(newEquations)) {
                newEquations = []
            }
            if (elementData !== undefined) {
                cursorElementId = elementData.id
            }
        } else {
            var data = this.getCursorParentListWithIndex(newEquations, "")
            if (data === undefined) {
                cursorElementId = this.insertElement(newEquations, 0, element, cursorElementId, "")
            } else {
                var newIndex = data.index + 1
                if (data.arr[data.index] instanceof EquationElement && data.arr[data.index].element === '') {
                    CalculatorUtil.remove(data.arr, data.index)
                    newIndex = data.index
                }
                cursorElementId = this.insertElement(data.arr, newIndex, element, cursorElementId, data.parentIdentifier)

            }
        }
        // console.log("equations : ", newEquations)
        this.setState({
            equations: newEquations,
            cursorIndex: cursor_indexs,
            inputKeyIdentifier: newInputKeyIdentifier,
            onFocusTextInputKey: newOnFocusTextInputKey,
            onFocusEquationElementBase: newOnFocusEquationElementBase,
            currentCursorItemId: cursorElementId
        })
    }


    CheckEquationAsValue = (equation) => {
        var asValue = false
        equation.elements.map((e) => {
            if (e instanceof EquationElement) {
                if (e.element !== "") {
                    asValue = true
                }
            } else if (e instanceof FractionExpression) {
                asValue = this.CheckEquationAsValue(e)
            }
        })

        equation.secondElements.map((e) => {
            if (e instanceof EquationElement) {
                if (e.element !== "") {
                    asValue = true
                } else if (e instanceof FractionExpression) {
                    asValue = this.CheckEquationAsValue(e)
                }
            }
        })
        return asValue
    }



    insertElement = (arr, index, element, cursorElementId, parentIdentifier) => {
        if (element === 'CE') {
            CalculatorUtil.remove(arr, index - 1)
        } else if (CalculatorUtil.isOperator(element)) {

            var isNextElementAvilable = CalculatorUtil.isNextElementAvilable(arr, index)

            if (element === "frac") {
                var firstElement = new EquationElement("FirstObj", parentIdentifier, "")
                var sencondElement = new EquationElement("SencondObj", parentIdentifier, "")

                let fr = new FractionExpression(parentIdentifier, 'column', element, [firstElement], [sencondElement])
                firstElement.parentIdentifier = fr.id
                sencondElement.parentIdentifier = fr.id
                CalculatorUtil.insert(arr, index, fr)

                if (!isNextElementAvilable) {
                    CalculatorUtil.insert(arr, index + 1, new EquationElement("EmptyObj", parentIdentifier, ""))
                }
                cursorElementId = firstElement.id

            } else if (element === "POW" || element === "CUBE" || element === "SQRT") {
                var expoElement = new EquationElement("", parentIdentifier, "")
                if (element === "CUBE") {
                    expoElement = new EquationElement("", parentIdentifier, "3")
                } else if (element === "SQRT") {
                    expoElement = new EquationElement("", parentIdentifier, "2")
                }

                var newElements = [new EquationElement("Obj", parentIdentifier, "")]
                var isTakePreviousElements = false
                if (index > 0) {
                    const pElement = []
                    const subarray = arr.slice(0, index);
                    subarray.reverse()

                    var isReachedSymbol = false
                    subarray.map((element) => {
                        console.log("square element", element)

                        // if (element instanceof SquareExpression) {
                        //     console.log("SquareExpression", element)
                        //     pElement.push(element)
                        // } else {
                        if (!(element instanceof EquationSymbol) && !isReachedSymbol) {
                            console.log("pElement element", pElement)

                            pElement.push(element)
                        } else {
                            isReachedSymbol = true
                            return
                        }
                        // }
                    })
                    if (pElement.length !== 0) {
                        isTakePreviousElements = true
                        newElements = pElement.reverse()
                    }
                }
                let squareExp = new SquareExpression(parentIdentifier, 'row', element, newElements, [expoElement])
                newElements.map((childElement) => {
                    childElement.parentIdentifier = squareExp.id
                })
                expoElement.parentIdentifier = squareExp.id

                CalculatorUtil.insert(arr, index, squareExp)
                if (!isNextElementAvilable) {
                    let emptyElement = new EquationElement("EmptyObj", parentIdentifier, "")
                    // console.log("Berfore arr", arr)

                    CalculatorUtil.insert(arr, index + 1, emptyElement)
                    // console.log("emptyElement", emptyElement)

                    if (element === "POW") {
                        cursorElementId = expoElement.id
                    } else {
                        cursorElementId = emptyElement.id
                    }
                }
                if (isTakePreviousElements) {
                    CalculatorUtil.removeWithLength(arr, index - newElements.length, newElements.length)
                } else {
                    cursorElementId = newElements[newElements.length - 1].id
                }
                // console.log("arr", arr)
            } else {
                var e = new EquationSymbol(parentIdentifier, element)
                CalculatorUtil.insert(arr, index, e)
                cursorElementId = e.id
            }
        } else {
            var e1 = new EquationElement("", parentIdentifier, element)

            CalculatorUtil.insert(arr, index, e1)
            cursorElementId = e1.id

        }

        return cursorElementId
    }


    createpopoverChildItem(key) {
        let imageInfoForKey = CalculatorUtil.imageForKey(key)

        if (imageInfoForKey === undefined) {
            return (<div onMouseEnter={() => this.onSelectedKey(key)} className="calbtnMore" style={{ padding: 10 }} onClick={() => { this.setGreaterThanOpen(false) }}>
                <span style={{ fontSize: 24, color: "#01497c" }}>
                    {key}
                </span>
            </div>)
        } else {
            return (
                <div onMouseEnter={() => this.onSelectedKey(key)} className="calbtnMore" style={{ padding: 10 }} onClick={() => { this.setIsPopoverOpen(false) }} >
                    <img src={key} width={imageInfoForKey.size} />
                </div>)
        }


    }

    setSelectedKey(key) {
        this.onSelectedKey = key
    }


    tooglePopover(key) {
        if (this.state.onSelectedPopoverKey) {
            this.onPopoverClose()
        } else {
            this.onPopoverOpen(key)
        }
    }
    onPopoverOpen(key) {
        this.setSelectedKey(key)
        this.setState({
            onSelectedPopoverKey: key
        })
    }


    onPopoverClose() {
        this.onCalculatorKeyPress(this.onSelectedKey)
        this.setSelectedKey(undefined)
        this.setState({
            onSelectedPopoverKey: undefined
        })
    }
    createParentItem(key) {
        let imageInfoForKey = CalculatorUtil.imageForKey(key)

        if (imageInfoForKey === undefined) {
            return (<div onMouseEnter={() => this.setSelectedKey(key)} style={{ paddingLeft: 10, display: 'flex', justifyContent: 'center' }} onClick={() => { this.tooglePopover(key) }}>
                <span style={{ fontSize: 24, color: "#01497c" }}>
                    {key}
                </span>
            </div>)
        } else {
            return (
                <div onMouseEnter={() => this.setSelectedKey(key)} style={{ paddingLeft: 10, marginRight: -5, display: 'flex', justifyContent: 'end' }} onClick={() => { this.tooglePopover(key) }} >
                    <img src={imageInfoForKey.image} width={imageInfoForKey.size} />
                </div>)
        }
    }

    createChildItem(key) {
        let imageInfoForKey = CalculatorUtil.imageForKey(key)

        if (imageInfoForKey === undefined) {
            return (<div onMouseEnter={() => this.setSelectedKey(key)} className="calbtnMore" style={{ padding: 10 }} onClick={() => { this.onPopoverClose() }}>
                <span style={{ fontSize: 24, color: "#01497c" }}>
                    {key}
                </span>
            </div>)
        } else {
            return (
                <div onMouseEnter={() => this.setSelectedKey(key)} className="calbtnMore" style={{ padding: 10 }} onClick={() => { this.onPopoverClose() }} >
                    <img src={imageInfoForKey.image} width={imageInfoForKey.size} />
                </div>)
        }
    }


    renderPopoverChild(pKey, style) {
        let views = []
        CalculatorUtil.getChildItem(pKey).map((key, a) => {
            views.push(<div>
                {this.createChildItem(key)}
            </div>)
        })
        return views
    }
    createPopoverItem(key, style) {
        return (
            <div className="noselect" style={{ ...style }}>
                <Popover
                    isOpen={this.state.onSelectedPopoverKey === key}
                    positions={['top']} // if you'd like, you can limit the positions
                    padding={10} // adjust padding here!
                    reposition={false} // prevents automatic readjustment of content position that keeps your popover content within its parent's bounds
                    onClickOutside={() => this.onPopoverClose()} // handle click events outside of the popover/target here!
                    content={({ position, nudgedLeft, nudgedTop }) => ( // you can also provide a render function that injects some useful stuff!
                        <div className="row" style={{ backgroundColor: '#f0f0f0', boxShadow: "1px 1px 1px #cccccc" }}>
                            {this.renderPopoverChild(key, style)}
                        </div>
                    )}
                >
                    <div style={{ width: '100%', alignItems: 'center', justifyContent: 'center', justifyItems: 'center' }}>
                        {this.createParentItem(key)}
                    </div>
                </Popover>
                <div style={{ fontSize: 24, color: 'red', marginRight: 5, marginBottom: 5, alignSelf: 'end' }}>
                    .
                </div>
            </div>
        )
    }
    createItem(key, isCornerItem, isEqual) {
        let isDisable = CalculatorUtil.isDisableKey(key)
        let imageInfoForKey = CalculatorUtil.imageForKey(key)

        let style = { width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginRight: '0.5px', marginLeft: '0.5px' }

        if (CalculatorUtil.isRightBootomKey(key)) {
            style.borderBottomRightRadius = 20
        }

        if (CalculatorUtil.isLeftBootomKey(key)) {
            style.borderBottomLeftRadius = 20
        }

        if (CalculatorUtil.isCornerItem(key)) {
            style.backgroundColor = '#f0f0f0'
        }
        if (CalculatorUtil.highLightlingKeys1.includes(key)) {
            style.backgroundColor = '#f0f0f0'
        }

        if (isDisable) {
            style.color = 'grey'
        }

        if (imageInfoForKey === undefined) {
            if (key === '<') {
                return this.createPopoverItem(key, style)
            } else if (key === '>') {
                return this.createPopoverItem(key, style)
            } else {
                return (
                    <div className="noselect" style={style} onClick={() => { this.onCalculatorKeyPress(key) }}>
                        <span style={{ fontSize: 24 }}>
                            {key}
                        </span>
                    </div>
                )
            }
        } else {
            if (key === 'SQRT') {
                return this.createPopoverItem(key, style)
            } else {
                return (
                    <div className="noselect" style={style} onClick={() => { this.onCalculatorKeyPress(key) }}>
                        <img src={imageInfoForKey.image} width={imageInfoForKey.size} />
                    </div>
                )
            }
        }
    }

    renderCalculatorKeySet(calKeys) {
        let views = []
        calKeys.map((key, a) =>
            views.push(this.createItem(key, CalculatorUtil.isCornerItem(key), key === '='))
        )
        return views
    }

    renderCalculatorKeys() {
        let views = []
        CalculatorUtil.nonSigntificKeys.map((key, a) => {
            views.push(<div style={{ display: 'flex', flex: 1, width: '100%', flexDirection: 'row', justifyContent: 'space-evenly', marginBottom: '1px' }}>
                {this.renderCalculatorKeySet(key)}
            </div>)
        })
        return views
    }

    evaluateFraction(fractionExpression, toSolve) {
        let equation = ""
        if (fractionExpression instanceof FractionExpression) {
            if (!toSolve) {
                equation += "("
                equation += "("
            } else {
                //         equ = equ + '\\frac{' + e[0] + '}{' + e[1] + '}'
                equation += "\\frac{"
            }
            fractionExpression.elements.map((map) => {
                equation += this.appendEquation(map, toSolve).equation
            })
            if (!toSolve) {
                equation += ")"
            } else {
                equation += "}"
            }

            if (!toSolve) {
                equation += "/"
            }

            if (!toSolve) {
                equation += "("
            } else {
                equation += "{"
            }
            fractionExpression.secondElements.map((map) => {
                equation += this.appendEquation(map, toSolve).equation
            })
            if (!toSolve) {
                equation += ")"
                equation += ")"
            } else {
                equation += "}"
            }
        }
        return equation
    }

    evaluateSquare(squareExpression, toSolve) {
        let equation = ""
        if (squareExpression instanceof SquareExpression) {
            squareExpression.elements.map((map) => {
                equation += this.appendEquation(map, toSolve).equation
            })
            if (!toSolve) {
                equation += "^"
            }
            if (!toSolve) {
                equation += "("
            } else {
                equation += "^{"
            }
            squareExpression.secondElements.map((map) => {
                equation += this.appendEquation(map, toSolve).equation
            })
            if (!toSolve) {
                equation += ")"
            } else {
                equation += "}"
            }
        }
        return equation
    }

    appendEquation(element, toSolve) {
        let equation = ""
        let ismmdEquation = false
        if (element instanceof EquationElement) {
            if (element.element === 'π') {
                equation += 'pi'
            } else {
                equation += element.element
            }
            if (element.element === '%' && toSolve) {
                equation += '25'
            }
        } else if (element instanceof EquationSymbol) {
            if (element.element === '×') {
                equation += '*'
            } else if (element.element === '÷') {
                equation += '/'
            } else {
                equation += element.element
            }
        } else if (element instanceof FractionExpression) {
            ismmdEquation = true
            equation += this.evaluateFraction(element, toSolve)
        }
        else if (element instanceof SquareExpression) {
            ismmdEquation = true
            equation += this.evaluateSquare(element, toSolve)
        }
        return { 'equation': equation, 'ismmdEquation': ismmdEquation }
    }

    formEquation() {
        let equation = ""
        for (let i = 0; i < this.state.equations.length; i++) {
            let map = this.state.equations[i]
            equation += this.appendEquation(map, false).equation
        }

        console.log("formEquation : ", equation)
        return equation
    }

    getSubElement(eq) {
        console.log("isPower eq: ", eq)

        var element = ""
        for (let i = 0; i < eq.length; i++) {
            var char = eq[i]
            if (char === "{") {

            } else if (char === '}') {
                return element
            } else if (CalculatorUtil.isNumeric(char)) {
                element = "" + element + char
            }

            console.log(i, " element: ", element)

        }
        console.log("element: ", element)

        return element;
    }

    insertFrac(firstElementVal, secondElementVal) {

        let fr = new FractionExpression("", 'column', "frac", [], [])
        var firstElements = []
        var secondElements = []
        for (let i = 0; i < firstElementVal.length; i++) {
            var e1 = firstElementVal[i]
            var eObj = new EquationElement("FirstObj", fr.id, e1)
            firstElements.push(eObj)
        }

        for (let j = 0; j < secondElementVal.length; j++) {
            var e2 = secondElementVal[j]
            var e2Obj = new EquationElement("FirstObj", fr.id, e2)
            secondElements.push(e2Obj)
        }

        fr.elements = firstElements
        fr.secondElements = secondElements

        return fr
        // CalculatorUtil.insert(arr, index, fr)
    }
    decodeEquation(eq) {
        let eqList = eq.split("")
        let newEquations = []
        let cursorElementId = 0
        let index = 0
        console.log("valc : ", eqList.length)

        for (let i = 0; i < eqList.length; i++) {
            var element = eqList[i]
            console.log("val : ", element)
            console.log("indez : ", index)
            // var data = this.getCursorParentListWithIndex(newEquations, "" , cursorElementId)

            // index = data ? data.index : 0

            if (CalculatorUtil.isNumeric(element) || CalculatorUtil.isOperator(element)) {
                cursorElementId = this.insertElement(newEquations, index, element, cursorElementId, "")
                index = index + 1
            } else if (CalculatorUtil.isPower(element)) {
                console.log("isPower : ", element)
                var subarray = eq.split("")
                var secondElement = this.getSubElement(subarray.splice(i + 1, (eqList.length - i - 1)))

                cursorElementId = this.insertElement(newEquations, index, 'POW', cursorElementId, "")

                // var data1 = this.getCursorParentListWithIndex(newEquations, "")
                // newEquations.reverse()
                var sqElement = newEquations[newEquations.length - 2]

                // console.log("data0" , data1)
                console.log("sqElement : ", sqElement)
                console.log("newEquations : ", newEquations)

                var expList = secondElement.split("")
                var newIndex = 0
                sqElement.secondElements = []
                for (let j = 0; j < expList.length; j++) {
                    console.log("sqElement.secondElemen ", sqElement)
                    this.insertElement(sqElement.secondElements, newIndex, expList[j], cursorElementId, sqElement.id)
                    newIndex++
                }

                i = i + secondElement.length + 2
                cursorElementId = newEquations[newEquations.length - 1].id
                index = newEquations.length - 1
                console.log("Second Elemnet: ", cursorElementId, " newEquations : ", newEquations)
            } else if (element === '*') {
                cursorElementId = this.insertElement(newEquations, index, '×', cursorElementId, "")
                index = index + 1
            } else if (element === '/') {
                console.log("CalculatorUtil.isSpecialChar(eqList[i + 1]) : ", CalculatorUtil.isSpecialChar(eqList[i + 1]))
                console.log("CalculatorUtil.isNumeric(element) : ", CalculatorUtil.isNumeric(element))
                if (i + 1 < eqList.length && (CalculatorUtil.isSpecialChar(eqList[i + 1]) || !CalculatorUtil.isNumeric(eqList[i + 1]))) {
                    console.log("xxxx isFrac i : ", i)

                    var fracLength = 5
                    var braceLength = 2
                    if (CalculatorUtil.isFrac(eq.substring(i, eq.length))) {
                        console.log("xxxx isFrac")
                        var firstElement = this.getSubElement(eq.substring(i + fracLength, (eqList.length - 1)))
                        var secondElement1 = ""
                        console.log("xxxx firstElement", firstElement)

                        if (i + fracLength + firstElement.length + braceLength < eqList.length) {
                            secondElement1 = this.getSubElement(eq.substring(i + fracLength + firstElement.length + braceLength, (eqList.length - 1)))
                            console.log("xxxx secondElement", secondElement1)
                        }


                        var fracExp = this.insertFrac(firstElement, secondElement1)

                        CalculatorUtil.insert(newEquations, index, fracExp)
                        cursorElementId = fracExp.id
                        i = firstElement.length + secondElement1.length + (braceLength * 2) + fracLength
                        console.log("i : ", newEquations)
                        index = index + 1
                    }
                } else {
                    cursorElementId = this.insertElement(newEquations, index, '÷', cursorElementId, "")
                    index = index + 1
                }
            } 
            else if (CalculatorUtil.isPi(eq.substring(i, eq.length))) {
                console.log("isPi   ")
                cursorElementId = this.insertElement(newEquations, index, 'π', cursorElementId, "")
                index=index+1
                i = i + 1
            }
        }


        console.log("XXX cursorElementId", cursorElementId)
        console.log("XXX newEquations", cursorElementId)
        this.setState({
            equations: newEquations,
            currentCursorItemId: cursorElementId
        })


        // return equation
    }
    evalute = () => {
        let result = 0

        let equation = this.formEquation()

        try {
            equation = equation.replace('≤', '<=')
            equation = equation.replace('≥', '>=')
            result = math.evaluate(equation)
        }
        catch (error) {
            result = "invalid"
        }

        if (result === undefined) {
            result = ""
        }
        this.setState({
            display: {
                result: result.toString()
            }
        })
    }

    onElementPress = (elementKey, id) => {
        this.setState({
            cursorIndex: elementKey,
            currentCursorItemId: id
        })

        // console.log("id : ", id)
    }

    render() {
        return (
            <div className="container">
                <div className="calc-wrapper">
                    <div className="calc-display-wrapper calc-display-area">
                        {
                            this.state.equations.length > 0
                                ?
                                <div className="calc-display-wrapper_display">
                                    <CalculatorTextArea
                                        values={[this.state.display.innerHTML]}
                                        equation={this.state.display.innerHTML}
                                        result={this.state.display.result}
                                        equations={this.state.equations}
                                        currentCursorPosition={this.state.currentCursorPosition}
                                        currentCursorItemId={this.state.currentCursorItemId}
                                        showSolutionSteps={this.moveToSolutionSteps}
                                        onCalculatorKeyPress={this.onCalculatorKeyPress}
                                        onChangeText={(k, t) => { }}
                                        onFocusedKey={this.state.onFocusTextInputKey}
                                        onFocus={(key, position) => {
                                            this.setState({
                                                currentCursorPosition: position,
                                                onFocusTextInputKey: key
                                            })
                                        }}
                                        onCursorPositionChange={(position) => {
                                            this.setState({
                                                currentCursorPosition: position
                                            })
                                        }}
                                        cursorIndex={this.state.cursorIndex}
                                        onElementPress={this.onElementPress}
                                    />
                                    <img className="calc-display-wrapper_display_clear_button" style={{ top: 50 }} src={process.env.PUBLIC_URL + "/cross-icn.png"} alt="" onClick={() => this.clear()} ></img>
                                </div>
                                :
                                <div className="calc-display-wrapper_placeholder">Type something and we’ll solve it..</div>

                        }

                        {
                            (this.state.display.result === "invalid") ?
                                < div className="calc-bottom calc-display-wrapper_error">Equation is incomplete. Please add another value for a solution.</div>
                                :
                                ((this.state.display.result !== undefined && this.state.display.result.toString() !== "") ?
                                    <div className="calc-bottom calc-display-wrapper_solution_card">
                                        <div className="calc-display-equation"> {this.state.display.result.toString()}</div>
                                        <button className="calc-bottom theme-button see-steps-button" onClick={() => this.gotoSolutionSteps()}>See steps</button>
                                    </div> :
                                    <div></div>)
                        }
                    </div>

                    <div className="calc-divider-line divider"></div>
                    <div style={{ display: "flex", flex: 1, width: '100%', height: 295, flexDirection: 'column', justifyContent: 'space-evenly' }}>
                        {this.renderCalculatorKeys()}
                    </div>
                </div>
            </div>
        )
    }
}

export default CalculatorNew1;
