import React from "react";

class GetHelp extends React.Component {
  constructor() {
    super();
    this.state = {
      activeCollapse: 0,
    };
  }

  questions = [
    "How to create an account?",
    "How to scan a problem?",
    "How to find an older expression that I solved?",
    "Does the app provide only solutions?",
  ];

  faqAnswers = [
    "Click on the account icon and select the header ‘Create an Account’. Choose one of the options to sign up and select the email address you would like to link with FEVMath. Voila, you’ve created an account and signed up.",
    "Click on the Camera icon and point your camera to the math expression you would like to scan. Press the white circular button to capture the image and we will work our magic!",
    "Click on the History icon and you will see a list of all the expressions you solved along with their answers. Scroll through to find what you’re looking for.",
    "After scanning an expression or entering it in the calculator, you are given the solution along with the math expression that was recognised. If you wish to dive deeper to understand the steps, click ‘See Steps’. You can then follow the breakdown of each step and click on the animation ‘Show me how’ to have a visual understanding too.",
  ];

  moreitems = ["Terms of use", "Privacy policy", "Supported Problem Types"];

  handleExpandCollaps = (name) => {
    if (this.state.activeCollapse === name) {
      this.setState({ activeCollapse: "" });
    } else {
      this.setState({ activeCollapse: name });
    }
  };

  handleMoreItems = (index) => {
    if (index === 5) {
      window.location.href = window.location.origin + "/terms-and-condition";
    } else if (index === 6) {
      window.location.href = window.location.origin + "/privacy-policy";
    } else {
      window.location.href = window.location.origin + "/supported-problem-types";
    }
  };

  moreInfoClick = (e) => {
    e.stopPropagation();
  };

  renderItems = () => {
    let arr = [1, 2, 3, 4];
    let arr1 = [];
    arr.forEach((item) => {
      arr1.push(
        <div
          className={`sidebar-nav-menu-item ${this.state.activeCollapse === item ? "item-active" : ""
            }`}
          onClick={() => this.handleExpandCollaps(item)}
          data-id="circulars"
        >
          <div className="sidebar-nav-menu-item-head">
            <span className="sidebar-nav-menu-item-head-title">
              {this.questions[item - 1]}
            </span>
            <img
              className="sidebar-nav-menu-item-head-arrow"
              src={process.env.PUBLIC_URL + "/arrow-icn.png"}
              alt=""
            ></img>
          </div>
          <div className="sidebar-nav-menu-item-body">
            {this.faqAnswers[item - 1]}
          </div>
          <div style={{ marginTop: 5 }} className="history-divider-line divider"></div>
        </div>
      );
    });
    return arr1;
  };

  renderMoreItems = () => {
    let morearr = [5, 6];
    let morearr1 = [];
    morearr.forEach((item) => {
      morearr1.push(
        <div
          className={`sidebar-nav-menu-item ${this.state.activeCollapse === item ? "item-active" : ""
            }`}
          onClick={() => this.handleMoreItems(item)}
          data-id="circulars"
        >
          <div className="sidebar-nav-menu-item-head">
            <span className="sidebar-nav-menu-item-head-title">
              {this.moreitems[item - 5]}
            </span>
            <img
              className="sidebar-nav-menu-item-head-arrow"
              src={process.env.PUBLIC_URL + "/arrow-icn.png"}
              alt=""
            ></img>
          </div>
          <div className="sidebar-nav-menu-item-body">
            The way to create an account is to use Facebook or Gmail or your
            email to login and then you’re all set. The way to create an account
            is to use Facebook or Gmail or your email to login. The way to
            create an account is to use Facebook or Gmail or your email to login
            and then you’re all set. The way to create an account is to use
            Facebook or Gmail or your email to login. The way to create an
            account is to use Facebook or Gmail or your email to login and then
            you’re all set.
          </div>
          <div style={{ marginTop: 5 }} className="history-divider-line divider"></div>
        </div>
      );
    });
    return morearr1;
  };

  renderProblemTypes = () => {
    let morearr = [7];
    let morearr1 = [];
    morearr.forEach((item) => {
      morearr1.push(
        <div
          className={`sidebar-nav-menu-item ${this.state.activeCollapse === item ? "item-active" : ""
            }`}
          onClick={() => this.handleMoreItems(item)}
          data-id="circulars"
        >
          <div className="sidebar-nav-menu-item-head">
            <span className="sidebar-nav-menu-item-head-title">
              {this.moreitems[item - 5]}
            </span>
            <img
              className="sidebar-nav-menu-item-head-arrow"
              src={process.env.PUBLIC_URL + "/arrow-icn.png"}
              alt=""
            ></img>
          </div>
          <div className="sidebar-nav-menu-item-body">
            The way to create an account is to use Facebook or Gmail or your
            email to login and then you’re all set. The way to create an account
            is to use Facebook or Gmail or your email to login. The way to
            create an account is to use Facebook or Gmail or your email to login
            and then you’re all set. The way to create an account is to use
            Facebook or Gmail or your email to login. The way to create an
            account is to use Facebook or Gmail or your email to login and then
            you’re all set.
          </div>
          <div style={{ marginTop: 5 }} className="history-divider-line divider"></div>
        </div>
      );
    });
    return morearr1;
  };

  render() {
    return (
      <div className="container App-Layout">
        <h2>Help Center</h2>
        <div className="sidebar-nav">
          <div style={{ marginTop: 20, color: "black" }}>
            <h5>Popular topics</h5>
          </div>
          <div className="sidebar-nav-menu">{this.renderItems()}</div>
        </div>
        <div style={{ marginTop: 20, fontSize: 15, fontWeight: 500 }}>
          <div style={{ marginBottom: 20 }}>
            <h5>What type of Problem are Supported?</h5>
          </div>
          <div className="sidebar-nav">
            <div className="sidebar-nav-menu">{this.renderProblemTypes()}</div>
          </div>
        </div>
        <div style={{ marginTop: 20, fontSize: 15, fontWeight: 500 }}>
          <div style={{ marginBottom: 20 }}>
            <h5>Have a different question?</h5>
          </div>
          <div
            onClick={() => {
              window.location.assign("mailto:support@fevmath.com");
            }}
          >
            <div style={{ color: "#01497C" }}>Email Customer Service</div>
            <div style={{ color: "gray" }}>support@fevmath.com</div>
          </div>
        </div>
        <div className="sidebar-nav" style={{ marginTop: 20 }}>
          <div className="sidebar-nav-menu">{this.renderMoreItems()}</div>
        </div>
      </div>
    );
  }
}
export default GetHelp;
