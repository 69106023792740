import React from "react";
import { Component } from "react";
import axios from "axios";

import Webcam from "react-webcam";
import Modal from 'react-modal';
import CropImage from "./CropImage";
import AddImageCard, { AddImageCardType } from "./solution/AddImageCard";

class AddImage extends Component {
  state = {
    selectedImage: null,
    imageUrl: null,
    croppedImageUrl: null,
    imageSrc: null,
    croppedImageSrc: null,
    showPopup: false,
    improperImage: false,
    captureImageOn: false,
    cropImageOn: false,
    cropImageSource: AddImageCardType.SelectImage
    // allSteps: [], // Used for createWithSteps function
    // isSimplified: false, // Used for createWithSteps function
    // openStepsIndexs: [], // Used for createWithSteps function
    // redirect: null,
  };

  videoConstraints = {
    width: 800,
    height: 500,
    facingMode: "environment"
  };

  constructor(props) {
    super(props);
    this.webcamRef = React.createRef();
  }

  customStyles = {
    content: {
      width: 'fit-content',
      height: 'fit-content',
      maxWidth: '80%',
      maxHeight: '80%',
      margin: 'auto',
      visibility: this.state.cropImageOn ? 'visible' : (this.state.showPopup ? 'visible' : 'hidden'),
    },
  };

  cropCustomStyles = {
    content: {
      width: 'fit-content',
      height: 'fit-content',
      maxWidth: '80%',
      maxHeight: '80%',
      margin: 'auto',
      visibility: 'visible',
      overflow: 'hidden'
    },
  };

  selectedImage = (files) => {
    if (files && files.length > 0) {
      this.setState({
        selectedImage: URL.createObjectURL(files[0].file),
      });
    }
  };

  toDataURL = (url) =>
    fetch(url)
      .then((response) => response.blob())
      .then(
        (blob) =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          })
      );

  solveASCIIEquation(latexEquation, equation, type) {
    equation = equation.replace(/\*\*/gi, '*');
    equation = equation.replace(/xx/gi, '*');
    equation = equation.replace(/\/\//gi, '/');
    equation = equation.replace(/-:/gi, '/');

    // var data1 = {
    //   problem: equation,
    //   "source": "image-scan"
    // };

    window.location.href =
      window.location.origin +
      "/solution/" +
      encodeURIComponent(equation) + "?type=new-native";
  }

  checkForPattern = (value) => {
    var regexConst = /^[+-]?[0-9]+=?$/;
    return regexConst.test(value);
  }

  navigateToSolutionPage = (mathpixResponse) => {
    let response = mathpixResponse
    let makeTextAPICall = false

    // window.location.href =
    //   window.location.origin +
    //   "/solution/" +
    //   encodeURIComponent(response.data.text) + "?type=new-native-text";
    // return

    if (response.data.data.length > 1) {
      if (response.data.data[1].value.includes('longdiv')) {
        makeTextAPICall = true
      } else if (response.data.data[1].value.includes('frac')) {
        window.location.href =
          window.location.origin +
          "/solution/" +
          encodeURIComponent(response.data.data[1].value) + "?type=new-native-text";
        return
      } else if (response.data.text) {
        if (response.data.text.includes('tabular')) {
          makeTextAPICall = true
        }
      }
    }

    if (!makeTextAPICall) {
      if (response.data.data.length > 1) {
        this.solveASCIIEquation(
          response.data.data[1].value,
          response.data.data[0].value,
          1,
        );
      } else if (response.data.text) {
        if (this.checkForPattern(response.data.text)) {
          this.solveASCIIEquation(
            response.data.text,
            response.data.text,
            1,
          );
        } else {
          window.location.href =
            window.location.origin +
            "/solution/" +
            encodeURIComponent(response.data.text) + "?type=new-native-text";
        }
      }
    } else {
      window.location.href =
        window.location.origin +
        "/solution/" +
        encodeURIComponent(response.data.text) + "?type=new-native-text";
    }
  }

  callService = (image) => {
    this.toDataURL(
      image
    ).then((dataUrl) => {

      let config = {
        headers: {
          // app_id: "admin_transnoesis_com_7e7afa_5ec7a1",
          // app_key: "18d60be64766df45f327",
          app_id: "rajjaya566_gmail_com_4d4c04_f78089",
          app_key: "164803dd5348043cb586",
        },
      };

      axios.post(
        "https://api.mathpix.com/v3/text",
        {
          src: dataUrl,
          formats: ["text", "data", "html"],
          data_options: {
            include_asciimath: true,
            include_latex: true,
          },
        },
        config
      ).then(
        (response) => {
          if (response.data.error === null || response.data.error === undefined) {
            const imgData = {
              base64: dataUrl,
              data_dump: response.data,
            };

            axios.post(
              "https://lm2yz3zj37.execute-api.ap-south-1.amazonaws.com/dev/image/",
              imgData,
              {
                headers: {
                  "Content-Type": "application/json",
                  "X-Api-Key": `T4tp4QcE6E2caWuXAOSOsa0nz6aw3FHt3wkgPTTe`,
                },
              }
            ).then(
              (response) => {
              },
              (error) => {
              }
            );

            this.navigateToSolutionPage(response)




            // const cleanExpression = response.data.data[0].value
            //   .replace(/\*\*/g, "*")
            //   .replace(/xx/g, "*")
            //   .replace(/\/\//g, "/")
            //   .replace(/-:/g, "/")
            //   .replace(/ /g, "");
            // window.location.href =
            //   window.location.origin +
            //   "/solution/" +
            //   encodeURIComponent(cleanExpression);
            // response.data.data[0].value;

            // browserHistory.push("/solution/" + response.data.data[0].value);
          } else {
            alert("Oh Rajib!, We were unable to find a mathematics problem in this photo.")
            // this.setState({
            //   equation: {
            //     asciimath:
            //       "Oh Rajib!, We were unable to find a mathematics problem in this photo. Mathematics is the language of the universe but I am but a puny mobile phone.",
            //     latex:
            //       "Oh Rajib!, We were unable to find a mathematics problem in this photo. Mathematics is the language of the universe but I am but a puny mobile phone.",
            //   },
            //   showPopup: true,
            //   improperImage: true,
            // });
          }
        },
        (error) => {
          this.setState({
            equation: {
              asciimath: error.error_info.messages,
              latex: error.error_info.messages,
            },
            showPopup: true,
          });
        }
      );
    });
  };

  clear = () => {
    this.setState({
      selectedImage: null,
      croppedImage: false,
      showPopup: false,
      equation: {
        asciimath: "",
        latex: "",
      },
      improperImage: false,
      allSteps: [],
    });
  };

  onMediaError = (error) => {
    this.closeCamera()
    alert(error)
  }

  openCamera = () => {
    this.setState({
      captureImageOn: true
    })
  }

  closeCamera = () => {
    this.setState({
      captureImageOn: false,
      showPopup: false
    })
  }

  render() {
    return (
      <div className="imgcont">
        <div className="row">
          <div className="column" style={{ margin: "auto" }}>
            <AddImageCard
              imageUrl={this.state.croppedImageUrl !== null ? this.state.croppedImageUrl : this.state.imageUrl}
              type={AddImageCardType.SelectImage}
              remove={() => {
                this.setState({
                  imageUrl: null,
                  croppedImageUrl: null,
                })
              }}
              crop={() => {
                this.setState({
                  showPopup: true,
                  cropImageOn: true,
                  cropImageSource: AddImageCardType.SelectImage
                })
              }}
              submit={() => {
                this.callService(this.state.croppedImageUrl !== null ? this.state.croppedImageUrl : this.state.imageUrl)
              }}
              addImage={(url) => {
                this.setState({
                  imageUrl: url
                })
              }}
            />
          </div>
          <div className="column" style={{ margin: "auto" }}>
            <AddImageCard
              imageUrl={this.state.croppedImageSrc !== null ? this.state.croppedImageSrc : this.state.imageSrc}
              type={AddImageCardType.CaptureImage}
              remove={() => {
                this.setState({
                  imageSrc: null,
                  croppedImageSrc: null
                })
              }}
              crop={() => {
                this.setState({
                  showPopup: true,
                  cropImageOn: true,
                  cropImageSource: AddImageCardType.CaptureImage
                })
              }}
              submit={() => {
                this.callService(this.state.croppedImageSrc !== null ? this.state.croppedImageSrc : this.state.imageSrc)
              }}
              captureImage={this.openCamera}
            />
          </div>
        </div>
        {
          this.state.captureImageOn &&
          <Modal
            isOpen={true}
            onAfterOpen={() => { }}
            onRequestClose={this.closeCamera}
            style={this.customStyles}
            contentLabel="WebcamModel"
          >
            <div style={{ position: 'relative', top: 0, visibility: this.state.showPopup ? 'visible' : 'hidden', overflow: 'hidden' }}>
              <Webcam
                audio={false}
                ref={this.webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={this.videoConstraints}
                mirrored={false}
                onUserMediaError={(error) => this.onMediaError(error)}
                onUserMedia={() => {
                  this.setState({
                    showPopup: true
                  })
                }}
              />
              <button
                className="imgbtn captureButton"
                onClick={() => {
                  const imageSrc1 = this.webcamRef.current.getScreenshot();
                  this.setState({
                    imageSrc: imageSrc1
                  })
                  this.closeCamera()
                }}>
                <img src={process.env.PUBLIC_URL + "/ic_camera.png"} width={45} height={45} alt=""></img>
              </button>
              <button
                className="imgbtn"
                style={{ position: 'absolute', right: 0, top: 0 }}
                onClick={this.closeCamera}>
                <img src={process.env.PUBLIC_URL + "/close.png"} width={25} height={25} alt=""></img>
              </button>
            </div>
          </Modal>
        }
        {
          this.state.cropImageOn &&
          <Modal
            isOpen={true}
            onAfterOpen={() => { }}
            onRequestClose={() => {
              this.setState({
                cropImageOn: false,
                showPopup: false
              })
            }}
            style={this.cropCustomStyles}
            contentLabel="WebcamModel"
          >
            <CropImage
              src={this.state.cropImageSource === AddImageCardType.SelectImage ? this.state.imageUrl : this.state.imageSrc}
              crop={(cropImage) => {
                if (this.state.cropImageSource === AddImageCardType.SelectImage) {
                  this.setState({
                    croppedImageUrl: cropImage,
                    cropImageOn: false,
                    showPopup: false
                  })
                } else {
                  this.setState({
                    croppedImageSrc: cropImage,
                    cropImageOn: false,
                    showPopup: false
                  })
                }
              }}
              cancel={() => {
                this.setState({
                  cropImageOn: false
                })
              }}
            ></CropImage>
          </Modal>
        }
      </div >
    );
  }
}

export default AddImage;

