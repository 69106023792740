import React from "react";
import MathJax from "react-mathjax";

import { Component } from "react";

class EquationCard extends Component {
  constructor(props) {
    super(props);
    this.updateAsciiMathEquation = this.updateAsciiMathEquation.bind(this);
    this.state = {
      editMode: false,
      updatedEquation: props.asciimathEquation,
    };
  }

  async componentDidMount() { }

  showEditView() {
    this.setState({
      editMode: true,
    });
  }

  hideEditView() {
    this.setState({
      editMode: false,
    });
  }

  solveEquation(event) {
    event.preventDefault();
    this.props.updateAsciiMathEquation(this.state.updatedEquation);
  }

  updateAsciiMathEquation(event) {
    this.setState({
      updatedEquation: event.target.value,
    });
  }

  // componentDidMount() {
  //   this.setState({
  //     updatedEquation: this.props.asciimathEquation,
  //   });
  // }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-12">
            <div className="equation-box sol-card-box">
              <div className="row">
                <div className="col-12 sol-card-title">
                  <div className="row">
                    <div className="col-6">Equation</div>
                    <div className="col-2"></div>
                    <div className="col-4">
                      {!this.state.editMode && (
                        <div
                          className="edit-equation"
                          onClick={() => this.showEditView()}
                        >
                          <span className="edit-eq-icn">
                            {" "}
                            <svg
                              width="13"
                              height="12"
                              viewBox="0 0 13 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.37333 4.01333L7.98667 4.62667L1.94667 10.6667H1.33333V10.0533L7.37333 4.01333ZM9.77333 0C9.60667 0 9.43333 0.0666666 9.30667 0.193333L8.08667 1.41333L10.5867 3.91333L11.8067 2.69333C12.0667 2.43333 12.0667 2.01333 11.8067 1.75333L10.2467 0.193333C10.1133 0.06 9.94667 0 9.77333 0ZM7.37333 2.12667L0 9.5V12H2.5L9.87333 4.62667L7.37333 2.12667Z"
                                fill="#01497C"
                              />
                            </svg>
                          </span>
                          Edit
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="col-12"> {this.props.asciimathEquation} </div> */}
                <div className="col-12 sol-card-content">
                  {this.state.editMode && (
                    <form className="mt-3">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control Input-theme"
                          id="exampleInputEmail1"
                          aria-describedby="equationHelp"
                          defaultValue={this.props.asciimathEquation}
                          onChange={(event) =>
                            this.updateAsciiMathEquation(event)
                          }
                          placeholder="Edit equation"
                          required
                        />
                      </div>
                      <div className="mt-2">
                        <button
                          type="submit"
                          disabled={this.props.asciimathEquation === ""}
                          className="btn rounded-pill submit-btn-fm bl-theme"
                          onClick={(event) => this.solveEquation(event)}
                        >
                          Submit
                        </button>
                        <button
                          className="btn ml-3 rounded-pill cancel-btn-fm bl-theme"
                          onClick={() => this.hideEditView()}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  )}
                  {!this.state.editMode && (
                    <MathJax.Provider>
                      <div
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          textAlign: "left",
                        }}
                      >
                        <MathJax.Node formula={this.props.latexEquation} />
                      </div>
                    </MathJax.Provider>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EquationCard;
