/**
 * This file contains the component for the scan, calculator,
 * solve and learn information cards used on the landing page.
 */

import React from "react";
import scan2 from "../assets/svg/scan2.svg";
import math_fun from "../assets/svg/math_fun.svg";
import steps from "../assets/svg/steps.svg";
import scicalc from "../assets/svg/scicalc.svg";
import teacher from "../assets/svg/teacher.svg";
import history from "../assets/svg/history.svg";

export default function OnboardingFeatures() {
  return (
    <div className="App-body obfView">
      <div className="cardsContent">
        <div className="obfCards">
          <img src={math_fun} className="obf" alt="math_fun"></img>
          <div className="obfTitle">Make math fun again</div>
        </div>
        <div className="obfCards">
          <img src={scan2} className="obf" alt="scan"></img>
          <div className="obfTitle">
            Begin by scanning a K-5 math expression
          </div>
        </div>
        <div className="obfCards">
          <img src={steps} className="obf" alt="steps"></img>
          <div className="obfTitle">
            Step by step breakdown of every solution
          </div>
        </div>
      </div>
      <div className="cardsContent">
        <div className="obfCards">
          <img src={scicalc} className="obf" alt="scicalc"></img>
          <div className="obfTitle">Built-in scientific calculator</div>
        </div>
        <div className="obfCards">
          <img src={teacher} className="obf" alt="teacher"></img>
          <div className="obfTitle">
            Learn maths like you do from your favourite maths teacher
          </div>
        </div>
        <div className="obfCards">
          <img src={history} className="obf" alt="history"></img>
          <div className="obfTitle">
            Keep track of every problem you have ever solved
          </div>
        </div>
      </div>
    </div>
  );
}
